import { enums, get } from '../../utils';
import { TaskType } from '.';
import { OptionType } from '../base/filter-drop-down';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import { getTasks, getTask } from '../../utils/web-apis-client';
import {
  getTasksConfig,
  getTaskStatusConfig,
} from '../../utils/remote-config-manager';
dayjs.extend(isBetween);

export const taskStatusConfigMap: { [key: string]: any } = {
  [enums.TaskStatuses.pending_notification]: {
    background: '#FEF3EA',
    color: '#E1710E',
    text: enums.TaskUIStatuses.awaiting_action,
  },
  [enums.TaskStatuses.pending]: {
    background: '#FEF3EA',
    color: '#E1710E',
    text: enums.TaskUIStatuses.awaiting_action,
  },
  [enums.TaskStatuses.returned]: {
    background: '#F8DEE0',
    color: '#CC222F',
    text: enums.TaskUIStatuses.needs_action,
  },
  [enums.TaskStatuses.under_review]: {
    background: '#D9EBF4',
    color: '#037CB7',
    text: enums.TaskUIStatuses.in_review,
  },
  [enums.TaskStatuses.escalated]: {
    background: '#FEF3EA',
    color: '#E1710E',
    text: enums.TaskUIStatuses.awaiting_action,
  },
  [enums.TaskStatuses.completed]: {
    background: '#D9F2EA',
    color: '#04A874',
    text: enums.TaskUIStatuses.completed,
  },
  [enums.TaskStatuses.ignored]: {
    background: '#333333',
    color: '#F8F8F8',
    text: enums.TaskUIStatuses.ignored,
  },
  [enums.TaskStatuses.archived]: {
    background: '#D9EBF4',
    color: '#037CB7',
    text: enums.TaskUIStatuses.archived,
  },
};

export const defaultOption = 'All Leaves & Claims';

const allFilterOptions: OptionType[] = [
  {
    option: 'View all',
    fieldType: 'Group 0',
    value: 'viewAll',
  },
  {
    option: 'Overdue',
    fieldType: 'Group 1',
    value: 'overdue',
  },
  {
    option: 'Due this week',
    fieldType: 'Group 1',
    value: 'dueThisWeek',
  },
  {
    option: 'Due next week',
    fieldType: 'Group 1',
    value: 'dueNextWeek',
  },
  {
    option: 'Due this month',
    fieldType: 'Group 1',
    value: 'dueThisMonth',
  },
  {
    option: 'Ignored/Snoozed',
    fieldType: 'Group 2',
    value: 'ignored',
  },
  {
    option: 'Received',
    fieldType: 'Group 3',
    value: 'under_review',
  },
  {
    option: 'Archived',
    fieldType: 'Group 4',
    value: 'archived',
  },
];

const requiredfilterOptions: OptionType[] = [
  {
    option: 'View all',
    fieldType: 'Group 0',
    value: 'viewAll',
  },
  {
    option: 'Overdue',
    fieldType: 'Group 1',
    value: 'overdue',
  },
  {
    option: 'Due this week',
    fieldType: 'Group 1',
    value: 'dueThisWeek',
  },
  {
    option: 'Due next week',
    fieldType: 'Group 1',
    value: 'dueNextWeek',
  },
  {
    option: 'Due this month',
    fieldType: 'Group 1',
    value: 'dueThisMonth',
  },
  {
    option: 'Received',
    fieldType: 'Group 2',
    value: 'under_review',
  },
  {
    option: 'Archived',
    fieldType: 'Group 4',
    value: 'archived',
  },
];

const recommendedfilterOptions: OptionType[] = [
  {
    option: 'View all',
    fieldType: 'Group 0',
    value: 'viewAll',
  },
  {
    option: 'Ignored/Snoozed',
    fieldType: 'Group 1',
    value: 'ignored',
  },
  {
    option: 'Received',
    fieldType: 'Group 2',
    value: 'under_review',
  },
  {
    option: 'Archived',
    fieldType: 'Group 4',
    value: 'archived',
  },
];

const UITFilterOptions: OptionType[] = [
  {
    option: 'View all',
    fieldType: 'Group 0',
    value: 'viewAll',
  },
];

const dueTaskStatuses = [
  enums.TaskStatuses.pending_notification,
  enums.TaskStatuses.pending,
  enums.TaskStatuses.returned,
  enums.TaskStatuses.escalated,
];

const receivedTaskStatuses = [
  enums.TaskStatuses.under_review,
  enums.TaskStatuses.completed,
];

export const isDueTask = (status: number) =>
  dueTaskStatuses.some((s) => s === status);

export const isReceivedTask = (status: number) =>
  receivedTaskStatuses.some((s) => s === status);

export const taskAppliesToNTN = (taskNTNID: string, NTNCaseID: string) =>
  taskNTNID === NTNCaseID;

export const taskIsRequired = (taskCategoryID: number) => taskCategoryID === 1;

export const claimStatusesThatUseTaskFlag = [
  'mixeddecision',
  'Mixed Decision',
  'partial',
  'Open',
  'Intake in Progress',
  'Request Recognized',
  'Pending',
  'Adjudication',
  'Approved',
];

export const filterOptionMap: {
  [key: string]: OptionType[];
} = {
  all: allFilterOptions,
  required: requiredfilterOptions,
  recommended: recommendedfilterOptions,
  uit: UITFilterOptions,
};

const statusOrder: { [key: string]: number } = {
  '6': 1,
  '4': 2,
  '3': 3,
  '5': 4,
  '7': 5,
  default: 6,
};

export const sortTasks = (a: TaskType, b: TaskType) => {
  const aStatus = get(
    statusOrder,
    a.taskStatusId.toString(),
    statusOrder.default,
  );
  const bStatus = get(
    statusOrder,
    b.taskStatusId.toString(),
    statusOrder.default,
  );
  if (aStatus === bStatus) {
    return dayjs(b.taskDueDate).isAfter(a.taskDueDate) ? 1 : -1;
  } else {
    return aStatus - bStatus;
  }
};

export const filterViewAll: Function = (tasks: Array<TaskType>) => {
  const reqTasksNotComp = [...tasks].filter(
    (t) =>
      t.category.codename === 'required' &&
      t.taskStatusId !== 7 &&
      t.taskStatusId !== 9 &&
      t.taskStatusId !== 10,
  );
  reqTasksNotComp.sort(sortTasks);

  const recTasksNotComp = [...tasks].filter(
    (t) =>
      t.category.codename === 'recommended' &&
      t.taskStatusId !== 7 &&
      t.taskStatusId !== 9 &&
      t.taskStatusId !== 10,
  );
  recTasksNotComp.sort(sortTasks);

  const compTasks = [...tasks].filter(
    (t) => t.taskStatusId === 7 || t.taskStatusId === 9,
  );
  compTasks.sort(sortTasks);

  const archTasks = [...tasks].filter((t) => t.taskStatusId === 10);
  archTasks.sort(sortTasks);

  return reqTasksNotComp
    .concat(recTasksNotComp)
    .concat(compTasks)
    .concat(archTasks);
};

export const filterOverdue: Function = (tasks: Array<TaskType>) =>
  tasks
    .filter(
      (t: TaskType) =>
        t.taskDueDate &&
        isDueTask(t.taskStatusId) &&
        dayjs(t.taskDueDate).isBefore(dayjs()),
    )
    .sort(sortTasks);

export const filterThisWeek: Function = (tasks: Array<TaskType>) =>
  tasks
    .filter(
      (t: TaskType) =>
        t.taskDueDate &&
        isDueTask(t.taskStatusId) &&
        dayjs(t.taskDueDate).isBetween(
          dayjs(),
          dayjs().add(7, 'd'),
          'day',
          '[]',
        ),
    )
    .sort(sortTasks);

export const filterNextWeek: Function = (tasks: Array<TaskType>) =>
  tasks
    .filter(
      (t: TaskType) =>
        t.taskDueDate &&
        isDueTask(t.taskStatusId) &&
        dayjs(t.taskDueDate).isBetween(
          dayjs(),
          dayjs().add(14, 'd'),
          'day',
          '[]',
        ),
    )
    .sort(sortTasks);

export const filterThisMonth: Function = (tasks: Array<TaskType>) =>
  tasks
    .filter(
      (t: TaskType) =>
        t.taskDueDate &&
        isDueTask(t.taskStatusId) &&
        dayjs(t.taskDueDate).month() === dayjs().month(),
    )
    .sort(sortTasks);

export const filterIgnored: Function = (tasks: Array<TaskType>) =>
  tasks.filter((t: TaskType) => t.taskStatusId === enums.TaskStatuses.ignored);

export const filterUnderReview: Function = (tasks: Array<TaskType>) =>
  tasks.filter(
    (t: TaskType) => t.taskStatusId === enums.TaskStatuses.under_review,
  );

export const filterCompleted: Function = (tasks: Array<TaskType>) =>
  tasks
    .filter((t: TaskType) => t.taskStatusId === enums.TaskStatuses.completed)
    .sort(sortTasks);

export const filterArchived: Function = (tasks: Array<TaskType>) =>
  tasks
    .filter((t: TaskType) => t.taskStatusId === enums.TaskStatuses.archived)
    .sort(sortTasks);

export const actionMap: {
  [key: string]: Function;
} = {
  viewAll: filterViewAll,
  overdue: filterOverdue,
  dueThisWeek: filterThisWeek,
  dueNextWeek: filterNextWeek,
  dueThisMonth: filterThisMonth,
  under_review: filterUnderReview,
  ignored: filterIgnored,
  completed: filterCompleted,
  archived: filterArchived,
};

// TODO delete after refactor
export const getTaskList = async (
  setTaskList: React.Dispatch<React.SetStateAction<TaskType[]>>,
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
  setDisplayToast: React.Dispatch<React.SetStateAction<boolean>>,
  setToastMessage: React.Dispatch<React.SetStateAction<string>>,
  setToastType: React.Dispatch<React.SetStateAction<enums.ToastTypes['style']>>,
  caseFilter?: string,
  isParentCase = false,
) => {
  setIsLoading(true);
  const queryParams: any = {};
  if (caseFilter) {
    if (isParentCase) {
      queryParams.notificationCaseId = caseFilter;
    } else {
      queryParams.caseId = caseFilter;
    }
  }
  queryParams.assigneeId = 2;

  try {
    const tasksConfig = await getTasksConfig();
    const tasks = await getTasks(queryParams);
    const statusText = await getTaskStatusConfig();

    const tasksWithKontent = [];
    for (const task of tasks.tasks) {
      const taskKontent = tasksConfig.find(
        (tc) => tc.taskCodename === task.taskCodeName,
      );

      if (taskKontent && taskKontent.taskCodename === 'll_task') {
        tasksWithKontent.push({
          ...task,
          ...taskKontent,
          taskTitle: task.taskTitle,
        });
      } else if (taskKontent) {
        tasksWithKontent.push({
          ...task,
          ...taskKontent,
        });
      }
    }

    setStatusHelpText(tasksWithKontent, statusText);

    setTaskList(tasksWithKontent);
  } catch (err: any) {
    setToastMessage(
      'There was a problem getting your tasks. Please try again later.',
    );
    setToastType('error');
    setDisplayToast(true);
    console.error(err);
  } finally {
    setIsLoading(false);
  }
};

export const retrieveTask = async (
  setTask: Function,
  setIsLlTask: Function,
  taskKey?: string,
  setLoading?: Function,
) => {
  let fullTask = {} as TaskType;
  if (taskKey) {
    const task = await getTask(taskKey);
    const tasksConfig = await getTasksConfig();
    const statusText = await getTaskStatusConfig();

    if (task && tasksConfig) {
      const tc = tasksConfig.find(
        (tc) => tc.taskCodename === task.taskCodeName,
      );
      if (tc) {
        fullTask = { ...task, ...tc };
      }

      const isLL = task.taskCodeName === 'll_task';

      if (fullTask) {
        llMappings([fullTask]);
        setIsLlTask(isLL);
        setStatusHelpText([fullTask], statusText);
        setTask(fullTask);
      }

      if (setLoading) {
        setLoading(false);
      }
    }
  }
};

// TODO delete after refactor
const setStatusHelpText = (tasks: TaskType[], statusText: any[]) => {
  if (statusText) {
    tasks.forEach((t) => {
      const matchingText = statusText.find(
        (s) => s.taskStatusId === t.taskStatusId,
      );
      t.taskStatusHelp = matchingText?.description;
    });
  }
};

const llMappings = (tasks: TaskType[]) => {
  tasks.forEach((t, i) => {
    if (t.taskCodeName === 'll_task') {
      if (t.formData.length > 0 && t.formData[0].formItems.length > 0) {
        t.taskTitle =
          t.formData[0].formItems.find(
            (x: any) => x.codename === 'leave_logic_name_8b0cc6c',
          )?.inputValue ?? '';

        t.description =
          t.formData[0].formItems.find(
            (x: any) => x.codename === 'leave_logic_description_294c9be',
          )?.inputValue ?? '';

        t.leaveLogicDescription =
          t.formData[0].formItems.find(
            (x: any) => x.codename === 'leave_logic_task_description',
          )?.inputValue ?? '';
      } else {
        tasks.splice(i, 1);
      }
    }
  });
};
