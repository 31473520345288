import React from 'react';
import {
  DropDownListProps,
  ListItemProps,
  DropDownListOpenEvent,
  DropDownListChangeEvent,
} from '@progress/kendo-react-dropdowns';
import { DropDownList as StyledDropDownList } from '../../simple-styled-components';
import { enums, get } from '../../../../utils';
import dayjs from 'dayjs';
import { Spinner } from '../../../base/spinner';
import { FormContext } from '../../../../contexts/form-context';
import { CallCenterDomainModel } from '../../../../utils/remote-config-manager';
import * as dm from '../../../../data-models';
import { useAvailableCallbackTimes } from '../../../../hooks/use-data';

export interface CustomListItemProps extends ListItemProps {
  dataItem: dm.CallCenterHoursDropDownItem;
}

export interface CustomDropDownListProps
  extends Omit<DropDownListProps, 'data'> {
  data?: any[] | string;
}

const DropDownList: (p: CustomDropDownListProps) => React.ReactElement = (
  p,
) => {
  const [staticMode, setStaticMode] = React.useState(true);

  const { formContext } = React.useContext(FormContext);
  const { data: availableTimesData, isFetching } = useAvailableCallbackTimes(
    get(formContext, 'person.companyCode', '0'),
    p.data && typeof p.data === 'string'
      ? dayjs(p.data?.split('.')[1]).format('YYYYMMDD')
      : '',
  );

  React.useEffect(() => {
    if (p.data && typeof p.data === 'string') {
      setStaticMode(false);
    }
  }, [p.data]);

  const onChangeHandler = React.useCallback(
    (e: DropDownListChangeEvent) => {
      if (e.value.disabled) {
        return;
      }
      p.onChange?.(e);
    },
    [p],
  );

  const getData = React.useCallback(() => {
    // currently only supporting available call center times based on type
    if (
      !(p.data as string).startsWith(
        enums.DataSources.availableCallCenterTimesBasedOn,
      )
    ) {
      throw new Error('Invalid data source');
    }

    const closureDates: CallCenterDomainModel[] = get(
      formContext,
      'callCenter.closures',
      [],
    );

    const callbacks = availableTimesData?.availableTimesArray || [];

    callbacks.forEach((hour) => {
      if (
        closureDates.some((d) => {
          return (
            dayjs(d.start).tz('America/New_York') <= dayjs(hour.startTime) &&
            dayjs(d.end).tz('America/New_York') >=
              dayjs(hour.startTime).add(1, 'h')
          );
        }) ||
        new Date() > new Date(hour.startTime)
      ) {
        hour.disabled = true;
      }
    });

    return callbacks;
  }, [availableTimesData?.availableTimesArray, formContext, p.data]);

  const itemRender = (
    li: React.ReactElement<HTMLLIElement>,
    itemProps: CustomListItemProps,
  ) => {
    if (itemProps?.dataItem?.disabled) {
      const props = {
        ...li.props,
        className: 'k-item k-state-disabled',
      };
      return React.cloneElement(li, props, li.props.children);
    }
    return li;
  };

  if (p.data && typeof p.data === 'string' && !staticMode && isFetching) {
    return <Spinner></Spinner>;
  }

  return (
    <>
      {p.data && (
        <StyledDropDownList
          {...p}
          data={
            p.data && typeof p.data !== 'string' && staticMode
              ? p.data
              : getData()
          }
          itemRender={itemRender}
          onOpen={(e: DropDownListOpenEvent) => {
            // When wrapped in conversational UI, dropdown intermittently does not recieve focus onOpen.
            // This causes onBlur to not fire, leaving the popup open when clicking outside the parent elem.
            // Fixed in lastest 5.5.0 dev build, but this is a workaround until released in a stable build.
            e.target.focus();
          }}
          onChange={onChangeHandler}
        />
      )}
    </>
  );
};

export default DropDownList;
