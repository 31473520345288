import React from 'react';
import { Document } from '../../../../../../data-models';
import useAsyncHandler from '../../../../../../hooks/use-async-handler';
import {
  DOWNLOAD_RETRY_INTERVAL_MS,
  generateDownloadHandler,
  MAX_DOWNLOAD_RETRIES,
} from '../../../../../documents/item-render';
import { CardItemContainer } from '../../../components/simple-components';
import {
  DocumentLink,
  StyledNotification,
  ToastNotificationGroupStyle,
  ButtonStyledLink,
  Dialog,
  Text,
} from '../../../../simple-styled-components';
import dayjs from 'dayjs';

import { colors } from '../../../../../../style';

type Props = {
  item: Document;
  index: number;
};
export const LetterItem = ({ item, index }: Props) => {
  const [displayToast, setDisplayToastValue] = React.useState(false);

  const {
    data: s3Url,
    error: showError,
    setError: setShowError,
    isBusy: downloading,
    startRequest,
    reset,
  } = useAsyncHandler(
    generateDownloadHandler(item),
    undefined,
    undefined,
    (r) => r !== 'pending',
    '',
    MAX_DOWNLOAD_RETRIES,
    DOWNLOAD_RETRY_INTERVAL_MS,
  );

  const setDisplayToast = () => {
    setDisplayToastValue(true);
  };

  const openDocument = React.useCallback(() => {
    startRequest();
    setDisplayToast();
  }, [startRequest]);

  const [displayModal, setDisplayModal] = React.useState(false);
  const [modalLink, setModalLink] = React.useState('');
  const openModal = React.useCallback(
    (link: string) => {
      setModalLink(link);
      setDisplayModal(true);
    },
    [setDisplayModal],
  );

  const closeModal = () => {
    setDisplayModal(false);
    reset(); // Reset the s3Url state
  };

  React.useEffect(() => {
    if ((s3Url as any).length > 0 && s3Url !== 'pending') {
      openModal(s3Url);
      setDisplayToastValue(false);
    }
  }, [s3Url, openModal]);

  React.useEffect(() => {
    if (showError) {
      setTimeout(() => {
        setShowError(false);
      }, 4000);
    }
  }, [setShowError, showError]);

  return (
    <>
      <ToastNotificationGroupStyle className="position-fixed">
        {showError && (
          <StyledNotification
            key="error"
            type={{
              style: 'error',
              icon: true,
            }}
            closable={true}
            onClose={() => setShowError(false)}
          >
            Sorry, there was a problem retrieving {item.name} from{' '}
            {dayjs(item.receivedDate).format('MM/DD/YY')}.
          </StyledNotification>
        )}
        {displayToast && (
          <StyledNotification
            key="info"
            type={{ style: 'info', icon: true }}
            closable={true}
            onClose={() => setDisplayToastValue(false)}
          >
            Retrieving your letter for you. This could take up to a minute.
          </StyledNotification>
        )}
      </ToastNotificationGroupStyle>
      <CardItemContainer key={item.fileName}>
        <div className="tw-p-3 tw-flex tw-justify-between tw-w-[100%]">
          <div>
            <DocumentLink
              id={`document-link-${index}`}
              onClick={openDocument}
              disabled={downloading}
              style={{
                textDecoration: 'underline',
                textAlign: 'left',
                fontWeight: 600,
              }}
            >
              {item.name}
            </DocumentLink>
          </div>
          <div>{dayjs(item.receivedDate).format('MM/DD/YY')}</div>
        </div>
      </CardItemContainer>

      {displayModal && (
        <Dialog
          id={`document-available-modal`}
          onClose={closeModal}
          title={'Document Available'}
        >
          <Text
            color={colors.warmNeutralL3}
            className="p-3 pb-4"
            style={{ fontSize: 16 }}
          >
            Your document has been securely retrieved and is now available for
            viewing.
          </Text>
          <ButtonStyledLink
            id={`open-document-link`}
            href={modalLink}
            target="_blank"
          >
            Open Document
          </ButtonStyledLink>
        </Dialog>
      )}
    </>
  );
};
