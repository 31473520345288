import React from 'react';
import { colors } from '../../../style';
import { ClipLoader } from 'react-spinners';
import dayjs from 'dayjs';
import {
  StyledNotification,
  ToastNotificationGroupStyle,
  ListContainer,
  Text,
  DocumentLink,
} from '../../base/simple-styled-components';
import { getDocument } from '../../../utils/web-apis-client';
import useAsyncHandler from '../../../hooks/use-async-handler';

export const MAX_DOWNLOAD_RETRIES = 45;
export const DOWNLOAD_RETRY_INTERVAL_MS = 2000;

export const generateDownloadHandler = (item: any) => {
  return async () => getDocument(item.caseId, item.documentKey);
};

/**
 * MyItemRender - Renders custom items in the ListView component
 * @param props
 */
const ItemRender: (props: any) => React.ReactElement = (props) => {
  const item = props.dataItem;
  const index = props.index;
  const [displayToast, setDisplayToastValue] = React.useState(
    props.showInfoToastInitial || false,
  );
  const {
    data: s3Url,
    error: showError,
    setError: setShowError,
    isBusy: downloading,
    startRequest,
  } = useAsyncHandler(
    generateDownloadHandler(item),
    undefined,
    undefined,
    (r) => r !== 'pending',
    '',
    MAX_DOWNLOAD_RETRIES,
    DOWNLOAD_RETRY_INTERVAL_MS,
  );

  const setDisplayToast = () => {
    setDisplayToastValue(true);
    setTimeout(() => {
      setDisplayToastValue(false);
    }, 4000);
  };

  const openDocument = React.useCallback(() => {
    setDisplayToast();
    startRequest();
  }, [startRequest]);

  React.useEffect(() => {
    if (s3Url.length > 0 && s3Url !== 'pending') {
      props.openModal(s3Url);
    }
  }, [props, s3Url]);

  React.useEffect(() => {
    if (showError) {
      setTimeout(() => {
        setShowError(false);
      }, 4000);
    }
  }, [setShowError, showError]);

  return (
    <>
      <ToastNotificationGroupStyle className="position-fixed">
        {showError && (
          <StyledNotification
            key="error"
            type={{
              style: 'error',
              icon: true,
            }}
            closable={true}
            onClose={() => setShowError(false)}
          >
            Sorry, there was a problem retrieving {item.name} from{' '}
            {dayjs(item.receivedDate).format('MM/DD/YY')}.
          </StyledNotification>
        )}
        {displayToast && (
          <StyledNotification
            key="info"
            type={{ style: 'info', icon: true }}
            closable={true}
            onClose={() => setDisplayToastValue(false)}
          >
            Retrieving your letter for you. This could take up to a minute.
          </StyledNotification>
        )}
      </ToastNotificationGroupStyle>

      <ListContainer
        id={`document-list-container-${index}`}
        style={{ margin: 0 }}
        className="flex-column flex-md-row"
      >
        {/* StartDate */}
        <Text
          id={`document-date-${index}`}
          color={colors.sirocco}
          className="d-none d-md-block col-md-3"
        >
          {dayjs(item.receivedDate).format('MMM D, YYYY')}
        </Text>

        {/* Title */}
        <div className="col-12 col-md-5">
          <DocumentLink
            id={`document-link-${index}`}
            onClick={openDocument}
            disabled={downloading}
          >
            {item.name}
          </DocumentLink>
          <ClipLoader
            size={15}
            loading={downloading}
            color={colors.primaryBlue}
            css="margin-left: 5px; margin-top: 4px"
          />
        </div>

        {/* ClaimID */}
        <div className="p-0 d-flex flex-row col-md-4">
          <Text
            id={`document-claimid-${index}`}
            color={colors.warmNeutralL3}
            className="col-8 col-md-12"
          >
            {item.caseId}
          </Text>

          <Text
            id={`document-date-mobile-${index}`}
            color={colors.sirocco}
            className="col-4 d-md-none"
          >
            {dayjs(item.receivedDate).format('MM/DD/YY')}
          </Text>
        </div>
      </ListContainer>
    </>
  );
};

export default ItemRender;
