import { useState } from 'react';
import * as dm from '../../../../../../data-models';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';
import { PageChangeEvent } from '@progress/kendo-react-data-tools';
import PagerSection from '../../../../pager-section';
import {
  MultipleCardContainer,
  TasksCardItemContainer,
  StatusFlag,
} from '../../../components/simple-components';
import { taskStatusConfigMap } from '../../../../../tasks/utils';

type Props = {
  EETasks: dm.Task[];
};

export const AssignedTasks = ({ EETasks }: Props) => {
  const [page, setPage] = useState({
    skip: 0,
    take: 3,
  });

  const handlePageChange = (e: PageChangeEvent) => {
    setPage({
      skip: e.skip,
      take: e.take,
    });
  };

  const { skip, take } = page; //Used for Pagination

  return (
    <MultipleCardContainer id="tasks-assigned-card">
      {EETasks && EETasks?.length > 0 ? (
        <>
          {EETasks.slice(skip, skip + take).map((task) => {
            const statusConfig = taskStatusConfigMap?.[task.taskStatusId];

            return (
              <Link
                to={`/app/task/${task.taskId}/details/${task.taskCodeName}`}
                className="tw-text-unm-primary01-100 tw-no-underline"
                key={task.taskId}
              >
                <TasksCardItemContainer>
                  <div className="tw-font-semibold tw-line-clamp-1 tw-mb-3">
                    {task.taskTitle}
                  </div>
                  <div className="tw-flex tw-justify-between tw-items-center">
                    <div className="tw-mr-1.5">
                      <div className="tw-text-xs">
                        Due {dayjs(task.taskDueDate).format('MM/DD/YYYY')}
                      </div>
                      <div className="tw-text-xs tw-text-unm-interactive01-400 tw-underline tw-mb-1 tw-uppercase">
                        {task.taskCategory}
                      </div>
                    </div>
                    <div>
                      {task.taskCategory === 'Required' ? (
                        <StatusFlag
                          data-testid={`task-${task.taskId}-status-flag`}
                          background={statusConfig.background}
                          color={statusConfig.color}
                        >
                          {statusConfig.text}
                        </StatusFlag>
                      ) : (
                        <StatusFlag
                          data-testid={`task-${task.taskId}-status-flag`}
                          background={`#D9EBF4`}
                          color={`#037CB7`}
                        >
                          Optional
                        </StatusFlag>
                      )}
                    </div>
                  </div>
                </TasksCardItemContainer>
              </Link>
            );
          })}
          <div className="tw-flex-grow"></div>
          <PagerSection
            skip={skip}
            take={take}
            handlePageChange={handlePageChange}
            elements={EETasks}
          />
        </>
      ) : (
        <div className="tw-font-semibold tw-text-sm">
          You currently have no assigned tasks.
        </div>
      )}
    </MultipleCardContainer>
  );
};
