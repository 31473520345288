import { useState } from 'react';
import * as dm from '../../../../../../data-models';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';
import { taskStatusConfigMap } from '../../../../../tasks/utils';
import { PageChangeEvent } from '@progress/kendo-react-data-tools';
import PagerSection from '../../../../pager-section';
import {
  MultipleCardContainer,
  TasksCardItemContainer,
  StatusFlag,
} from '../../../components/simple-components';

type Props = {
  receivedTasks?: dm.Task[];
};

export const ReceivedTasks = ({ receivedTasks }: Props) => {
  const [page, setPage] = useState({
    skip: 0,
    take: 3,
  });

  const handlePageChange = (e: PageChangeEvent) => {
    setPage({
      skip: e.skip,
      take: e.take,
    });
  };

  const { skip, take } = page; //Used for Pagination

  return (
    <MultipleCardContainer id="tasks-received-card">
      {receivedTasks && receivedTasks?.length > 0 ? (
        <>
          {receivedTasks?.slice(skip, skip + take).map((task) => (
            <Link
              to={`/app/task/${task.taskId}/details/${task.taskCodeName}`}
              className="tw-text-unm-primary01-100 tw-no-underline"
              key={task.taskId}
            >
              <TasksCardItemContainer>
                <div className="tw-font-semibold tw-line-clamp-1">
                  {task.taskTitle}
                </div>
                <div className="tw-flex tw-justify-between tw-items-center">
                  <div className="tw-mr-1.5">
                    <div className="tw-text-xs">
                      Due {dayjs(task.taskDueDate).format('MM/DD/YYYY')}
                    </div>
                  </div>
                  <div>
                    {taskStatusConfigMap?.[task.taskStatusId] && (
                      <StatusFlag
                        data-testid={`task-${task.taskId}-status-flag`}
                        background={`#ECF9E6`}
                        color={`#447430`}
                      >
                        {taskStatusConfigMap?.[task.taskStatusId].text}
                      </StatusFlag>
                    )}
                  </div>
                </div>
              </TasksCardItemContainer>
            </Link>
          ))}
          <div className="tw-flex-grow"></div>
          <PagerSection
            skip={skip}
            take={take}
            handlePageChange={handlePageChange}
            elements={receivedTasks}
          />
        </>
      ) : (
        <div className="tw-font-semibold tw-text-sm">
          You currently have no completed tasks.
        </div>
      )}
    </MultipleCardContainer>
  );
};
