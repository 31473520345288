import { AssignedTasks } from './tasks-assigned';
import { ReceivedTasks } from './tasks-received';
import { OverviewLetters } from './letters';
import { OverviewDocuments } from './documents';
import * as dm from '../../../../../data-models';
import { IGeneratedCase } from '../..';
import { useState } from 'react';
import { TabCards } from '../tab-cards';
import './index.css';

type Props = {
  EETasks: dm.Task[];
  claim: IGeneratedCase;
  receivedTasks?: dm.Task[];
  NTNId: string;
};

export const OverviewTab = ({
  EETasks,
  claim,
  receivedTasks,
  NTNId,
}: Props) => {
  const [openSection, setOpenSection] = useState<string | null>(null);

  const sectionMapping = {
    tasks: {
      title: 'Assigned tasks ' + (EETasks?.length ? `(${EETasks.length})` : ''),
      headerLinkName: 'Go to all tasks',
      headerLink: '/app/tasks/' + NTNId,
      component: <AssignedTasks EETasks={EETasks} />,
      popupContent:
        'Tasks highlight information needed by Unum to assist in the management of your case. Please complete your required tasks by the due date provided or as soon as possible. Recommended tasks are optional.',
    },
    caseHistory: {
      title:
        'Completed tasks ' +
        (receivedTasks?.length ? `(${receivedTasks.length})` : ''),
      headerLinkName: 'Go to all tasks',
      headerLink: '/app/tasks/' + NTNId,
      component: <ReceivedTasks receivedTasks={receivedTasks} />,
      popupContent:
        'Information you have provided Unum to assist in the management of your case.',
    },
    documents: {
      title: 'Documents',
      headerLinkName: 'Go to all documents',
      headerLink: '/app/documents',
      component: <OverviewDocuments claim={claim} />,
      popupContent:
        'Documents submitted by you or on your behalf that Unum has received for your case.',
    },
    letters: {
      title: 'Letters from Unum',
      headerLinkName: 'Go to all documents',
      headerLink: '/app/documents',
      component: <OverviewLetters claim={claim} />,
      popupContent: 'All letters sent to you by Unum regarding your case.',
    },
  };

  return (
    <TabCards
      sectionMapping={sectionMapping}
      openSection={openSection}
      setOpenSection={setOpenSection}
    />
  );
};
