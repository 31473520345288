import React, { useEffect } from 'react';
import { PersonDataContext } from '../../contexts/person-data-context';
import ClaimCard from '../base/claim-card';
import { Link } from 'react-router-dom';
import Button from '../base/button';
import { isDueTask, isReceivedTask } from '../tasks/utils';
import { KenticoDataContext } from '../../contexts/kentico-data-context';
import * as dm from '../../data-models';
import { trackFSVars, trackGA4Event } from '../../utils/analytics';
import NoClaimsView from './no-claims';
import { TaskType } from '../tasks';
import { enums, showPaymentPageBasedOnEmployerId } from '../../utils';
import { styledButtons } from '../../components/base/button';
import { PreferencesContext } from '../../contexts/preferences-context';

type Props = {
  tasks: TaskType[];
  remoteCfg: { [name: string]: string };
  isEmployerEnabled: boolean;
  vbCoverageData: dm.ICoverageV1ResponseExt;
  failedWithData?: boolean;
  absenceNotifications?: dm.IAbsenceNotifications;
  allTasks?: any;
};

type FontProps = {
  children: React.ReactNode;
  className?: string;
};

type LinkProps = {
  children: React.ReactNode;
  to: string;
};

type ExternalLinkProps = {
  children: React.ReactNode;
  href: string;
  onClick?: () => void;
};

type SeparatorProps = {
  color: 'blue' | 'green';
};

type ChildProps = {
  children: React.ReactNode;
};

type ButtonModProps = {
  id: string;
  buttonStyle?: keyof typeof styledButtons;
  onClick?: () => void;
  className?: string;
  children?: React.ReactNode;
  [key: string]: any;
};

export const MenuSection = ({ children }: ChildProps) => {
  return (
    <div className="tw-flex-1 tw-basis-full sm:tw-basis-1/3">{children}</div>
  );
};

export const ListContainer = ({ children }: ChildProps) => {
  return <ul className="tw-list-none tw-m-0 tw-p-0">{children}</ul>;
};

export const ListItem = ({ children }: ChildProps) => {
  return <li className="tw-mb-2.5">{children}</li>;
};

export const Title = ({ children, className = '' }: FontProps) => {
  return (
    <h1
      className={`tw-text-2xl md:tw-text-4xl tw-font-normal tw-text-unm-interactive01-800 tw-mb-5 tw-leading-10 ${className}`}
    >
      {children}
    </h1>
  );
};

export const H2 = ({ children, className = '' }: FontProps) => {
  return (
    <h2
      className={`tw-text-sm md:tw-text-lg lg:tw-text-2xl tw-font-medium ${className}`}
    >
      {children}
    </h2>
  );
};

export const SidebarTitle = ({ children }: FontProps) => {
  return <H2 className="">{children}</H2>;
};

export const StyledLink = ({ children, to }: LinkProps) => {
  return (
    <Link
      to={to}
      className="tw-text-sm md:tw-text-base tw-font-semibold tw-text-unm-interactive01-400 tw-no-underline"
    >
      {children}
    </Link>
  );
};

export const StyledExternalLink = ({
  children,
  href,
  onClick,
}: ExternalLinkProps) => {
  return (
    <a
      href={href}
      className="tw-text-sm md:tw-text-base tw-font-semibold tw-text-unm-interactive01-400 tw-no-underline"
      onClick={onClick}
    >
      {children}
    </a>
  );
};

const ButtonMod = ({
  id,
  buttonStyle,
  onClick,
  className,
  children,
  ...others
}: ButtonModProps) => {
  return (
    <Button
      id={id}
      buttonStyle={buttonStyle}
      onClick={onClick}
      className={`text-base hover:no-underline ${className}`}
      {...others}
    >
      {children}
    </Button>
  );
};

export const Separator = ({ color }: SeparatorProps) => {
  const colorClass =
    color === 'blue' ? 'tw-bg-unm-interactive01-200' : 'tw-bg-unm-approved';
  return (
    <hr
      className={`tw-w-36 sm:tw-w-full lg:tw-w-20 tw-h-1 ${colorClass} tw-my-3 tw-ml-0 tw-rounded-lg tw-border-none`}
    />
  );
};

const getShowPaymentInfo = async (
  setShowPaymentInfo: Function,
  ERContent: any,
) => {
  const showInfo = await showPaymentPageBasedOnEmployerId(ERContent);
  setShowPaymentInfo(showInfo);
};

const Summary = ({
  tasks,
  remoteCfg,
  isEmployerEnabled,
  vbCoverageData,
  failedWithData,
  absenceNotifications,
  allTasks,
}: Props) => {
  const { personData } = React.useContext(PersonDataContext);
  const { callCenter } = React.useContext(KenticoDataContext);
  const { ERContent } = React.useContext(PreferencesContext);
  const hasCoverage = vbCoverageData.hasCoverage;
  const [showPayments, setShowPayments] = React.useState(false);

  const ERTasks = tasks.filter(
    (t: dm.Task) =>
      isDueTask(t?.taskStatusId) && t.assigneeId === 1 && t.taskStatusId !== 10,
  );

  const EETasks = tasks.filter(
    (t: dm.Task) => isDueTask(t?.taskStatusId) && t.assigneeId === 2,
  );

  const allReceivedTasks = tasks.filter(
    (t: dm.Task) => isReceivedTask(t?.taskStatusId) && t.assigneeId === 2,
  );

  const mufmLink = remoteCfg
    ? remoteCfg[enums.RemoteConfigKeys.iServicesIDPURL]
    : '#';

  useEffect(() => {
    if (personData?.person?.companyCode) {
      const coverageNames =
        vbCoverageData.coverages?.map((c) => c.productDescription) || [];
      coverageNames.sort();

      trackFSVars({
        vbCoverages: coverageNames.join(', '),
        vbEmployerEnabled: isEmployerEnabled,
      });
    }
  }, [
    isEmployerEnabled,
    personData?.person?.companyCode,
    vbCoverageData.coverages,
  ]);

  useEffect(() => {
    getShowPaymentInfo(setShowPayments, ERContent);
  }, [ERContent]);

  return personData?.generated?.parentNTNs.length > 0 ? (
    <div
      id="has-claims-view"
      className="tw-grid tw-grid-cols-1 md:tw-grid-cols-12 tw-gap-4"
    >
      <div className="tw-col-span-12 lg:tw-col-span-9 tw-px-0">
        <Title>{`Welcome back, ${personData?.person?.firstName}!`}</Title>
        {personData?.generated?.parentNTNs.map((claim: any, index: number) => (
          <div
            key={`claim-card-${index}`}
            style={{ position: 'relative', marginBottom: '2em' }}
          >
            <ClaimCard
              keyId={index}
              claim={claim}
              ERTasks={ERTasks}
              EETasks={EETasks}
              failedWithData={failedWithData}
              allReceivedTasks={allReceivedTasks}
              absenceNotifications={absenceNotifications}
              personData={personData}
              allTasks={allTasks}
              showPayments={showPayments}
            />
          </div>
        ))}
      </div>

      <div className="tw-col-span-12 lg:tw-col-span-3" id="summary-sidebar">
        <div className="tw-flex tw-flex-wrap sm:tw-flex-nowrap lg:tw-flex-col tw-gap-4 lg:tw-ml-2">
          {hasCoverage && isEmployerEnabled && (
            <MenuSection>
              <>
                <SidebarTitle>Supplemental benefits</SidebarTitle>
                <Separator color="green" />
                <ListContainer>
                  <ListItem>
                    <StyledLink to="/app/supplemental">
                      View my supplemental coverage
                    </StyledLink>
                  </ListItem>
                  <ListItem>
                    <StyledLink to="/app/supplemental">
                      File a supplemental benefit claim
                    </StyledLink>
                  </ListItem>
                  <ListItem>
                    <StyledExternalLink
                      href={
                        remoteCfg
                          ? remoteCfg[enums.RemoteConfigKeys.iServicesIDPURL]
                          : '#'
                      }
                      onClick={() => {
                        trackGA4Event({
                          action: 'click',
                          target:
                            'View my existing supplemental benefit claims',
                        });
                      }}
                    >
                      View my existing supplemental benefit claims
                    </StyledExternalLink>
                  </ListItem>
                </ListContainer>
              </>
            </MenuSection>
          )}
          {hasCoverage && !isEmployerEnabled && (
            <MenuSection>
              <>
                <SidebarTitle>Supplemental benefits</SidebarTitle>
                <Separator color="green" />
                <ListContainer>
                  <ListItem>
                    <StyledExternalLink href={mufmLink}>
                      View my supplemental coverage
                    </StyledExternalLink>
                  </ListItem>
                  <ListItem>
                    <StyledExternalLink href={mufmLink}>
                      File a supplemental benefit claim
                    </StyledExternalLink>
                  </ListItem>
                  <ListItem>
                    <StyledExternalLink href={mufmLink}>
                      View my existing supplemental benefit claims
                    </StyledExternalLink>
                  </ListItem>
                </ListContainer>
              </>
            </MenuSection>
          )}
          <MenuSection>
            <SidebarTitle>Update key dates</SidebarTitle>
            <Separator color="blue" />
            <ListContainer>
              <ListItem>
                <StyledLink to="/app/form/last-day-worked">
                  Last day of work
                </StyledLink>
              </ListItem>
              <ListItem>
                <StyledLink to="/app/form/return-to-work">
                  Return to work
                </StyledLink>
              </ListItem>
              <ListItem>
                <StyledLink to="/app/form/delivery-date">
                  Delivery date
                </StyledLink>
              </ListItem>
              <ListItem>
                <StyledLink to="/app/form/surgery-date">
                  Surgery details
                </StyledLink>
              </ListItem>
              <ListItem>
                <StyledLink to="/app/form/extension-request">
                  Request extension
                </StyledLink>
              </ListItem>
            </ListContainer>
          </MenuSection>
          <MenuSection>
            <SidebarTitle>Provide Unum details</SidebarTitle>
            <Separator color="blue" />
            <ListContainer>
              <ListItem>
                <StyledLink to="/app/form/intermittent-absence">
                  Report intermittent absence
                </StyledLink>
              </ListItem>
              <ListItem>
                <StyledLink to="/app/form/e-signature">
                  Sign e-Signature authorization forms
                </StyledLink>
              </ListItem>
              <ListItem>
                <StyledLink to="/app/upload">Upload a document</StyledLink>
              </ListItem>
              <ListItem>
                <StyledLink to="/app/form/physician-information">
                  Update physician information
                </StyledLink>
              </ListItem>
            </ListContainer>
          </MenuSection>
          <MenuSection>
            <SidebarTitle>Get support</SidebarTitle>
            <Separator color="blue" />
            <ListContainer>
              <ListItem>
                <ButtonMod
                  id="live-chat-button"
                  buttonStyle={enums.ButtonTypes.link}
                  onClick={() => (global as any)?.displayLiveChat?.(true)}
                  className={`live-chat-${
                    callCenter.open ? 'online' : 'offline'
                  }`}
                >
                  Live chat
                </ButtonMod>
              </ListItem>
              <ListItem>
                <StyledLink to="/app/form/scheduled-callback">
                  Schedule a callback
                </StyledLink>
              </ListItem>
              <ListItem>
                <ButtonMod
                  id="survey-button"
                  buttonStyle={enums.ButtonTypes.link}
                  onClick={() => (window as any)?.ShowSurveyFeedback?.()}
                >
                  Leave feedback
                </ButtonMod>
              </ListItem>
            </ListContainer>
          </MenuSection>
        </div>
      </div>
    </div>
  ) : (
    <NoClaimsView />
  );
};

export default Summary;
