import React from 'react';
import { Link } from 'react-router-dom'; // looks like this was removed from the overview tab - non-overview tab could follow suit?
import {
  ExpansionPanel,
  ExpansionPanelActionEvent,
  ExpansionPanelContent,
} from '@progress/kendo-react-layout';
import { Reveal } from '@progress/kendo-react-animation';
import {
  TabCardContainer,
  CardItemTitleMobile,
  CardItemTitleDesktop,
  CardItemTitleNonOverview,
  HelpSection,
  TabCardContainerPayments,
  HelpIcon,
  NoPaymentsContainer,
} from '../../components/simple-components';
import './index.css';
import { isEmptyObject } from '../payments';

export type SectionMappingProps = {
  sectionMapping: {
    [key: string]: {
      title: string;
      headerLinkName: string;
      headerLink: string;
      component: React.ReactNode;
      popupContent: string;
    };
  };
};

type ReturnComponentProps = SectionMappingProps & {
  openSection: string | null;
  setOpenSection: React.Dispatch<React.SetStateAction<string | null>>;
};

export const TabCards = ({
  sectionMapping,
  openSection,
  setOpenSection,
}: ReturnComponentProps) => {
  return (
    <div className="tw-grid tw-gap-4 md:tw-grid-cols-2">
      {Object.entries(sectionMapping).map(
        ([
          section,
          { title, headerLinkName, headerLink, component, popupContent },
        ]) => (
          <TabCardContainer key={section}>
            {/* Mobile view */}
            <div className="tw-block md:tw-hidden">
              <ExpansionPanel
                title={
                  <CardItemTitleMobile>
                    <HelpSection
                      title={title}
                      popupContent={popupContent}
                      headerLinkName={headerLinkName}
                      headerLink={headerLink}
                      showDesktopLink={false}
                    />
                  </CardItemTitleMobile>
                }
                expanded={openSection === section}
                onAction={(event: ExpansionPanelActionEvent) => {
                  setOpenSection(event.expanded ? null : section);
                }}
                className="tw-border tw-rounded-lg tw-shadow-lg"
              >
                <Reveal>
                  {openSection === section && (
                    <ExpansionPanelContent>{component}</ExpansionPanelContent>
                  )}
                </Reveal>
              </ExpansionPanel>
            </div>

            {/* Desktop view */}
            <div className="tw-hidden md:tw-block tw-rounded tw-h-full tw-p-2 tw-px-3 tw-bg-white">
              <CardItemTitleDesktop>
                <HelpSection
                  title={title}
                  popupContent={popupContent}
                  headerLinkName={headerLinkName}
                  headerLink={headerLink}
                  showDesktopLink
                />
              </CardItemTitleDesktop>
              {component}
            </div>
          </TabCardContainer>
        ),
      )}
    </div>
  );
};

export const TabCardsNonOverview = ({
  sectionMapping,
}: SectionMappingProps) => {
  return (
    <div className="tw-grid tw-gap-2">
      {!isEmptyObject(sectionMapping) ? (
        Object.entries(sectionMapping).map(
          ([
            section,
            { title, headerLinkName, headerLink, component, popupContent },
          ]) => (
            <TabCardContainerPayments key={section}>
              <div className="tw-rounded tw-pt-2 tw-px-3 tw-bg-[#FCFCFC]">
                <CardItemTitleNonOverview>
                  <div className="tw-flex tw-justify-start tw-items-center">
                    <p className="tw-inline-block tw-m-0 tw-mr-2">{title}</p>
                    <HelpIcon popupContent={popupContent}></HelpIcon>
                  </div>
                  <Link
                    to={headerLink}
                    className="tw-no-underline tw-leading-8 tw-text-sm tw-align-middle"
                  >
                    <div>{headerLinkName}</div>
                  </Link>
                </CardItemTitleNonOverview>
                {component}
              </div>
            </TabCardContainerPayments>
          ),
        )
      ) : (
        <NoPaymentsContainer></NoPaymentsContainer>
      )}
    </div>
  );
};
