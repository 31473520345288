/* eslint-disable @typescript-eslint/no-unused-vars */
import { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { colors } from '../../../style';
import infoIcon from '../../../images/info-circle.svg';
import arrow from '../../../images/down_arrow.svg';
import {
  get,
  prefixObjectValues,
  analyticsTrackEvent,
  enums,
  claimStage,
  getLocalizedOrDefault,
} from '../../../utils';
import { Reveal } from '@progress/kendo-react-animation';
import * as dm from '../../../data-models';
import useRefreshHandler from '../../../hooks/use-refresh-handler';
import FadeLoader from 'react-spinners/FadeLoader';
import { Column } from './card-items/shared-styles';
import { StatusFlag } from '../../base/claim-card/components/simple-components';
import {
  isDueTask,
  isReceivedTask,
  taskAppliesToNTN,
  claimStatusesThatUseTaskFlag,
} from '../../tasks/utils';
import {
  sortAssignedTasks,
  checkForRequiredTasks,
  sortReceivedTasks,
} from './utils';
import { OverviewTab } from './tabs/overview';
import { DetailsTab } from './tabs/details';
import { PaymentsTab } from './tabs/payments';
import { StatusTracker } from './status-tracker';
import dayjs from 'dayjs';
import { KenticoDataContext } from '../../../contexts/kentico-data-context';

export interface IGeneratedCase {
  NTNId: string;
  accommodations: Array<dm.Accommodation>;
  claims: Array<dm.Claim>;
  leaves: Array<dm.Leave>;
  isIntegrated: boolean;
}

interface IClaimCard {
  keyId?: number;
  claim: IGeneratedCase;
  ERTasks: Array<dm.Task>;
  EETasks: Array<dm.Task>;
  failedWithData?: boolean;
  allReceivedTasks?: Array<dm.Task>;
  absenceNotifications?: dm.IAbsenceNotifications;
  personData?: dm.PersonData;
  allTasks?: any;
  showPayments?: boolean;
}

type CardBodyType = {
  expand: boolean;
};

const Card = styled.div`
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 3px 6px rgba(46, 46, 46, 0.16);
  max-width: 992px;
  overflow: hidden;
  width: 100%;

  .k-animation-container {
    z-index: 1000 !important;
  }
`;

export const Arrow = styled.img.attrs({
  alt: 'Expand',
  src: arrow,
})`
  cursor: pointer;
  margin-right: 0.5em;
  transform: ${(props: { rotate: string }) =>
    props.rotate === 'true' ? 'rotate(180deg)' : 'rotate(0deg)'};
  transition: all 0.5s;
  user-select: none;
  width: 2.75em;

  @media (min-width: 500px) {
    margin-right: 1em;
  }
`;
const CardHeader = styled.div`
  align-items: stretch;
  background: #fff;
  border-left: solid 16px #037cb7;
  border-radius: 8px;
  box-shadow: 0 3px 6px rgba(46, 46, 46, 0.16);
  cursor: pointer;
  display: flex;
  position: relative;
  width: 100%;
  z-index: 2;
`;

const CardBody = styled.div<CardBodyType>`
  height: ${(p) => (p.expand ? '100%' : '0')};
  overflow: hidden;
  visibility: ${(p) => (p.expand ? 'visible' : 'hidden')};
  width: 100%;

  &:first-child {
    height: 100%;
    visibility: visible;
  }
`;

const LoadingCover = styled.div`
  height: 110px;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;

  & > span {
    width: 48px;
    height: 48px;
  }
`;

const CardItemsContainer = styled.div`
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 32px;
  padding-right: 32px;
  width: 100%;
`;

const StatusFlagMod = styled(StatusFlag)`
  @media (max-width: 420px) {
    margin-left: unset;
    margin-bottom: 8px;
  }
`;

const domIdsStatic = {
  rootNode: 'claims-card',
  cardTitle: 'claim-card-title',
  dropDownArrow: 'dropdown-arrow',
};

export const domIdsUnique = (prefix?: string) =>
  prefixObjectValues(prefix, domIdsStatic);

const getCardTitle = (claim: any) => {
  if (claim?.leaves?.length > 0) {
    return claim.leaves[0].reason ===
      enums.PregnancyTextChanges.pregnancyBondingLiteral
      ? enums.PregnancyTextChanges.pregnancyBondingLiteralNew
      : claim.leaves[0].reason;
  } else if (claim?.claims?.length > 0) {
    return claim.claims[0].claimType;
  } else if (claim?.accommodations?.length > 0) {
    const firstAccommodation = claim.accommodations[0];
    const details = firstAccommodation?.accommodationDetails || [];

    return details.length > 0
      ? details[0].category
      : firstAccommodation?.accommodationId;
  }
  return 'N/A';
};

const getAllStatuses = (claim: IGeneratedCase) => {
  const statuses: string[] = [];

  claim.claims.forEach((claim) => {
    if (claim.status) {
      statuses.push(claim.status);
    }
  });
  claim.leaves.forEach((leave) => {
    if (leave.requestStatus) {
      statuses.push(leave.requestStatus);
    }
  });

  return statuses;
};

const getDecidedLeaves = (claim: IGeneratedCase) => {
  const leaves = claim?.leaves;
  const selectedLeavePlans = leaves?.map((l) => l.selectedLeavePlans);
  const acm = claim?.accommodations;

  if (selectedLeavePlans.length > 0) {
    for (const element of selectedLeavePlans) {
      if (
        element?.some((plan) => plan.adjudicationStatus === 'Approved') ||
        element?.some((plan) => plan.adjudicationStatus === 'Accepted') ||
        element?.some((plan) => plan.adjudicationStatus === 'Mixed Decision') ||
        element?.some((plan) => plan.adjudicationStatus === 'Rejected') ||
        element?.some((plan) => plan.adjudicationStatus === 'Denied')
      ) {
        return 'Move';
      }
    }
  }

  if (acm?.length > 0) {
    for (const element of acm) {
      if (
        (element?.parentId === '' && element?.status === 'Approved') ||
        element?.status === 'Monitoring'
      ) {
        return 'Move';
      }
    }
  }
  return 'Stay';
};

const getDecisionLetters = (claim: IGeneratedCase, letters: dm.Document[]) => {
  return letters?.map(
    (l) =>
      (l.name === 'ADA Decision Notice' ||
        l.name === 'Designation Notice Letter' ||
        l.name === 'EP Not Satisfied Letter' ||
        l.name === 'Integrated Approval Letter' ||
        l.name === 'Leave Cancellation' ||
        l.name === 'Leave Denial Letter') &&
      l.caseId?.includes(claim.NTNId),
  );
};

const trackerStage = (
  parentCase: { status: string; createdDate: string } | undefined,
  claim: IGeneratedCase,
  currentDate?: string,
  letters: dm.Document[] = [],
) => {
  if (parentCase?.status === 'Closed') {
    return 'Closed';
  } else if (
    getDecidedLeaves(claim) === 'Move' &&
    getDecisionLetters(claim, letters)?.length > 0
  ) {
    return 'Management';
  } else if (dayjs(currentDate).isAfter(dayjs(parentCase?.createdDate))) {
    return 'Reviewing';
  } else {
    return 'Received';
  }
};

const getReviewingScenario = (
  claim: IGeneratedCase,
  caseTasks: dm.Task[],
  currentDate: string,
) => {
  const startDates = claim.leaves.map((l) => l.startDate);
  startDates.sort();
  const reqTasks = caseTasks?.filter(
    (t) =>
      (t.assigneeId === 2 || t.dualTask === true) &&
      t.taskCategory === 'Required' &&
      t.taskStatusId !== 5 &&
      t.taskStatusId !== 7,
  );

  if (reqTasks?.length === 0) {
    return 'Reviewing1';
  } else if (
    reqTasks !== undefined &&
    reqTasks?.length > 0 &&
    dayjs(currentDate).isAfter(dayjs(startDates[0]))
  ) {
    return 'Reviewing2';
  } else if (
    reqTasks !== undefined &&
    reqTasks?.length > 0 &&
    dayjs(currentDate).isBefore(dayjs(startDates[0]))
  ) {
    return 'Reviewing3';
  }
  return 'Reviewing1';
};

const getManagementScenario = (
  claim: IGeneratedCase,
  letters: dm.Document[],
  caseTasks: dm.Task[],
  currentDate: string,
) => {
  const startDates = claim.leaves.map((l) => l.startDate);
  startDates.sort();
  const reqTasks = caseTasks?.filter(
    (t) =>
      (t.assigneeId === 2 || t.dualTask === true) &&
      t.taskCategory === 'Required' &&
      t.taskStatusId !== 5 &&
      t.taskStatusId !== 7,
  );
  const dl = getDecisionLetters(claim, letters);

  if (dl.length !== 0 && reqTasks?.length === 0) {
    return 'Management1';
  } else if (
    dl.length !== 0 &&
    reqTasks?.length !== 0 &&
    dayjs(currentDate).isBefore(dayjs(startDates[0]))
  ) {
    return 'Management2';
  } else if (
    dl.length !== 0 &&
    reqTasks?.length !== 0 &&
    dayjs(currentDate).isAfter(dayjs(startDates[0]))
  ) {
    return 'Management3';
  }
  return 'Management1';
};

const doesAnySubCaseInNTNUseTaskFlag = (claim: IGeneratedCase) => {
  const statuses = getAllStatuses(claim);

  return statuses.some((status) =>
    claimStatusesThatUseTaskFlag.includes(status),
  );
};

const alwaysVisibleTabs = {
  overview: 'Overview',
  details: 'Details',
};

const addConditionallyVisibleTabs = (
  setVisible: Function,
  paymentsVisible: boolean | undefined = false,
) => {
  const tabList = alwaysVisibleTabs;

  if (paymentsVisible) {
    Object.defineProperty(tabList, 'payments', {
      enumerable: true,
      value: 'Payments',
    });
  }
  setVisible(tabList);
};

export type ExpandedType = (
  p: {
    NTNId: string;
    ariaExpand: boolean;
    areTasksDue?: boolean;
    failedWithData?: boolean;
    receivedTasks?: Array<dm.Task>;
    subStatusText: any;
    currentDate: string;
    letters: dm.Document[];
  } & IClaimCard,
) => React.ReactElement;

const ExpandedSection: ExpandedType = (p) => {
  const {
    claim,
    ariaExpand,
    NTNId,
    EETasks,
    failedWithData,
    receivedTasks,
    absenceNotifications,
    subStatusText,
    currentDate,
    letters,
    showPayments,
  } = p;
  const allLeaveIds = claim?.leaves?.map((l) => l.leaveId ?? '');
  const allClaimIds = claim?.claims?.map((l) => l.claimId ?? '');
  const allAccommodationsIds = claim?.accommodations?.map(
    (l) => l.accommodationId ?? '',
  );
  const allIds = [...allLeaveIds, ...allClaimIds, ...allAccommodationsIds];
  const { status } = useRefreshHandler(
    [enums.RefreshDetailTypes.caseDetails],
    allIds,
  );
  const parentCase = absenceNotifications?.absenceNotifications?.find(
    (n) => n.notificationCaseId === NTNId,
  );

  const [visibleTabs, setVisibleTabs] = useState(alwaysVisibleTabs);

  enum tabs {
    overview = 'Overview',
    details = 'Details',
    payments = 'Payments',
  }
  const [tab, setTab] = useState('Overview');

  useEffect(
    () => addConditionallyVisibleTabs(setVisibleTabs, showPayments),
    [showPayments],
  );

  const renderTabContent = () => {
    let tabContent;

    switch (tab) {
      case tabs.details:
        tabContent = <DetailsTab {...p} />;
        break;
      case tabs.payments:
        tabContent = (
          <PaymentsTab parentNTNId={NTNId} failedWithData={failedWithData} />
        );
        break;
      case tabs.overview:
      default:
        tabContent = (
          <OverviewTab
            EETasks={EETasks}
            claim={claim}
            receivedTasks={receivedTasks}
            NTNId={NTNId}
          />
        );
        break;
    }

    return tabContent;
  };

  return status !== enums.RefreshStatus.started ? (
    <CardBody expand={ariaExpand}>
      {/* Notification */}
      <CardItemsContainer
        className="tw-bg-unm-adhoc1 tw-px-0 md:tw-p-[16px] tw-pt-3"
        style={{ borderTop: '1px solid #007CB7' }}
      >
        <div className="tw-flex tw-text-xs md:tw-text-sm tw-font-normal md:tw-font-medium">
          <img alt="info" src={infoIcon} />
          <div className="tw-pl-3 tw-text-unm-interactive01-400 ">
            {subStatusText(
              trackerStage(parentCase, claim, currentDate, letters),
            )}
          </div>
        </div>
      </CardItemsContainer>

      <CardItemsContainer className="md:tw-hidden tw-p-0">
        <StatusTracker
          stage={trackerStage(parentCase, claim, currentDate, letters)}
        />
      </CardItemsContainer>

      <CardItemsContainer className="tw-px-0 md:tw-p-5 tw-pt-4">
        {/* Tab buttons */}
        <div className="tw-flex tw-justify-center tw-gap-6 md:tw-gap-8 pb-4">
          {Object.values(visibleTabs).map((tabName) => (
            <button
              // tw-transition-all tw-delay-100 tw-duration-300 tw-ease-in-out
              className={`
              tw-border-0 tw-rounded-md tw-py-2 tw-px-2 md:tw-px-4 tw-w-28 md:tw-w-36 tw-text-sm md:tw-text-lg
                ${
                  tab === tabName
                    ? ' tw-underline tw-text-unm-interactive01-400 tw-font-bold tw-bg-unm-interactive01-100 '
                    : ' tw-text-white tw-bg-unm-interactive01-400'
                }
              `}
              key={tabName}
              onClick={() => setTab(tabName)}
            >
              {tabName}
            </button>
          ))}
        </div>

        {/* cards container */}
        <div className="tw-bg-unm-container-bg tw-rounded tw-px-0 md:tw-p-4">
          {renderTabContent()}
        </div>
      </CardItemsContainer>
    </CardBody>
  ) : (
    <LoadingCover>
      <FadeLoader
        height={13}
        width={4}
        radius={5}
        margin={0}
        color={colors.blue}
      />
    </LoadingCover>
  );
};

const ClaimsCard: (p: IClaimCard) => React.ReactElement = (p) => {
  const {
    claim,
    keyId,
    EETasks,
    ERTasks,
    allReceivedTasks,
    absenceNotifications,
    personData,
    allTasks,
    showPayments,
  } = p;
  const domIds = domIdsUnique(`sc-${keyId}-`);
  const [ariaExpand, setAriaExpand] = useState(false);
  const NTNId = get(claim, 'NTNId', '');
  const { localizedStrings } = useContext(KenticoDataContext);
  const letters = get(personData, 'documents.letters', []);
  const currentDate = dayjs().format('YYYY-MM-DD');

  const parentCase = absenceNotifications?.absenceNotifications?.find(
    (n) => n.notificationCaseId === NTNId,
  );

  const caseTasks = allTasks?.tasks?.filter(
    (task: dm.Task) => task.notificationCaseId === NTNId,
  );

  const dueTasks: dm.Task[] = [];
  if (EETasks && doesAnySubCaseInNTNUseTaskFlag(claim)) {
    EETasks.forEach((task) => {
      if (
        isDueTask(task.taskStatusId) &&
        taskAppliesToNTN(task.notificationCaseId, NTNId) &&
        !dueTasks.includes(task)
      ) {
        dueTasks.push(task);
      }
    });
  }
  const sortedAssignedTasks = sortAssignedTasks(dueTasks);
  const areTasksDue = sortedAssignedTasks.length > 0;
  const dueTasksContainRequired = checkForRequiredTasks(dueTasks);

  const subStatusText = (trackerStage: string) => {
    if (trackerStage === 'Closed') {
      return getLocalizedOrDefault(
        localizedStrings,
        'closed_sub_status_text',
        'Closed',
      );
    } else if (trackerStage === 'Management') {
      if (
        getManagementScenario(claim, letters, caseTasks, currentDate) ===
        'Management3'
      ) {
        return getLocalizedOrDefault(
          localizedStrings,
          'management3_sub_status_text',
          'management3',
        );
      } else if (
        getManagementScenario(claim, letters, caseTasks, currentDate) ===
        'Management2'
      ) {
        return getLocalizedOrDefault(
          localizedStrings,
          'management2_sub_status_text',
          'management2',
        );
      } else {
        return getLocalizedOrDefault(
          localizedStrings,
          'management1_sub_status_text',
          'management1',
        );
      }
    } else if (trackerStage === 'Reviewing') {
      if (
        getReviewingScenario(claim, caseTasks, currentDate) === 'Reviewing3'
      ) {
        return getLocalizedOrDefault(
          localizedStrings,
          'reviewing3_sub_status_text',
          'reviewing3',
        );
      } else if (
        getReviewingScenario(claim, caseTasks, currentDate) === 'Reviewing2'
      ) {
        return getLocalizedOrDefault(
          localizedStrings,
          'reviewing2_sub_status_text',
          'reviewing2',
        );
      } else {
        return getLocalizedOrDefault(
          localizedStrings,
          'reviewing1_sub_status_text',
          'reviewing1',
        );
      }
    } else {
      return getLocalizedOrDefault(
        localizedStrings,
        'received_sub_status_text',
        'Received',
      );
    }
  };

  const receivedTasks: dm.Task[] = [];
  if (allReceivedTasks) {
    allReceivedTasks.forEach((task) => {
      if (
        isReceivedTask(task.taskStatusId) &&
        taskAppliesToNTN(task.notificationCaseId, NTNId) &&
        !receivedTasks.includes(task)
      ) {
        receivedTasks.push(task);
      }
    });
  }
  const sortedReceivedTasks = sortReceivedTasks(receivedTasks);

  const expandCard = () => {
    ariaExpand &&
      analyticsTrackEvent(enums.AnalyticsEvents.summaryExpand, {
        ntn: NTNId,
        leaves: claim?.leaves?.length,
        claims: claim?.claims?.length,
        accommodations: claim?.accommodations?.length,
      });
    setAriaExpand((e) => !e);
  };

  const setClaimStage = (p: any) => {
    claimStage(p);
  };
  const trackerStatus = trackerStage(parentCase, claim, currentDate, letters);

  return (
    <Card id={domIds.rootNode}>
      {setClaimStage}
      {/* Mobile View */}
      <CardHeader onClick={expandCard} className="tw-flex-col md:tw-hidden">
        <div className="tw-grid tw-grid-cols-2 tw-justify-items-stretch tw-px-2 tw-pt-4 tw-pb-1">
          <div className="tw-col-span-1 tw-text-unm-primary02-500 tw-font-sans tw-font-semibold tw-text-base">
            {NTNId}
          </div>
          <div className="tw-col-span-1 tw-justify-self-end">
            {dueTasksContainRequired && (
              <StatusFlagMod
                data-testid={`claim-${claim.NTNId}-task-awaiting-action-flag`}
                background={'#FEF3EA'}
                color={'#E1710E'}
              >
                {enums.TaskUIStatuses.awaiting_action}
              </StatusFlagMod>
            )}
          </div>
        </div>
        <div className="tw-flex tw-w-full tw-pb-1">
          <Column size={80} align={'flex-start'}>
            <div className="tw-text-[#00203B] tw-font-Sans tw-font-normal tw-text-2xl tw-px-2">
              {getCardTitle(claim)}
            </div>
          </Column>
          <Column size={12} align={'flex-end'}>
            <Arrow rotate={`${ariaExpand}`} />
          </Column>
        </div>
        <div className="tw-flex tw-px-2 tw-py-4 tw-text-[#525252] tw-font-sans tw-font-semibold tw-text-sm">
          Status: {trackerStatus}
        </div>
      </CardHeader>

      {/* Desktop View */}
      <CardHeader
        onClick={expandCard}
        className="tw-hidden md:tw-block tw-flex-col"
      >
        <div className="tw-flex-row tw-w-full tw-text-unm-primary02-500 tw-font-sans tw-font-semibold tw-font-lg tw-leading-[28px] tw-break-words tw-pt-[1.5em] tw-pl-[24px] tw-pb-6 md:tw-pb-2 ">
          {NTNId}
        </div>
        <div className="tw-flex tw-w-full tw-pl-[24px] tw-pb-6 md:tw-pb-2 tw-items-center">
          <div
            id={domIds.cardTitle}
            className="tw-flex-row tw-w-full tw-pr-8 tw-text-[#00203B] tw-font-Sans tw-font-normal tw-text-[28px] tw-leading-[1.2em] tw-break-words"
          >
            {getCardTitle(claim)}
          </div>
          <div className={'tw-pl-2'}>
            {dueTasksContainRequired && (
              <StatusFlagMod
                data-testid={`claim-${claim.NTNId}-task-awaiting-action-flag`}
                background={'#FEF3EA'}
                color={'#E1710E'}
              >
                {enums.TaskUIStatuses.awaiting_action}
              </StatusFlagMod>
            )}
          </div>
          <Column size={12} align={'flex-end'} className="tw-items-end">
            <Arrow id={domIds.dropDownArrow} rotate={`${ariaExpand}`} />
          </Column>
        </div>
        <div className="tw-hidden md:tw-flex tw-pb-4">
          <StatusTracker
            stage={trackerStage(parentCase, claim, currentDate, letters)}
          />
        </div>
      </CardHeader>
      <Reveal style={{ display: 'block' }}>
        {ariaExpand ? (
          <ExpandedSection
            {...p}
            NTNId={NTNId}
            ariaExpand={ariaExpand}
            areTasksDue={areTasksDue}
            EETasks={sortedAssignedTasks}
            ERTasks={ERTasks}
            receivedTasks={sortedReceivedTasks}
            subStatusText={subStatusText}
            currentDate={currentDate}
            letters={letters}
            showPayments={showPayments}
          />
        ) : null}
      </Reveal>
    </Card>
  );
};

export default ClaimsCard;
