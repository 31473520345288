import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { ActionButton } from '../action-button';
import { colors, fonts, rem, maxWidthQuery } from '../../../style';
import { Switch } from '@progress/kendo-react-inputs';
import { ReactComponent as arrowIcon } from '../../../images/arrow-icon.svg';
import { ReactComponent as arrowLeftRight } from '../../../images/arrow-left-right-icon.svg';
import { ReactComponent as arrowRight } from '../../../images/arrow-solid-right.svg';
import { ReactComponent as filterIcon } from '../../../images/filter-icon.svg';
import { Button } from '@progress/kendo-react-buttons';
import {
  Notification,
  NotificationGroup,
} from '@progress/kendo-react-notification';
import { Pager as KendoPager } from '@progress/kendo-react-data-tools';
import chevronRight from '../../../images/blue-arrow-right-pagination.svg';
import chevronLeft from '../../../images/blue-arrow-left.svg';
import { DropDownList as KendoDropDownList } from '@progress/kendo-react-dropdowns';
import CloseIcon from '../../../images/close-icon.svg';
import { Dialog as KendoDialog } from '@progress/kendo-react-dialogs';
import CustomButton from '../button';

/*******************************************************************************
 * non-standard colors
 ******************************************************************************/
const darkBlueColor = '#025780';
const horizontalLineColor = '#C7C8CA';

/*******************************************************************************
 * simple styled components
 ******************************************************************************/

const ActionLink = styled(Link)`
  color: ${colors.skyD2};
  font-family: ${fonts.openSans.semibold.fontFamily};
  font-weight: ${fonts.openSans.semibold.fontWeight};
  font-size: ${rem(16)};
  line-height: ${rem(24)};
  margin-top: 36px;

  :hover {
    color: ${darkBlueColor};
  }
`;

const ActionLinkButton = styled(ActionLink).attrs({
  as: 'button',
})`
  background-color: #0000;
  border: none;
  padding: 0;

  :hover {
    text-decoration: underline;
  }
`;

const ArrowIcon = styled(arrowIcon)`
  margin-bottom: 4px;
  margin-left: 8px;

  path {
    fill: currentColor;
  }
  rect {
    fill: none;
  }
`;

const ArrowLeftRightIcon = styled(arrowLeftRight)`
  color: ${colors.boulder};
  height: 14px;
  width: 14px;

  path {
    fill: currentColor;
  }
  rect {
    fill: none;
  }
`;

const ArrowRight = styled(arrowRight)`
  margin-left: 18px;
  path {
    fill: currentColor;
  }
  rect {
    fill: none;
  }
`;

const ArrowIconLeft = styled(ArrowIcon)`
  margin-left: 0;
  margin-right: 8px;
  transform: rotate(180deg);
`;

const BottomRightActionButton = styled(ActionButton)`
  bottom: 40px;
  float: right;
  position: fixed;
  right: 40px;
`;

const CenteredContentContainer = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  flex-direction: column;
`;

const CopyrightText = styled.div`
  color: ${colors.alabaster};
  font-family: ${fonts.openSans.semibold.fontFamily};
  font-size: ${rem(14)};
  font-weight: ${fonts.openSans.semibold.fontWeight};
  line-height: ${rem(19)};
`;

const ContainerFluid = styled.div.attrs({ className: 'container-fluid' })`
  padding: 0;
`;

const DashboardMenuItem = styled(Link)`
  color: ${colors.warmNeutralL3};
  font-family: ${fonts.ubuntu.medium.fontFamily};
  font-size: ${rem(18)};
  font-weight: ${fonts.ubuntu.medium.fontWeight};
  line-height: ${rem(20)};
  cursor: pointer;

  &:hover {
    text-decoration: none;
  }

  &:visited {
    color: ${colors.warmNeutralL3};
  }
`;

const DescriptionList = styled.dl.attrs({ className: 'row' })`
  margin-bottom: 0;
`;

const EditButton = styled.button`
  color: ${colors.skyD2};
  font-family: ${fonts.openSans.semibold.fontFamily};
  font-weight: ${fonts.openSans.semibold.fontWeight};
  font-size: ${rem(15)};
  line-height: ${rem(20)};
  background-color: ${colors.white};
  border: 0;
  outline: none;
`;

const EditContainer = styled.div`
  background-color: ${colors.coolNeutralD2};
`;

const ErrorText = styled.span`
  color: ${colors.errorRed};
  font-family: ${fonts.openSans.semibold.fontFamily};
  font-size: ${rem(16)};
  font-weight: ${fonts.openSans.semibold.fontWeight};
  line-height: ${rem(22)};
`;

const FeatureTitle = styled.h1`
  border-bottom: 1px solid ${horizontalLineColor};
  color: ${colors.warmNeutralL3};
  font-family: ${fonts.ubuntu.light.fontFamily};
  font-weight: ${fonts.ubuntu.light.fontWeight};
  font-size: ${rem(28)};
  line-height: ${rem(36)};
  margin-bottom: 36px;
  margin-top: 36px;
  padding-bottom: 12px;
`;

const FilterIcon = styled(filterIcon)`
  path {
    fill: currentColor;
  }
  rect {
    fill: none;
  }
`;

//White colored links for use on a Unum blue background
const FooterLink = styled(Link)`
  color: ${colors.alabaster};
  font-family: ${fonts.openSans.bold.fontFamily};
  font-size: ${rem(14)};
  font-weight: ${fonts.openSans.bold.fontWeight};
  line-height: ${rem(19)};
  cursor: pointer;

  &:hover {
    text-decoration: underline;
    color: ${colors.alabaster};
  }

  &:visited {
    color: ${colors.alabaster};
  }
`;

const FullScreenHeader = styled.div`
  color: ${colors.blue};
  font-family: ${fonts.ubuntu.light.fontFamily};
  font-weight: ${fonts.ubuntu.light.fontWeight};
  font-size: ${rem(46)};
  line-height: ${rem(56)};
  margin-top: 24px;
  text-align: center;
`;

const FullScreenSubHeader = styled.div`
  color: ${colors.warmNeutralL3};
  font-family: ${fonts.openSans.semibold.fontFamily};
  font-weight: ${fonts.openSans.semibold.fontWeight};
  font-size: ${rem(16)};
  line-height: ${rem(24)};
  margin-top: 12px;
  text-align: center;
`;

const HeaderArea = styled.div`
  display: flex;
  justify-content: space-between;
`;

const OpenSansSemiBoldLabel = styled.div`
  color: ${colors.warmNeutralL3};
  font-family: ${fonts.openSans.semibold.fontFamily};
  font-weight: ${fonts.openSans.semibold.fontWeight};
  font-size: ${rem(16)};
  line-height: ${rem(24)};
`;

const OpenSansSemiBoldLabel2 = styled.div`
  color: ${colors.boulder1};
  font-family: ${fonts.openSans.semibold.fontFamily};
  font-weight: ${fonts.openSans.semibold.fontWeight};
  font-size: ${rem(14)};
  line-height: ${rem(16)};
`;

const MaxWidthWrapper = styled.div`
  width: 100%;

  @media (min-width: 0px) and (max-width: 1036px) {
    padding: 0 3%;
  }

  @media (min-width: 1280px) {
    margin-left: auto;
    margin-right: auto;
    max-width: 1280px;
  }
`;

const NvgContainer = styled.div`
  margin-bottom: 16px;
`;

const OpenSansRegularValue = styled.div`
  color: ${colors.warmNeutralL3};
  font-family: ${fonts.openSans.regular.fontFamily};
  font-weight: ${fonts.openSans.regular.fontWeight};
  font-size: ${rem(16)};
  line-height: ${rem(22)};
`;
const OpenSansRegularSmall = styled.div`
  color: ${colors.warmNeutralL3};
  font-family: ${fonts.openSans.regular.fontFamily};
  font-weight: ${fonts.openSans.regular.fontWeight};
  font-size: ${rem(14)};
  line-height: ${rem(22)};
`;

const ReadOnlyContainer = styled.div`
  max-height: ${rem(163)};
  background-color: ${colors.white};
`;

const Row = styled.div.attrs({ className: 'row no-gutters' })``;

const ScreenContainer = styled.div`
  padding-top: 24px;
`;

const SectionSeparator = styled.hr`
  border-top: 1px solid ${horizontalLineColor};
  margin-bottom: ${(props) => props.bottomMargin || '24px'};
  margin-top: ${(props) => props.topMargin || '24px'};
`;

const VerticalSeparator = styled.div`
  border-left: 2px solid;
  border-color: ${(props) => props.color || horizontalLineColor};
  margin-bottom: 16px;
`;

const SectionTitle = styled.h3`
  color: ${colors.warmNeutralL3};
  font-family: ${fonts.openSans.medium.fontFamily};
  font-weight: ${fonts.openSans.medium.fontWeight};
  font-size: ${rem(30)};
  letter-spacing: -0.38px;
  line-height: ${rem(27)};
`;

const SectionSubTitle = styled.h4`
  color: ${colors.warmNeutralL3};
  font-family: ${fonts.openSans.regular.fontFamily};
  font-weight: ${fonts.openSans.regular.fontWeight};
  font-size: ${rem(16)};
  letter-spacing: 0px;
  line-height: ${rem(22)};
`;

const SuccessText = styled.span`
  color: ${colors.apple};
  font-family: ${fonts.openSans.semibold.fontFamily};
  font-size: ${rem(16)};
  font-weight: ${fonts.openSans.semibold.fontWeight};
  line-height: ${rem(22)};
`;

const SwitchButton = styled(Switch)`
  margin-left: auto;
  .k-switch-on {
    background-color: #037cb7 !important;
  }
  .k-switch-off {
  }
  .k-switch-off.k-state-focused
    .k-switch-on.k-state-focused
    .k-switch-on:focus
    .k-switch-off:focus {
    box-shadow: none !important;
  }
`;

const ItemRenderWrapper = styled.div`
  font-family: ${fonts.openSans.regular.fontFamily};
  font-size: ${rem(14)};
  font-weight: ${fonts.openSans.regular.fontWeight};
  padding: 25px 20px;
  width: 100%;
`;

const BannerContainer = styled.div`
  width: 100%;
  background: ${(props) => props.color};
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  display: flex;
  margin-bottom: 32px;
  padding: 16px;
  font-family: ${fonts.openSans.regular.fontFamily};
  font-size: ${rem(14)};
  font-weight: ${fonts.openSans.regular.fontWeight};
`;

const TextStyle1 = styled.p`
  color: ${colors.boulder};
  font-family: ${fonts.openSans.regular.fontFamily};
  font-size: ${rem(14)};
  font-weight: ${fonts.openSans.regular.fontWeight};
  letter-spacing: -0.17px;
  line-height: ${rem(22)};
`;

const FeedbackLink = styled(Link)`
  color: ${colors.boulder};
  font-family: ${fonts.openSans.regular.fontFamily};
  font-size: ${rem(14)};
  font-weight: ${fonts.openSans.regular.fontWeight};
  letter-spacing: -0.17px;
  line-height: ${rem(22)};
`;

const TextStyle2 = styled.p`
  color: ${colors.warmNeutralL3};
  font-family: ${fonts.openSans.semibold.fontFamily};
  font-size: ${rem(15)};
  font-weight: ${fonts.openSans.semibold.fontWeight};
`;

const backgroundColorMap = {
  success: colors.primaryBlue,
  info: colors.primaryBlue,
  error: colors.errorRed,
};

const ToastNotificationGroupStyle = styled(NotificationGroup)`
  right: 60px;
  top: 50px;
  max-width: 376px;
  @media (max-width: 557px) {
    top: 0;
    left: 14px;
  }
  position: absolute;
`;

const StyledNotification = styled(Notification)`
  color: ${colors.white};
  border: none;
  background-color: ${(props) => backgroundColorMap[props.type.style]};
  border-radius: 6px;
  font-size: 13px;
  font-family: 'Open Sans';
  font-weight: 500;
  box-shadow: 0 3px 6px #a9c2d5;
  width: 100%;

  .k-notification {
    padding: 0;
    height: 55px;
    display: flex;
    position: relative;
  }

  span.k-icon {
    font-size: 25px;
  }

  div.k-notification-content {
    margin: auto 0 auto 3px;
  }

  a.k-icon.k-i-close {
    top: -8px;
    right: -12px;
  }
`;

const Pager = styled(KendoPager)`
  background-color: ${colors.white};
  border: 0;
  justify-content: center;
  padding-top: 18px;

  .k-link.k-pager-nav {
    background: none;
    border: 0;
    position: relative;
    &:focus {
      box-shadow: none;
    }
    .k-icon.k-i-arrow-60-right {
      content: url(${chevronRight});
    }
    .k-icon.k-i-arrow-60-left {
      content: url(${chevronLeft});
    }
  }
  .k-link.k-pager-nav.k-pager-first {
    display: none;
  }
  .k-link.k-pager-nav.k-pager-last {
    display: none;
  }
  .k-pager-numbers-wrap {
    &select.k-dropdown {
      display: none;
    }

    .k-pager-numbers {
      display: flex;
      li {
        margin-bottom: 0;
      }

      li:not(:first-child) > .k-link {
        margin-left: 16px;
      }

      .k-link {
        background: none;
        border: 1px solid ${colors.primaryBlue};
        border-radius: 50%;
        color: ${colors.primaryBlue};
        font-family: ${fonts.openSans.regular.fontFamily};
        font-size: 15px;
        font-weight: ${fonts.openSans.regular.fontWeight};
        height: 24px;
        min-width: 24px;

        &:focus {
          box-shadow: none;
        }
        &.k-state-selected {
          background-color: rgba(0, 124, 183, 0.1);
          border: 0;
        }
      }
    }
  }
`;

const WelcomeTextLarge = styled.div`
  color: ${colors.warmNeutralL3};
  font-family: ${fonts.ubuntu.light.fontFamily};
  font-size: ${rem(46)};
  font-weight: ${fonts.ubuntu.light.fontWeight};
  line-height: ${rem(52)};
`;

const Container = styled.div`
  background-color: ${colors.white};
  border-radius: 5px;
  box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%);
  max-width: 792px;
`;

const TitleBarPhoneStyling = maxWidthQuery.phone`
  align-items: start; 
  border-bottom: 0;
  flex-direction: column; 
  padding-left: 20px;
`;

const TitleBar = styled.div`
  align-items: flex-end;
  border-bottom: 1px solid ${colors.geyser};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  min-height: 83px;
  padding: 13.5px;
  padding-left: 24px;
  ${TitleBarPhoneStyling};
`;

const DropDownList = styled(KendoDropDownList)`
  max-width: 345px;
  width: 100%;

  .k-dropdown-wrap {
    background-color: ${colors.white};
    border-color: ${colors.alto};
    height: 56px;
  }
  .k-input,
  option {
    align-self: center;
    color: ${colors.warmNeutralL3};
    font-family: ${fonts.openSans.regular.fontFamily};
    font-weight: ${fonts.openSans.regular.fontWeight};
  }
`;

const BodyPhoneStyling = maxWidthQuery.phone`
  padding: 7px 10px 12px;
`;

const Body = styled.div`
  padding: 40px 65px 12px 24px;
  ${BodyPhoneStyling};

  .k-listview-content {
    position: initial;
  }
`;

const ListContainer = styled.div`
  border-bottom: 1px solid ${colors.geyser};
  display: flex;
  flex-direction: row;
  padding: 20px 0 8px;
  vertical-align: middle;

  &:last-child {
    border-bottom: 0;
  }
`;

const Text = styled.div`
  color: ${(p) => p.color};
  font-family: ${fonts.openSans.regular.fontFamily};
  font-size: ${(p) => (p.fontsize ? rem(p.fontsize) : rem(15))};
  font-weight: ${fonts.openSans.regular.fontWeight};
`;

const ItalicText = styled(Text)`
  color: ${colors.warmNeutralL3};
  font-style: italic;
`;

const NoDocumentsContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: 405px;
  padding: 100px 0 200px;
  text-align: center;
`;

const TitlePhoneStyling = maxWidthQuery.phone`padding-bottom: 20px;`;

const Title = styled.div`
  color: ${colors.warmNeutralL3};
  font-family: ${fonts.ubuntu.light.fontFamily};
  font-size: ${rem(24)};
  font-weight: ${fonts.ubuntu.light.fontWeight};
  padding-bottom: 3px;
  ${TitlePhoneStyling};
`;

const HeaderLabel = styled.div`
  color: ${colors.warmNeutralL3};
  font-family: ${fonts.openSans.semibold.fontFamily};
  font-size: ${rem(15)};
  font-weight: ${fonts.openSans.semibold.fontWeight};
`;

const DocumentLink = styled.button`
  background-color: ${colors.white};
  border: 0;
  color: ${colors.skyD2};
  font-family: ${fonts.openSans.regular.fontFamily};
  font-size: ${rem(15)};
  font-weight: ${fonts.openSans.regular.fontWeight};
  padding: 0;
  vertical-align: top;
`;

const ButtonStyledLink = styled.a`
  align-items: center;
  background-color: ${colors.primaryBlue};
  border-radius: 0 0 3px 3px;
  color: ${colors.white};
  display: flex;
  font-family: ${fonts.openSans.regular.fontFamily};
  font-size: ${rem(20)};
  font-weight: ${fonts.openSans.regular.fontWeight};
  height: 61px;
  justify-content: center;
  padding: 6px 20px;
  text-decoration: none;
  width: 100%;

  :hover,
  :focus {
    color: ${colors.white};
    text-decoration: none;
  }
`;

const Dialog = styled(KendoDialog)`
  justify-content: start;
  top: 20%;

  .k-window-titlebar {
    border: 0;
    padding-bottom: 0;
  }

  .k-window-title {
    color: ${colors.warmNeutralL3};
    font-family: ${fonts.ubuntu.bold.fontFamily};
    font-size: ${rem(22)};
    font-weight: ${fonts.ubuntu.bold.fontWeight};
  }

  .k-dialog-close .k-icon.k-i-x::before {
    content: url(${CloseIcon});
  }

  .k-window-content {
    max-width: 408px;
    padding: 0;
  }

  .k-window {
    border: 0;
    margin: 10px;
  }
`;

const StatusBubble = styled.div`
  height: 8px;
  width: 8px;
  background-color: ${colors.carrotorange};
  border-radius: 50%;
  display: inline-block;
  vertical-align: text-top;
  margin-top: -3px;
`;

const LoadingCover = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: ${colors.white};
  z-index: 10004;
  opacity: 95%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const RegularDisplayText = styled.div`
  color: ${(props) => (props.color ? props.color : colors.warmNeutralL3)};
  font-family: ${fonts.openSans.regular.fontFamily};
  font-size: ${(props) => (props.size ? rem(props.size) : rem(14))};
  font-weight: ${fonts.openSans.regular.fontWeight};
  text-align: left;
`;

const BasicButton = styled(Button)`
  border-radius: 10px !important;
  border-width: 1px !important;
  font-family: ${fonts.openSans.semibold.fontFamily};
  font-size: ${rem(14)};
  font-weight: ${fonts.openSans.semibold.fontWeight};
  height: ${rem(37)};
  outline: none;
  width: ${rem(105)};
`;

const CancelButton = styled(BasicButton)`
  background-color: #fff !important;
  border-color: ${colors.primaryBlue} !important;
  color: ${colors.primaryBlue} !important;
  margin-right: 7.5px;
  padding: 6px 25px !important;
`;

const DialogActionsStyle = styled.div`
  padding: 5px 0 25px;
  text-align: center;
  width: 100%;
`;

const SubmitButton = styled(BasicButton)`
  background-color: #007cb7 !important;
  border-color: #007cb7 !important;
  color: #fff !important;
  padding: 6px 20px !important;
`;

const RegularCustom = styled(RegularDisplayText)`
  color: ${(p) => (p.bold ? colors.warmNeutralL3 : colors.boulder)};
  font-size: ${rem(15)};

  > p:last-of-type {
    margin-bottom: 0;
  }
`;

const CustomLink = styled(Link)`
  box-shadow: unset;
  color: ${colors.primaryBlue};
  font-family: ${fonts.openSans.semibold.fontFamily};
  font-size: 18px;
  font-weight: ${fonts.openSans.semibold.fontWeight};
  margin-right: 24px;
  outline: 0;
  padding: 0;
  pointer-events: all;
  text-decoration: none !important;

  &:hover {
    text-decoration: none;
  }
  &:focus {
    box-shadow: unset;
    outline: 0;
  }
`;

const CardButton = styled(CustomButton)`
  background-color: ${colors.primaryBlue};
  border: 0;
  border-radius: 30px;
  font-size: 14px;
  padding: 0.75em 2em;
  text-decoration: none;
`;

/*******************************************************************************
 * exported api definition
 ******************************************************************************/
export {
  ActionLink,
  ActionLinkButton,
  ArrowIcon,
  ArrowIconLeft,
  ArrowLeftRightIcon,
  ArrowRight,
  BottomRightActionButton,
  CenteredContentContainer,
  CopyrightText,
  ContainerFluid,
  DashboardMenuItem,
  DescriptionList,
  EditButton,
  EditContainer,
  ErrorText,
  FeatureTitle,
  FilterIcon,
  FooterLink,
  FullScreenHeader,
  FullScreenSubHeader,
  HeaderArea,
  horizontalLineColor,
  MaxWidthWrapper,
  NvgContainer,
  OpenSansRegularValue,
  OpenSansRegularSmall,
  OpenSansSemiBoldLabel,
  ReadOnlyContainer,
  Row,
  ScreenContainer,
  SectionSeparator,
  SectionTitle,
  SectionSubTitle,
  SuccessText,
  SwitchButton,
  TextStyle1,
  TextStyle2,
  FeedbackLink,
  StyledNotification,
  Pager,
  WelcomeTextLarge,
  ToastNotificationGroupStyle,
  ItemRenderWrapper,
  Container,
  TitleBar,
  DropDownList,
  Body,
  ListContainer,
  Text,
  ItalicText,
  NoDocumentsContainer,
  Title,
  HeaderLabel,
  DocumentLink,
  ButtonStyledLink,
  Dialog,
  StatusBubble,
  LoadingCover,
  RegularDisplayText,
  BasicButton,
  CancelButton,
  DialogActionsStyle,
  SubmitButton,
  RegularCustom,
  CustomLink,
  CardButton,
  OpenSansSemiBoldLabel2,
  VerticalSeparator,
  BannerContainer,
};
