import { useState } from 'react';
import { ScrollView } from '@progress/kendo-react-scrollview';
import { Button } from '@progress/kendo-react-buttons';
import { Icon } from '@progress/kendo-react-common';
import './index.css';

type Status = 'completed' | 'inProgress' | 'pending';

interface StatusItem {
  name: string;
  status: Status;
}

const getStatusClass = (status: Status): string => {
  if (status === 'completed') {
    return 'tw-bg-unm-interactive01-400 tw-border-none';
  } else if (status === 'inProgress') {
    return 'tw-bg-unm-interactive01-400 tw-text-white tw-outline tw-outline-1 tw-outline-white tw-outline-offset-[-4px]';
  } else {
    return 'tw-border-unm-secondary01-700';
  }
};

const getIconOrNumber = (status: Status, name: string) => {
  if (status === 'completed') {
    return <Icon name="check" className="tw-text-white" />;
  } else {
    return (
      <span
        className={
          status === 'inProgress'
            ? 'tw-text-white'
            : 'tw-text-unm-secondary01-700'
        }
      >
        {name.split(' ')[0]}
      </span>
    );
  }
};

const getTextClass = (status: Status): string => {
  if (status === 'completed') {
    return 'tw-text-unm-interactive01-400 tw-font-sans tw-font-semibold tw-text-[16px]';
  } else if (status === 'inProgress') {
    return 'tw-text-unm-adhoc2 tw-font-sans tw-font-semibold tw-text-[16px] tw-underline tw-decoration-unm-interactive01-400 tw-decoration-2 tw-underline-offset-4';
  } else {
    return 'tw-text-unm-secondary01-700 tw-font-sans tw-font-semibold tw-text-[16px]';
  }
};

const recieved = '1 Received';
const reviewing = '2 Reviewing';
const management = '3 Management';
const closed = '4 Closed';

const setStatusItems = (stage: string): StatusItem[] => {
  if (stage === 'Closed') {
    return [
      { name: recieved, status: 'completed' as Status },
      { name: reviewing, status: 'completed' as Status },
      { name: management, status: 'completed' as Status },
      { name: closed, status: 'completed' as Status },
    ];
  } else if (stage === 'Management') {
    return [
      { name: recieved, status: 'completed' as Status },
      { name: reviewing, status: 'completed' as Status },
      { name: management, status: 'inProgress' as Status },
      { name: closed, status: 'pending' as Status },
    ];
  } else if (stage === 'Reviewing') {
    return [
      { name: recieved, status: 'completed' as Status },
      { name: reviewing, status: 'inProgress' as Status },
      { name: management, status: 'pending' as Status },
      { name: closed, status: 'pending' as Status },
    ];
  } else if (stage === 'Received') {
    return [
      { name: recieved, status: 'inProgress' as Status },
      { name: reviewing, status: 'pending' as Status },
      { name: management, status: 'pending' as Status },
      { name: closed, status: 'pending' as Status },
    ];
  } else {
    return [
      { name: recieved, status: 'completed' as Status },
      { name: reviewing, status: 'pending' as Status },
      { name: management, status: 'pending' as Status },
      { name: closed, status: 'inProgress' as Status },
    ];
  }
};
export const StatusTracker = (stage: any) => {
  const statusItems: StatusItem[] = setStatusItems(stage.stage);
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleNext = () => {
    if (currentIndex < statusItems.length - 3) {
      setCurrentIndex(currentIndex + 1);
    }
  };

  const handlePrev = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };

  return (
    <div id="status-tracker" className="tw-w-full">
      {/* Mobile view */}
      <div className="tw-flex tw-justify-between tw-items-center tw-py-2 md:tw-hidden">
        <Button
          onClick={handlePrev}
          disabled={currentIndex === 0}
          className="tw-p-0"
          style={{ border: 'none', background: 'none' }}
        >
          <Icon name="chevron-left" style={{ fontSize: '30px' }} />
        </Button>
        <ScrollView
          arrows={true}
          activeView={currentIndex}
          className="tw-w-[500px] tw-h-[27px] tw-flex tw-overflow-hidden tw-border-none"
          pageable={false}
        >
          <div className="tw-flex tw-justify-between tw-px-0">
            {statusItems.slice(currentIndex, currentIndex + 3).map((item) => (
              <div
                key={item.name}
                className="tw-border-none tw-flex tw-items-center"
              >
                <div
                  className={`tw-flex tw-items-center tw-justify-center 
                            tw-font-semibold tw-text-sm
                            tw-w-6 tw-h-6 tw-rounded-full tw-border tw-border-solid tw-mr-1.5
                            ${getStatusClass(item.status)}`}
                >
                  {getIconOrNumber(item.status, item.name)}
                </div>
                <span
                  className={`shortened-text tw-self-center tw-text-sm ${getTextClass(
                    item.status,
                  )}`}
                >
                  {item.name.split(' ').slice(1).join(' ')}
                </span>
              </div>
            ))}
          </div>
        </ScrollView>
        <Button
          onClick={handleNext}
          disabled={currentIndex >= statusItems.length - 3}
          className="tw-p-0 tw-border-none"
          style={{ border: 'none', background: 'none' }}
        >
          <Icon name="chevron-right" style={{ fontSize: '30px' }} />
        </Button>
      </div>

      {/* Desktop view */}
      <div className="tw-hidden md:tw-block tw-h-full tw-px-2 tw-py-4 tw-bg-white">
        <div className="tw-flex tw-justify-around">
          {statusItems.map((item, index) => (
            <div
              key={item.name}
              className="tw-border-none tw-flex tw-items-center tw-justify-start"
            >
              <div
                className={`tw-flex tw-items-center tw-justify-center 
                            tw-font-semibold tw-text-sm
                            tw-w-8 tw-h-8 tw-rounded-full tw-border tw-border-solid tw-mr-1.5
                            ${getStatusClass(item.status)}`}
              >
                {getIconOrNumber(item.status, item.name)}
              </div>
              <span
                className={`tw-flex-col tw-self-center tw-text-sm ${getTextClass(
                  item.status,
                )}`}
              >
                {item.name.split(' ').slice(1).join(' ')}
              </span>
              {index < statusItems.length - 1 && (
                <Icon
                  name="chevron-right"
                  style={{ fontSize: '28px' }}
                  className="tw-self-center tw-text-unm-interactive01-800"
                />
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
