import {
  getClient,
  employerContentClient,
  models,
  getTasksClient,
} from '../cms-client';
import envConfig from '../env-config';
import * as storageManager from '../app-cache/cache-manager';
import { log } from '../log';
import {
  enums,
  getEREnvironmentName,
  formatSpecialBenefits,
  formatTasks,
  formatCustomTask,
  formatPostIntakeContent,
  formatIntakeList,
  get,
} from '..';
import {
  ContentItem,
  DeliveryClient,
  ElementModels,
  ItemResponses,
  MultipleItemQuery,
} from '@kentico/kontent-delivery';
import { IKenticoData } from '../../contexts/kentico-data-context';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';

dayjs.extend(isBetween);

/*******************************************************************************
 * remote-config-manager.js is a collection of functions to assist with
 * fetching remote configuration and making it available to the app
 ******************************************************************************/

const envCodename = envConfig.upEnvironment
  .toLowerCase()
  .replace('.', '_') as string;
// const log = new Logger\('remote-config-manager');

type Fetching = {
  [key in enums.CacheKeyType]?: boolean;
};

const fetching: Fetching = {};

/** Domain Models for Cache Storage */
export interface CallCenterDomainModel {
  allDay: boolean;
  start: Date | null;
  end: Date | null;
  bannerMessage: string;
}

export interface IFeatureFlagMap {
  feedback: boolean;
  show_tasks: boolean;
  absence_history: boolean;
  [name: string]: boolean;
}

export interface IDynamicContentItem {
  [name: string]: string;
}

export interface IDynamicContent {
  [name: string]: IDynamicContentItem;
}

export interface ILocalizedString {
  scope: string;
  translatedText: string;
}

export interface ILocalizedStrings {
  [name: string]: ILocalizedString;
}

export interface ISplashConfig {
  showSplash: boolean;
  content?: string;
  nextStartDateTime?: Date;
}

export type EmployerContentItemType =
  | string
  | ISpecialBenefit[]
  | ElementModels.IElement<any>[]
  | IEmployerCustomTask[]
  | INextStep[];

export interface IEmployerContent {
  [name: string]: EmployerContentItemType;
}

export interface ISpecialBenefit {
  content?: string;
  featureImage?: ElementModels.AssetModel;
  thumbnail?: ElementModels.AssetModel;
  headline?: string;
  intakes?: Array<string>;
  summary?: string;
}
export interface ITask {
  type?: string;
  name?: string;
  description?: string;
  action?: string;
  customTask?: boolean;
  form?: string;
}

export interface IEmployerCustomTask {
  type: string;
  name: string;
  description: string;
  action: string;
  form: string;
}
export interface INextStep {
  title: any;
  description: any;
  thumbnail: any;
  optionsTemplate: any;
  link: any;
  type: any;
  order: any;
}
export interface IEmployerNextStep {
  title: any;
  description: any;
  thumbnail: any;
  optionsTemplate: any;
  link: any;
  type: any;
  intakes: any;
}

export const DEFAULT_FEATURE_FLAGS: IFeatureFlagMap = {
  feedback: false,
  show_tasks: false,
  absence_history: false,
};

export interface ITasksConfig {
  taskTitle: string;
  taskCodename: string;
  category: ElementModels.MultipleChoiceOption;
  description: string;
  shortDescription: string;
  frequentlyAskedQuestions: {
    question: string;
    response: string;
  }[];
  tags: ElementModels.TaxonomyTerm[];
  formCodename: string;
}

// typescript magic to require at least one of the provided keys
type AtLeastOne<T, U = { [K in keyof T]: Pick<T, K> }> = Partial<T> &
  U[keyof U];

type GetKontentRequiredParameters = {
  cacheKey: enums.CacheKeyType;
};

type GetKontentEitherRequiredParameters = {
  kontentQuery: MultipleItemQuery<ContentItem>;
  kontentType: string;
};

type GetKontentOptionalParameters = {
  kontentContainsFilters?: { element: string; value: string[] }[];
  kontentEqualsFilter?: { element: string; value: string };
  kontentGreaterThanFilter?: { element: string; value: string };
  kontentLimitParameter?: number;
  kontentElementsParameters?: string[];
  kontentDepth?: number;
  cacheSuffix?: string;
  cacheExpirationMinutes?: number;
  cmsClient?: DeliveryClient;
};

// for use in the main getKontent() function
type GetKontentParameters = GetKontentRequiredParameters &
  AtLeastOne<GetKontentEitherRequiredParameters> &
  GetKontentOptionalParameters;

// for use in the buildKontentQuery() function
type BuildKontentQueryParameters = GetKontentRequiredParameters &
  Pick<GetKontentEitherRequiredParameters, 'kontentType'> &
  GetKontentOptionalParameters;

export const buildKontentQuery = async <KontentType extends ContentItem>({
  kontentType,
  kontentContainsFilters = [],
  kontentEqualsFilter,
  kontentGreaterThanFilter,
  kontentLimitParameter,
  kontentElementsParameters,
  kontentDepth,
  cmsClient,
}: BuildKontentQueryParameters) => {
  const client = cmsClient ?? (await getClient());
  let kontentQuery = client.items<KontentType>().type(kontentType);

  for (const kontentContainsFilter of kontentContainsFilters) {
    kontentQuery = kontentQuery.containsFilter(
      kontentContainsFilter.element,
      kontentContainsFilter.value,
    );
  }

  if (kontentEqualsFilter) {
    kontentQuery = kontentQuery.equalsFilter(
      kontentEqualsFilter.element,
      kontentEqualsFilter.value,
    );
  }

  if (kontentGreaterThanFilter) {
    kontentQuery = kontentQuery.greaterThanFilter(
      kontentGreaterThanFilter.element,
      kontentGreaterThanFilter.value,
    );
  }

  if (kontentLimitParameter) {
    kontentQuery = kontentQuery.limitParameter(kontentLimitParameter);
  }

  if (kontentElementsParameters) {
    kontentQuery = kontentQuery.elementsParameter(kontentElementsParameters);
  }

  if (kontentDepth) {
    kontentQuery = kontentQuery.depthParameter(kontentDepth);
  }

  return kontentQuery;
};

export const getKontent = async <KontentType extends ContentItem>(
  p: GetKontentParameters,
) => {
  const { kontentType, cacheKey, cacheSuffix } = p;

  log.debug(`getKontent(${kontentType})...`);

  while (fetching[cacheKey]) {
    await new Promise((resolve) => setTimeout(resolve, 100));
  }

  const calculatedCacheKey = cacheSuffix
    ? `${cacheKey}_${cacheSuffix}`
    : cacheKey;

  let cachedItem =
    storageManager.getItem<ItemResponses.ListContentItemsResponse<KontentType>>(
      calculatedCacheKey,
    );

  if (!cachedItem) {
    // set this to avoid multiple network calls for the same cache key
    fetching[cacheKey] = true;

    // use the provided query, or build one from parameters
    const kontentQueryInstance =
      (p.kontentQuery as MultipleItemQuery<KontentType>) ||
      (await buildKontentQuery<KontentType>(p as BuildKontentQueryParameters));

    // network call
    const result = await kontentQueryInstance.toPromise();

    // store result in cache
    try {
      storageManager.setItem(calculatedCacheKey, result);
    } catch (error) {
      // don't want to block the rest of the app if cache fails
      // might have hit the browser limit for session storage
      log.error(error);
    }

    cachedItem = result;
  }

  fetching[cacheKey] = false;
  return cachedItem;
};

/**
 * Retrieves asset config from Kentico, maps it, and caches it
 * @returns {object} asset configuration key/value pairs
 */
const getAllAssetReferences = async () => {
  log.debug('getAllAssetReferences...');

  const assetRefs: IKenticoData['assets'] = {};

  const result = await getKontent<models.AssetContainer>({
    kontentType: 'asset_container',
    cacheKey: 'asset_references',
    kontentEqualsFilter: {
      element: 'system.codename',
      value: enums.CodeNames.allAssets,
    },
  });

  const firstItem = result?.firstItem;

  if (!firstItem) {
    throw Error('Unable to get assets');
  }

  for (const asset of firstItem.assets.value) {
    assetRefs[asset.name] = asset;
  }

  return assetRefs;
};

/**
 * Retrieves call center closure config from Kentico
 * @param {Date} date to filter in greaterThanFilter()
 * @returns {object} form configuration key/value pairs
 */
const getCallCenterClosureConfig = async (endDate: Date) => {
  log.debug('getCallCenterClosureConfig...');

  const r = await getKontent<models.CallCenterClosure>({
    kontentType: 'call_center_closure',
    cacheKey: 'callCenterClosures',
    kontentGreaterThanFilter: {
      element: 'elements.end',
      value: endDate.toISOString(),
    },
  });

  // build domain model to store in cache
  return r.items.map((i) => ({
    allDay: i.flags.value.some((f) => f.codename === enums.FormFlags.allDay),
    start: i.start.value,
    end: i.end.value,
    bannerMessage: i.portalUnumComBannerMessage?.value ?? '',
  })) as CallCenterDomainModel[];
};

/**
 * Retrieves Start A Claim Page Content
 * @returns {object} start a claim page content
 */
const getStartAClaimContent = async () => {
  log.debug('getStartAClaimContent...');

  return (await getClient())
    .item<models.IntakeSelection>('intake_start')
    .depthParameter(3)
    .toPromise();
};

/**
 * Retrieves form config from Kentico
 * @returns {object} form configuration key/value pairs
 */
const getFormConfig = async (formName: string, depthParameter = 3) => {
  return getKontent<models.Form>({
    kontentType: 'form',
    cacheKey: 'form_config',
    cacheSuffix: formName,
    kontentEqualsFilter: {
      element: 'elements.path_match',
      value: formName,
    },
    kontentLimitParameter: 1,
    kontentDepth: depthParameter,
  });
};

const getExitPromptFormList = async () => {
  const result = await getKontent({
    kontentType: 'intakes_list',
    cacheKey: 'intakes_list',
    kontentDepth: 3,
  });

  for (const item of result.items) {
    if (item.intakes) {
      return formatIntakeList(item.intakes.value);
    }
  }

  return [];
};

/**
 * Retrieves simple remote config from Kentico, maps it, and caches it
 * @returns {object} remote configuration key/value pairs
 */
const getSimpleRemoteConfig = async () => {
  const remoteConfig: { [name: string]: string } = {};
  const r = await getKontent<models.SimpleRemoteConfigurationItem>({
    kontentType: 'simple_remote_configuration_item',
    cacheKey: 'remote_config',
    kontentElementsParameters: [envCodename],
  });

  for (const item of r.items) {
    const codename = item.system.codename;
    remoteConfig[codename] = item[envCodename].value;
  }

  return remoteConfig;
};

/**
 * Retrieves feature flags from Kentico, maps it, and caches it
 * @returns {object} feature flag key/value pairs
 */
const getFeatureFlags = async () => {
  const r = await getKontent<models.FeatureFlags>({
    kontentType: 'feature_flags',
    cacheKey: 'feature_flags',
    kontentElementsParameters: [envCodename],
  });

  const ff = DEFAULT_FEATURE_FLAGS;

  Object.keys(ff).forEach((k) => {
    ff[k] = r.items.some((i) =>
      i[envCodename].value.some(
        (f: ElementModels.MultipleChoiceOption) => f.codename === k,
      ),
    );
  });

  return ff;
};

/**
 * Retrieves dynamic content blobs from Kentico, maps it, and caches it
 * @returns {object} dynamic content blob
 */
const getDynamicContent = async (path: string) =>
  getKontent<models.ContentBlob>({
    kontentType: 'content_blob',
    cacheKey: 'content_blob',
    kontentEqualsFilter: {
      element: 'elements.path',
      value: path,
    },
    cacheSuffix: path.replace(new RegExp(/\//g), '_'),
  });

/**
 * Retrieves Localized String content items from Kentico and maps it
 * @returns {object} Localized String key/value pairs
 */
const getLocalizedStrings = async () => {
  log.debug('getLocalizedStrings...');

  const localizedStrings: ILocalizedStrings = {};
  const result = await getKontent<models.LocalizedString>({
    kontentType: 'localized_string',
    cacheKey: 'localized_string',
  });

  for (const item of result.items) {
    const codename = item.system.codename;
    localizedStrings[codename] = {
      scope: item?.scope?.value,
      translatedText: item?.translatedText?.value,
    };
  }

  return localizedStrings;
};

/**
 * Retrieves Dynamic Content type items from Kentico and maps it
 * @returns {object} Dynamic Content key/value pairs
 */
const getMarkdownContent = async () => {
  log.debug('getMarkdownContent...');

  const dynamicContent: IDynamicContent = {};
  const result = await getKontent<models.DynamicContent>({
    kontentType: 'dynamic_content',
    cacheKey: 'dynamic_content',
  });

  for (const item of result.items) {
    const codename = item.system.codename;
    dynamicContent[codename] = {};
    Object.keys(item).forEach((key) => {
      dynamicContent[codename][key] = item[key].value;
    });
  }

  return dynamicContent;
};

/**
 * Retrieves the live chat pre form items
 * @returns {object}
 */
const getLiveChatForm = async () => {
  log.debug('getLiveChatForm...');

  const result = await getKontent<models.DynamicContent>({
    kontentType: 'live_chat_form',
    cacheKey: 'live_chat_form',
  });

  return result.items;
};

/**
 * Gets splash configuration from Kentico, maps it, and returns it
 * @returns {ISplashConfig}
 */
const getSplashConfig = async (includeFuture = false) => {
  log.debug('getSplashConfig...');
  const splashConfig: ISplashConfig = { showSplash: false };
  const now = dayjs().startOf('minute').toISOString();

  const querySplashes = (await getClient())
    .items<models.SplashConfig>()
    .type('splash_config')
    .containsFilter('elements.environments', [envCodename])
    .containsFilter('elements.recurring_splash', ['no'])
    .notEqualsFilter('system.codename', 'odyssei_auto_splash');

  const queryRecurring = (await getClient())
    .items<models.SplashConfig>()
    .type('splash_config')
    .containsFilter('elements.environments', [envCodename])
    .containsFilter('elements.recurring_splash', ['yes']);

  const querySplashesRefined = includeFuture
    ? querySplashes.greaterThanFilter('elements.end_time', now)
    : querySplashes
        .lessThanFilter('elements.start_time', now)
        .greaterThanFilter('elements.end_time', now);

  const splashes = await getKontent({
    kontentQuery: querySplashesRefined,
    cacheKey: 'splash_config',
  });
  const recurringSplashes = await getKontent({
    kontentQuery: queryRecurring,
    cacheKey: 'splash_config_recurring',
  });
  let recurringSplash: ISplashConfig = { showSplash: false };

  if (recurringSplashes.items.length) {
    recurringSplash = mapRecurringSplash(recurringSplashes);
  }

  if (splashes.items.length) {
    splashConfig.content = splashes.items[0].content.value;
    splashConfig.nextStartDateTime =
      splashes.items[0].startTime.value || new Date();
    splashConfig.showSplash = true;
  }

  if (recurringSplash.showSplash && !splashConfig.showSplash) {
    return recurringSplash;
  }

  return splashConfig;
};

//Assumes only a single recurring splash in action
const mapRecurringSplash = (recurringSplashes: any): ISplashConfig => {
  const today = dayjs();
  const startDate = dayjs(recurringSplashes.items[0].startTime.value);
  const endDate = dayjs(recurringSplashes.items[0].endTime.value);

  //Recurring splash date must be set in the past
  const startTime = startDate.add(today.diff(startDate, 'day'), 'days');
  const endTime = endDate.add(today.diff(endDate, 'day'), 'days');

  return {
    content: recurringSplashes.items[0].content.value,
    showSplash: today.isBetween(startTime, endTime),
  };
};

/**
 * Retrieves Employer Content items from Kentico and maps it
 * @returns {object} Employer Content key/value pairs
 */
const getEmployerContent = async (erId: string) => {
  log.debug('getEmployerContent...');
  const filterQueryLS = `elements.${getEREnvironmentName(
    envConfig.upEnvironment,
  )}`;

  const result = await getKontent<models.Employer>({
    kontentQuery: employerContentClient()
      .items<models.Employer>()
      .type('employer')
      .equalsFilter(filterQueryLS, erId)
      .depthParameter(3),
    cacheKey: 'employer_content',
  });

  if (!result.firstItem) {
    return {};
  }

  const item: models.Employer = result.firstItem;
  const employerContent: IEmployerContent = {};

  //Loop through properties and format
  Object.keys(item).forEach((key: string) => {
    //handle text and unknown values
    let rootValue: EmployerContentItemType = item[key].value || '';

    //handle special benefits
    if (Array.isArray(item[key].value)) {
      rootValue = formatSpecialBenefits(item[key].value);
    }

    if (item[key].name === 'Custom Tasks') {
      rootValue = formatCustomTask(item[key].value);
    }

    if (item[key].name === 'Post-intake Content') {
      rootValue = formatPostIntakeContent(item[key].value);
    }

    //handle multiple choice
    if (item[key].type === enums.ERContentTypes.multipleChoice) {
      rootValue = item[key]?.value[0]?.name || '';
    }

    employerContent[key] = rootValue;
  });

  return employerContent;
};

/**
 * Retrieves tasks from Kentico, maps it, and caches it
 * @returns {object} Task key/value pairs
 */
const getTasksMap = async () => {
  log.debug('getTasksMap...');
  const tasksMap = [];

  const result = await getKontent<models.Task>({
    kontentType: 'task',
    cacheKey: 'tasks-config',
  });

  for (const item of result.items) {
    if (!item) {
      break;
    } else {
      tasksMap.push(formatTasks(item));
    }
  }

  return tasksMap;
};

const getByBusinessRole = (items: models.Descriptionbybusinessrole[]) =>
  items.find((item) =>
    item.businessrole.value.find(
      (role) => role.businessroleid.value === '300000',
    ),
  );

const getMessageByBusinessRole = (item: models.Descriptionbybusinessrole[]) => {
  const matchingItem = getByBusinessRole(item);
  return get(matchingItem, 'message.value', '');
};

const getFAQsByBusinessRole = (item: models.Faq[]) => {
  const filtered = item.filter((faq) =>
    faq.businessrole.value.find(
      (role) => role.businessroleid.value === '300000',
    ),
  );

  return filtered.map((faq) => ({
    question: get(faq, 'question.value', ''),
    response: get(faq, 'response.value', ''),
  }));
};

/**
 * Retrieves tasks from Kentico, maps it, and caches it
 * @returns {object} Task key/value pairs
 */
const getTasksConfig: () => Promise<ITasksConfig[]> = async () => {
  log.debug('getTasksConfig...');
  const tasksConfig: ITasksConfig[] = [];

  const result = await getKontent<models.Task>({
    kontentQuery: (await getTasksClient()).items<models.Task>().type('task'),
    cacheKey: 'tasks-config',
  });

  for (const item of result.items) {
    const formattedTask = {
      taskTitle: getMessageByBusinessRole(item.taskTitle.value),
      taskCodename: item.system.codename,
      category: item?.category?.value?.[0],
      description: getMessageByBusinessRole(item.description.value),
      shortDescription: getMessageByBusinessRole(item.shortDescription.value),
      frequentlyAskedQuestions: getFAQsByBusinessRole(
        item.frequentlyAskedQuestions.value,
      ),
      tags: get(item, 'tags.value', []),
      formCodename: get(item, 'form.itemCodenames.0', ''),
    };
    tasksConfig.push(formattedTask);
  }

  return tasksConfig;
};

/**
 * Retrieves task status help text from Kentico, maps it, and caches it
 * @returns {object} Task key/value pairs
 */
const getTaskStatusConfig = async () => {
  log.debug('getTaskStatusConfig...');
  const statusConfig: {
    description: any;
    taskStatusId: any;
  }[] = [];

  const result = await getKontent<models.StatusHelpText>({
    kontentQuery: (await getTasksClient())
      .items<models.StatusHelpText>()
      .type('status_help_text')
      .depthParameter(3),
    cacheKey: 'task-status-help',
  });

  for (const item of result.items) {
    const statusHelp = {
      description: getMessageByBusinessRole(item.helpText.value),
      taskStatusId: item.taskStatusId.value,
    };
    statusConfig.push(statusHelp);
  }

  return statusConfig;
};

/**
 * Retrieves claim status help text from Kentico, maps it, and caches it
 * @returns {object} Task key/value pairs
 */
const getClaimStatusConfig = async () => {
  log.debug('getClaimStatusConfig...');
  const claimStatusConfig: {
    message: any;
    status: any;
  }[] = [];

  const result = await getKontent<models.StatusHelpIcon>({
    kontentQuery: (await getClient())
      .items<models.StatusHelpIcon>()
      .type('status_help_icon'),
    cacheKey: 'status-help-icon',
  });

  for (const item of result.items) {
    const statusIcon = {
      message: item.message.value,
      status: item.status.value,
    };
    claimStatusConfig.push(statusIcon);
  }
  log.debug('status result - ', claimStatusConfig);
  return claimStatusConfig;
};

export const getEmployerContentSafely = async (erId?: string) => {
  if (erId) {
    try {
      return await getEmployerContent(erId);
    } catch (error) {
      log.error(error);
    }
  }

  return {};
};

/*******************************************************************************
 * exported api definition
 ******************************************************************************/
export {
  getCallCenterClosureConfig,
  getAllAssetReferences,
  getFormConfig,
  getSimpleRemoteConfig,
  getSplashConfig,
  getFeatureFlags,
  getDynamicContent,
  getLocalizedStrings,
  getStartAClaimContent,
  getMarkdownContent,
  getEmployerContent,
  getTasksMap,
  getLiveChatForm,
  getExitPromptFormList,
  getTasksConfig,
  getTaskStatusConfig,
  getClaimStatusConfig,
};
