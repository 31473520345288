import React from 'react';
import styled from 'styled-components';
import { fonts } from '../../../style';
import TaskList from '../task-list';
import { TaskType } from '..';
import { isDueTask } from '../utils';

interface TaskListWrapperProps {
  keyId?: string;
  taskList: TaskType[];
}

const ButtonSection = styled.div`
  margin-top: 16px;
  border-bottom: 1px solid #dddddd;

  @media (max-width: 576px) {
    margin-top: 0;
  }
`;

const TabStyledButton = styled.button<{ hasTasks?: boolean }>`
  text-decoration: none;
  background-color: transparent;
  border: 0;
  padding: 0 16px;
  margin: 0 0 -3px;
  position: relative;

  &:first-child {
    padding-left: 0;
  }

  &::after {
    border-radius: 50%;
    background-color: #f29423;
    height: 8px;
    width: 8px;
    position: absolute;
    right: 10px;
    top: 3px;
    content: '';
    position: absolute;
    display: ${(p) => (p.hasTasks ? 'block' : 'none')};
  }

  @media (max-width: 576px) {
    padding: 0 8px;
    &::after {
      right: 4px;
    }
  }
`;

const StyledText = styled.div`
  color: #535353;
  font-family: ${fonts.openSans.semibold.fontFamily};
  font-size: 16px;
  font-weight: ${fonts.openSans.semibold.fontWeight};
  line-height: 40px;
  margin: 0;

  &.selected {
    border-bottom: 5px solid #037cb7;
  }

  @media (max-width: 576px) {
    font-size: 14px;
  }
`;

const TabHelpText = styled.div`
  color: #535353;
  font-family: ${fonts.openSans.regular.fontFamily};
  font-size: 16px;
  font-weight: ${fonts.openSans.regular.fontWeight};
  margin: 0;
  padding: 2em 0em 0em 0em;
`;

const TaskListWrapper: (p: TaskListWrapperProps) => React.ReactElement = (
  p,
) => {
  const { taskList } = p;
  const [filter, setFilter] = React.useState<string>('all');
  const [filteredTasks, setFilteredTasks] = React.useState<TaskType[]>([]);

  const filterTasks = (type: string) => {
    if (type === 'all') {
      setFilteredTasks(taskList);
    } else if (type === 'uit') {
      const filtered = taskList.filter((t) => t.taskSource === 2);
      setFilteredTasks(filtered);
    } else {
      const filtered = taskList.filter((t) => t.category.codename === type);
      setFilteredTasks(filtered);
    }
  };

  const handleOnClick = (type: string) => {
    setFilter(type);
    filterTasks(type);
  };

  React.useEffect(() => {
    setFilter('all');
    filterTasks('all');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taskList]);

  const hasRequiredTasks = taskList.some(
    (task) =>
      task.category.codename === 'required' && isDueTask(task.taskStatusId),
  );

  const hasRecommendedTasks = taskList.some(
    (task) =>
      task.category.codename === 'recommended' && isDueTask(task.taskStatusId),
  );

  const hasUIT = taskList.some((task) => task.taskSource === 2);

  return (
    <div>
      <ButtonSection>
        <TabStyledButton onClick={() => handleOnClick('all')}>
          <StyledText className={filter === 'all' ? 'selected' : ''}>
            All
          </StyledText>
        </TabStyledButton>
        <TabStyledButton
          hasTasks={hasRequiredTasks}
          onClick={() => handleOnClick('required')}
        >
          <StyledText className={filter === 'required' ? 'selected' : ''}>
            Required
          </StyledText>
        </TabStyledButton>
        <TabStyledButton
          hasTasks={hasRecommendedTasks}
          onClick={() => handleOnClick('recommended')}
        >
          <StyledText className={filter === 'recommended' ? 'selected' : ''}>
            Recommended
          </StyledText>
        </TabStyledButton>
        <TabStyledButton hasTasks={hasUIT} onClick={() => handleOnClick('uit')}>
          <StyledText className={filter === 'uit' ? 'selected' : ''}>
            Updates You Provided
          </StyledText>
        </TabStyledButton>
      </ButtonSection>
      {filter === 'required' ? (
        <TabHelpText>
          Required tasks prompt you to perform actions and/or provide
          information by a set date, that help us process your leave(s) or
          claim(s).
        </TabHelpText>
      ) : filter === 'recommended' ? (
        <TabHelpText>
          Recommended tasks prompt you to engage with content that could help
          improve your leave or claim experience, at your own pace.
        </TabHelpText>
      ) : null}
      <TaskList taskList={filteredTasks} categoryFilter={filter} />
    </div>
  );
};

export default TaskListWrapper;
