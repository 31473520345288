import { useState, useEffect } from 'react';
import { PaymentPreferencesModel } from '../../../data-models';
import { Link } from 'react-router-dom';
import { get, prefixObjectValues, getHtmlFromMarkdown } from '../../../utils';
import styled from 'styled-components';
import { Form } from '@progress/kendo-react-form';
import { RadioButton } from '@progress/kendo-react-inputs';
import { ClipLoader } from 'react-spinners';
import { ToastTypes, AppPaths, ButtonTypes } from '../../../utils/enums';
import {
  getPaymentPreferences,
  postPaymentPreferences,
} from '../../../utils/web-apis-client';
import {
  ReadOnlyContainer,
  OpenSansSemiBoldLabelMod,
  SubmitButton,
  EditButtonMod,
  CancelButton,
  SectionTitleMod,
  EditImage,
  StyledSpinner,
  toastMessages,
  ErrorText,
  SectionSubTitleMod,
  Container,
  PrefSeparator,
} from '../index.sc';
import {
  SectionSeparator,
  StyledNotification,
  ToastNotificationGroupStyle,
} from '../../base/simple-styled-components/index';
import {
  EftPreferenceOptions,
  templateIds as EftIds,
} from './eft-preference-options';
import { CheckPreferenceOptions } from './check-preference-options';
import {
  ZellePreferenceOptions,
  formatPhoneForSubmission,
  formatPhoneForDisplay,
} from './zelle-preference-options';
import { colors, fonts } from '../../../style';
import ZelleIcon from '../../../images/zelle-logo.svg';
import EditIcon from '../../../images/edit-icon.svg';

const paymentMethods = {
  Zelle: {
    label: 'Zelle',
    id: 'zelle',
    context: `<a href="https://www.zellepay.com/" target="_blank">Zelle</a> \
       is a secure digital payment solution, where we send you funds approximately within a day, without needing your bank details.`,
  },
  EFT: {
    label: 'Direct Deposit',
    id: 'eft',
    context:
      'An electronic payment method, where we transfer funds approximately within 3 days, directly to your bank account.',
  },
  Check: {
    label: 'Mailed Paper Check',
    id: 'check',
    context:
      'A traditional payment method, where we send you a physical check in the mail, approximately within 7 days.',
  },
};

const AccountNumberLabel = styled.p`
  color: #031e47;
  font-family: ${fonts.openSans.regular.fontFamily};
  font-size: 16px;
  font-weight: ${fonts.openSans.regular.fontWeight};
  margin: 0;
`;

const CheckMethodLabel = styled(OpenSansSemiBoldLabelMod)`
  margin: 0;
  width: fit-content;
`;

const ControlContainer = styled.div`
  align-items: center;
  display: flex;
  padding-bottom: 10px;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 26px;

  > :first-child {
    margin-right: 8px;
  }

  > :last-child {
    margin-left: 8px;
  }
`;

const MutedLabel = styled.p`
  color: #657676;
  font-family: ${fonts.openSans.semibold.fontFamily};
  font-size: 13px;
  font-weight: ${fonts.openSans.semibold.fontWeight};
  margin: 0;
`;

const ContextLabel = styled.p`
  color: #767676;
  font-family: ${fonts.openSans.regular.fontFamily};
  font-size: 1rem;
  line-height: 1.375rem;
  font-weight: ${fonts.openSans.regular.fontWeight};
  margin: 0;
  margin-top: 5px;
  margin-left: 52px;
  margin-right: 20px;
  margin-bottom: 12px;
`;

const RadioGroupMod = styled.div`
  color: #525252;
  font-family: ${fonts.openSans.semibold.fontFamily};
  font-size: 15px;
  font-weight: ${fonts.openSans.semibold.fontWeight};
  line-height: 1.25;

  .k-radio {
    height: 24px;
    margin-left: 16px;
    width: 24px;
  }

  .k-radio::before {
    height: 16px;
    width: 16px;
  }

  .k-radio-label {
    margin-left: 11px;
  }
`;

const ZelleMethodLabel = styled(MutedLabel)`
  img {
    margin-right: 12px;
    vertical-align: text-top;
  }
`;

export const templateIds = {
  rootNode: 'payment-preferences',
  readOnlyTitle: 'payment-preferences-readonly-title',
  readOnlyEditButton: 'payment-preferences-readonly-edit-button',
  cancelButton: 'payment-preferences-cancel-button',
  submitButton: 'payment-preferences-submit-button',
  checkRadioButton: 'payment-preferences-check-radio-button',
  eftRadioButton: 'payment-preferences-eft-radio-button',
  zelleRadioButton: 'payment-preferences-zelle-radio-button',
};

export const domIdsUnique = (prefix?: string) =>
  prefixObjectValues(prefix, templateIds);

const getInitialFormValues = (prefs: any) => {
  return {
    paymentMethod: get(prefs, 'paymentMethod', ''),
    [EftIds.accountNumberInput]: get(prefs, 'accountDetails.accountNo', ''),
    [EftIds.bankNameInput]: get(prefs, 'accountDetails.accountName', ''),
    [EftIds.routingNumberInput]: get(prefs, 'accountDetails.routingNumber', ''),
  };
};

const renderPaymentMethodRadioButton = (
  paymentMethod: any,
  processing: boolean,
  selectedPaymentMethod: any,
  handlePaymentMethodChange: Function,
  id: string,
  formRenderProps: any,
) => (
  <div className="pb-3">
    <RadioButton
      id={id}
      disabled={processing}
      name={'payment-preferences'}
      value={paymentMethod.id}
      checked={selectedPaymentMethod === paymentMethod.id}
      label={paymentMethod.label}
      onChange={(e) => handlePaymentMethodChange(e, formRenderProps)}
    />
    <ContextLabel
      dangerouslySetInnerHTML={getHtmlFromMarkdown(paymentMethod.context)}
    ></ContextLabel>
  </div>
);

const RenderCheckRadioAndOptions = (
  processing: boolean,
  selectedPaymentMethod: any,
  handlePaymentMethodChange: Function,
  automationIds: any,
  formRenderProps: any,
  address: any,
) => {
  return (
    <>
      <br />
      {renderPaymentMethodRadioButton(
        paymentMethods.Check,
        processing,
        selectedPaymentMethod,
        handlePaymentMethodChange,
        automationIds.checkRadioButton,
        formRenderProps,
      )}
      {selectedPaymentMethod === paymentMethods.Check.id && (
        <CheckPreferenceOptions address={address} />
      )}
    </>
  );
};

const RenderEFTRadioAndOptions = (
  processing: boolean,
  selectedPaymentMethod: any,
  handlePaymentMethodChange: Function,
  automationIds: any,
  formRenderProps: any,
) => {
  return (
    <>
      <br />
      {renderPaymentMethodRadioButton(
        paymentMethods.EFT,
        processing,
        selectedPaymentMethod,
        handlePaymentMethodChange,
        automationIds.eftRadioButton,
        formRenderProps,
      )}
      {selectedPaymentMethod === paymentMethods.EFT.id && (
        <EftPreferenceOptions
          bankNameLabel="Bank Name"
          routingNumberLabel="Routing Number"
          accountNumberLabel="Account Number"
        />
      )}
      <PrefSeparator />
    </>
  );
};

const RenderZelleRadioAndOptions = (
  processing: boolean,
  selectedPaymentMethod: any,
  handlePaymentMethodChange: Function,
  automationIds: any,
  formRenderProps: any,
  optionProps: any,
) => {
  return (
    <>
      {renderPaymentMethodRadioButton(
        paymentMethods.Zelle,
        processing,
        selectedPaymentMethod,
        handlePaymentMethodChange,
        automationIds.zelleRadioButton,
        formRenderProps,
      )}
      {selectedPaymentMethod === paymentMethods.Zelle.id && (
        <ZellePreferenceOptions {...optionProps} />
      )}
      <PrefSeparator />
    </>
  );
};

const renderReadOnlyContainer = (
  prefs: any,
  selectedPaymentMethod: any,
  zellePrefs: any,
  setEditMode: Function,
  id: string,
) => {
  return (
    <ReadOnlyContainer>
      <div>
        {selectedPaymentMethod === paymentMethods.Check.id && (
          <CheckMethodLabel>Mailed Paper Check</CheckMethodLabel>
        )}
        {selectedPaymentMethod === paymentMethods.Zelle.id && (
          <ZelleMethodLabel data-cb-mask="true">
            <span>
              <img src={ZelleIcon} alt="" />
            </span>
            Account:{' '}
            {zellePrefs.email
              ? zellePrefs.email
              : formatPhoneForDisplay(zellePrefs.phone)}
          </ZelleMethodLabel>
        )}
        {selectedPaymentMethod === paymentMethods.EFT.id && (
          <>
            <MutedLabel data-cb-mask="true">
              {get(prefs, 'eft.bankName', '')}
            </MutedLabel>
            <MutedLabel className="text-uppercase">Account Number</MutedLabel>
            <AccountNumberLabel data-cb-mask="true">
              {get(prefs, 'eft.accountNum', '').replace(/.(?=.{4,}$)/g, '•')}
            </AccountNumberLabel>
          </>
        )}
      </div>
      <EditButtonMod id={id} onClick={() => setEditMode(true)} disabled={false}>
        <EditImage src={EditIcon} alt="" />
        Edit
      </EditButtonMod>
    </ReadOnlyContainer>
  );
};

const eftValidator = (values: any, eftValidation: any) => {
  const errObj: { [key: string]: any } = {};

  if (
    eftValidation &&
    eftValidation.err &&
    eftValidation.accountDetails.accountNo ===
      values[EftIds.accountNumberInput] &&
    eftValidation.accountDetails.routingNumber ===
      values[EftIds.routingNumberInput]
  ) {
    errObj[EftIds.accountNumberInput] = eftValidation.err;
    errObj[EftIds.routingNumberInput] = eftValidation.err;
  }

  return errObj;
};

const getUpdatePayload = (
  currentPrefs: any,
  selectedPaymentMethod: any,
  dataItem: any,
) => {
  const payload = { ...currentPrefs };
  payload.paymentMethod = selectedPaymentMethod;

  switch (selectedPaymentMethod) {
    case 'zelle':
      if (dataItem['paymentPhone']) {
        payload.zelle.phone = formatPhoneForSubmission(
          dataItem['paymentPhone'],
        );
      } else {
        if (dataItem['paymentEmail']) {
          payload.zelle.email = dataItem['paymentEmail'];
        }
      }
      break;

    case 'eft':
      payload.eft.bankName = dataItem[EftIds.bankNameInput];
      payload.eft.accountNum = dataItem[EftIds.accountNumberInput];
      payload.eft.routingNum = dataItem[EftIds.routingNumberInput];
      break;
  }

  return payload;
};

const getSetPaymentPreferences = async (
  setPrefs: Function,
  setSelectedPaymentMethod: Function,
  setProcessing: Function,
  setToast: Function,
  setGetError: Function,
) => {
  try {
    const prefs = (await getPaymentPreferences()) as PaymentPreferencesModel;
    if (prefs) {
      setPrefs(prefs);
      setSelectedPaymentMethod(prefs.paymentMethod);
    }
    setProcessing(false);
  } catch (e: any) {
    setToast(toastMessages.getError, 'error');
    setProcessing(false);
    setGetError(true);
  }
};

const handleFormSubmit = async (
  dataItem: any,
  prefs: any,
  selectedPaymentMethod: any,
  setProcessing: Function,
  setEftValidation: Function,
) => {
  setProcessing(true);
  const payload = getUpdatePayload(prefs, selectedPaymentMethod, dataItem);

  try {
    const response = await postPaymentPreferences(
      payload,
      prefs.paymentPreferenceId,
    );
    response.customerAddress = prefs.customerAddress;
    return true;
  } catch (err: any) {
    const msg = get(err, 'response.data.errorMessage', err.message);
    if (get(err, 'response.status', 0) === 400) {
      setEftValidation({ accountDetails: payload.accountDetails, err: msg });
    }
    return false;
  } finally {
    setProcessing(false);
  }
};

const Spinner = (
  closeToast: Function,
  displayToast: boolean,
  toastType: any,
  automationIds: any,
  message: string,
  getError: boolean,
) => {
  return (
    <Container id={automationIds.rootNode}>
      <ToastNotificationGroupStyle
        style={{
          top: 0,
          right: 0,
          alignItems: 'flex-end',
          position: 'fixed',
        }}
      >
        {displayToast && (
          <StyledNotification
            key="success"
            type={{ style: toastType, icon: true }}
            closable={true}
            onClose={() => closeToast}
          >
            {message}
          </StyledNotification>
        )}
      </ToastNotificationGroupStyle>
      <SectionTitleMod>Payments</SectionTitleMod>
      <SectionSeparator />
      <SectionSubTitleMod>
        You can change your payment method to one of our other options anytime.
      </SectionSubTitleMod>
      {getError ? (
        <ErrorText>
          There was an error fetching your preferences. Please{' '}
          <Link to={AppPaths.contactUs}>contact us</Link> to resolve this issue.
        </ErrorText>
      ) : (
        <StyledSpinner />
      )}
    </Container>
  );
};

const getAddressFromPrefs = (check: any) => {
  const address: { [key: string]: string } = {};

  if (check && check.address) {
    check.address.forEach((l: any, i: number) => {
      const lineNumber: number = i + 1;
      address['addressLine' + lineNumber] = l;
    });
    address['city'] = check.city;
    address['state'] = check.state;
    address['zip'] = check.zip;
  }
  return address;
};

const zelleLookupResult = (
  valid: boolean,
  isEmail: boolean,
  formRenderProps: any,
  setZelleValid: Function,
) => {
  setZelleValid(valid);
  if (valid) {
    formRenderProps.onChange(isEmail ? 'paymentEmail' : 'paymentPhone', {
      value: valid,
    });
  }
};

const PaymentPreferences = (props: any) => {
  const [editMode, setEditMode] = useState(false);
  const [eftValidation, setEftValidation] = useState({});
  const [processing, setProcessing] = useState(true);
  const [prefs, setPrefs] = useState<PaymentPreferencesModel | any>({});
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState('');
  const [zelleValid, setZelleValid] = useState(false);
  const [displayToast, setDisplayToast] = useState<boolean>(false);
  const [toastMessage, setToastMessage] = useState<string>('');
  const [toastType, setToastType] = useState<ToastTypes['style']>('success');
  const [getError, setGetError] = useState<boolean>(false);

  useEffect(() => {
    getSetPaymentPreferences(
      setPrefs,
      setSelectedPaymentMethod,
      setProcessing,
      setToast,
      setGetError,
    );
  }, []);

  const setToast = (message: string, type: any) => {
    setToastMessage(message);
    setToastType(type);
    setDisplayToast(true);
  };

  const closeToast = () => {
    setDisplayToast(false);
  };

  setTimeout(() => {
    if (displayToast) {
      closeToast();
    }
  }, 4000);

  const automationIds = domIdsUnique(props.keyId);
  const address = getAddressFromPrefs(prefs.check);
  const zelleEmail = props.userEmail;
  const initialFormValues = getInitialFormValues(prefs);

  const reset = (paymentMethod: any) => {
    setSelectedPaymentMethod(paymentMethod);
    setZelleValid(false);
    setEditMode(false);
    setEftValidation({});
  };

  const formValidator = (values: any) => {
    return eftValidator(values, eftValidation);
  };

  const handleCancelOnClick = () => {
    reset(prefs.paymentMethod);
  };

  const handlePaymentMethodChange = (e: any, formRenderProps: any) => {
    if (e.value === paymentMethods.Zelle.id) {
      setZelleValid(false);
    }
    setSelectedPaymentMethod(e.value);
    formRenderProps.onChange('paymentMethod', { value: e.value });
  };

  if (processing || getError) {
    return Spinner(
      closeToast,
      displayToast,
      toastType,
      automationIds,
      toastMessage,
      getError,
    );
  }

  const callSubmit = async (dataItem: any) => {
    const success = await handleFormSubmit(
      dataItem,
      prefs,
      selectedPaymentMethod,
      setProcessing,
      setEftValidation,
    );

    if (success) {
      setToast(toastMessages.success, 'success');
      reset(selectedPaymentMethod);
    } else {
      setToast(toastMessages.postError, 'error');
      reset(prefs.paymentMethod);
    }
  };

  return (
    <Container id={automationIds.rootNode}>
      <ToastNotificationGroupStyle
        style={{
          top: 0,
          right: 0,
          alignItems: 'flex-end',
          position: 'fixed',
        }}
      >
        {displayToast && (
          <StyledNotification
            key="success"
            type={{ style: toastType, icon: true }}
            closable={true}
            onClose={closeToast}
          >
            {toastMessage}
          </StyledNotification>
        )}
      </ToastNotificationGroupStyle>
      <SectionTitleMod>Payments</SectionTitleMod>
      <SectionSeparator />
      <SectionSubTitleMod>
        You can change your payment method to one of our other options anytime.
      </SectionSubTitleMod>
      <br />
      {!editMode &&
        renderReadOnlyContainer(
          prefs,
          selectedPaymentMethod,
          prefs.zelle,
          setEditMode,
          automationIds.readOnlyEditButton,
        )}
      {editMode && (
        <Form
          initialValues={initialFormValues}
          onSubmit={callSubmit}
          validator={formValidator}
          render={(formRenderProps) => (
            <>
              <RadioGroupMod>
                {RenderZelleRadioAndOptions(
                  processing,
                  selectedPaymentMethod,
                  handlePaymentMethodChange,
                  automationIds,
                  formRenderProps,
                  {
                    resultCallback: (valid: any, isEmail: boolean) =>
                      zelleLookupResult(
                        valid,
                        isEmail,
                        formRenderProps,
                        setZelleValid,
                      ),
                    currentEmailPref: zelleEmail,
                    currentPaymentMethod: prefs.paymentMethod,
                  },
                )}
                {RenderEFTRadioAndOptions(
                  processing,
                  selectedPaymentMethod,
                  handlePaymentMethodChange,
                  automationIds,
                  formRenderProps,
                )}
                {RenderCheckRadioAndOptions(
                  processing,
                  selectedPaymentMethod,
                  handlePaymentMethodChange,
                  automationIds,
                  formRenderProps,
                  address,
                )}
              </RadioGroupMod>
              <ControlContainer>
                <CancelButton
                  id={automationIds.cancelButton}
                  className="k-button"
                  onClick={handleCancelOnClick}
                  buttonStyle={ButtonTypes.secondary}
                >
                  Cancel
                </CancelButton>
                <SubmitButton
                  id={automationIds.submitButton}
                  className="k-button"
                  onClick={formRenderProps.onSubmit}
                  disabled={
                    !zelleValid &&
                    selectedPaymentMethod === paymentMethods.Zelle.id
                  }
                >
                  Submit
                </SubmitButton>
                <ClipLoader
                  size={25}
                  loading={processing}
                  color={colors.primaryBlue}
                />
              </ControlContainer>
            </>
          )}
        />
      )}
    </Container>
  );
};

export default PaymentPreferences;
export { eftValidator, getUpdatePayload };
