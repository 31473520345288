import React from 'react';
import styled from 'styled-components';
import { prefixObjectValues } from '../../../utils';

interface LandingProps {
  keyId?: string;
}

const domIdsStatic = {
  rootNode: 'landing',
};

export const domIdsUnique = (prefix?: string) =>
  prefixObjectValues(prefix, domIdsStatic);

const Container = styled.div`
  font-size: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;

  button {
    margin: 15px;
  }
`;

const CustomButton = styled.button`
  font-size: 1.5em;
  text-align: center;
  border: 7px solid #666666;
  margin: 15px;
  width: 15em;
  padding: 5px;
  box-shadow: -5px 5px 15px #888888;
  cursor: pointer;
`;

const Landing: (p: LandingProps) => React.ReactElement = (p) => {
  const domIds = domIdsUnique(p.keyId);
  const [liveToken, setLiveToken] = React.useState<string | null>(null);

  const setLiveTokenHandler = React.useCallback(
    (environment) => {
      document.cookie = `unumEnterpriseSSO_${environment}=${liveToken}; Secure`;
      sessionStorage.setItem('mock_source', 'proxy');
      sessionStorage.setItem('mock_env', environment);
      window.location.assign('/app');
    },
    [liveToken],
  );

  const mock = React.useCallback((mockFile) => {
    sessionStorage.setItem('mockFile', mockFile);
    sessionStorage.setItem('mock_source', 'browser');
    window.location.assign('/app');
  }, []);

  return (
    <Container id={domIds.rootNode}>
      {process.env.REACT_APP_MOCK && (
        <>
          <div className="tw-flex tw-flex-col tw-items-center tw-justify-center tw-mb-5">
            <div className="tw-text-center tw-w-full tw-size-4 tw-mb-2">
              Live Environment Token
            </div>
            <input
              className="tw-mt-5"
              type="text"
              onChange={(e) => setLiveToken(e.target.value)}
            />
            <div className="tw-flex tw-justify-center">
              <button
                disabled={!liveToken?.length}
                onClick={() => setLiveTokenHandler('DEV')}
              >
                DEV
              </button>
              <button
                disabled={!liveToken?.length}
                onClick={() => setLiveTokenHandler('ITEST')}
              >
                ITEST
              </button>
              <button
                disabled={!liveToken?.length}
                onClick={() => setLiveTokenHandler('ACPT')}
              >
                ACPT
              </button>
              <button
                disabled={!liveToken?.length}
                onClick={() => setLiveTokenHandler('')}
              >
                PROD
              </button>
            </div>
          </div>
          <CustomButton onClick={() => mock('data-benjamin-cochran')}>
            Mock Benjamin Cochran
          </CustomButton>
          <CustomButton onClick={() => mock('data-lisa-goodman')}>
            Mock Lisa Goodman
          </CustomButton>
          <CustomButton onClick={() => mock('data-thomas-cisneros')}>
            Thomas Cisneros
          </CustomButton>
          <CustomButton onClick={() => mock('data-carmen-watson')}>
            Carmen Watson
          </CustomButton>
        </>
      )}
    </Container>
  );
};

export default Landing;
