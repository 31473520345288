import React from 'react';
import ThumbsUp from '../../../../images/thumbs-up.svg';
import ErrorIcon from '../../../../images/red-exclamation.svg';
import ClockIcon from '../../../../images/orange-clock.svg';
import Button from '../../../base/button';
import { Link } from 'react-router-dom';

export type taskModelType = 'success' | 'error' | 'fail' | '';

type Props = {
  type: taskModelType;
  codename: string;
};

export const UserInitiatedModal: React.FC<Props> = ({ type, codename }) => {
  const title = () => {
    switch (type) {
      case 'success':
        return 'Task created';
      case 'error':
        return 'Error';
      case 'fail':
        return 'Task created';

      default:
        return '';
    }
  };

  const icon = () => {
    switch (type) {
      case 'success':
        return ThumbsUp;
      case 'error':
        return ErrorIcon;
      case 'fail':
        return ClockIcon;

      default:
        break;
    }
  };

  const message = () => {
    switch (type) {
      case 'success':
        return `Thank you for providing this information.  Your Specialist will review.  Please monitor your tasks and letters for a decision or additional information that may be needed.`;
      case 'error':
        return `Your task ${codename} could not be created. Please try again in a couple of minutes`;
      case 'fail':
        return `You created a task less than 24 hours ago. We are taking care of it your information at the moment.\n\nIf you need to create a new task please wait until 24 hours have passed from your last task creation`;
    }
  };

  return (
    <div className="tw-w-[420px] tw-border tw-rounded-md">
      <div className="tw-bg-[#FFFFFF] tw-h-[340px] tw-p-8 tw-text-[#525252] tw-font-semibold tw-flex tw-flex-col tw-justify-between">
        <div className="tw-text-unm-interactive01-700 tw-text-2xl">
          {title()}
        </div>
        <div className="tw-flex tw-justify-center">
          <img src={icon()} alt="modal" />
        </div>
        <div className="tw-whitespace-pre-line">{message()}</div>
      </div>
      <div className="tw-bg-unm-adhoc3 tw-h-[60px] tw-flex tw-items-center tw-justify-end tw-p-8">
        <Link to="/app">
          <Button>Ok</Button>
        </Link>
      </div>
    </div>
  );
};
