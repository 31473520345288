import styled from 'styled-components';
import { colors, fonts, rem } from '../../../style';
import { enums, get, prefixObjectValues } from '../../../utils';

export interface ButtonProps extends React.HTMLAttributes<HTMLButtonElement> {
  keyId?: string;
  buttonStyle?: enums.ButtonTypes;
  hPadding?: number;
  disabled?: boolean;
  type?: 'button' | 'reset' | 'submit';
}

export interface StyledButtonProps {
  hPadding: number;
}

const domIdsStatic = {
  rootNode: 'button',
};

export const domIdsUnique = (prefix?: string) =>
  prefixObjectValues(prefix, domIdsStatic);

// color constants
const darkBlueColor = '#025780';
const disabledColor = '#aaa';

// helpers
const getPx = (v: number, m = 0) => `${v + m}px`;

// components
const BaseButton = styled.button`
  border-radius: 10px;
  font-family: ${fonts.openSans.semibold.fontFamily};
  font-size: ${rem(16)};
  font-weight: ${fonts.openSans.semibold.fontWeight};
  text-decoration: none;

  :focus {
    text-decoration: underline;
  }

  :hover:not(:disabled) {
    text-decoration: underline;
  }
`;

const PrimaryButton = styled(BaseButton)<StyledButtonProps>`
  background-color: ${colors.skyD2};
  border: 2px solid ${colors.skyD2};
  color: ${colors.white};
  padding: 6px ${(props) => getPx(props.hPadding)};

  @media (max-width: 500px) {
    padding: 6px ${(props) => getPx(props.hPadding - 15)};
  }

  :focus {
    background-color: ${colors.skyD2};
    border-color: ${darkBlueColor};
  }

  :hover:not(:disabled) {
    background-color: ${darkBlueColor};
    border-color: ${darkBlueColor};
  }

  :disabled {
    background-color: ${disabledColor};
    border-color: ${disabledColor};
  }
`;

const BreadcrumbButton = styled(PrimaryButton)`
  border-radius: 20px;
  font-size: ${rem(15)};
  padding: 3px 14px;
  text-decoration: none !important;
  white-space: nowrap;
`;

const SecondaryButton = styled(BaseButton)<StyledButtonProps>`
  background-color: ${colors.white};
  border: 1px solid ${colors.skyD2};
  color: ${colors.skyD2};
  padding: 7px ${(props) => getPx(props.hPadding, 1)};

  :focus {
    background-color: ${colors.coolNeutralL1};
    border-color: ${darkBlueColor};
    border-width: 2px;
    color: ${darkBlueColor};
    padding: 6px ${(props) => getPx(props.hPadding)};
  }

  @media (max-width: 500px) {
    padding: 7px ${(props) => getPx(props.hPadding - 15, 1)};
  }

  :hover:not(:disabled) {
    background-color: ${colors.coolNeutralL1};
    border-color: ${darkBlueColor};
    color: ${darkBlueColor};
    text-decoration: none;
  }

  :disabled {
    border-color: ${disabledColor};
    color: ${disabledColor};
  }
`;

const OutlineButton = styled(BaseButton)<StyledButtonProps>`
  background-color: ${colors.white};
  border: 1px solid ${colors.primaryBlue};
  color: ${colors.primaryBlue};
  font-family: ${fonts.openSans.regular.fontFamily};
  font-size: ${rem(18)};
  font-weight: ${fonts.openSans.regular.fontWeight};
  margin: 0;
  padding: 0.8rem 1.6rem;
  text-decoration: none;

  &.selected,
  :hover,
  :focus {
    background-color: ${colors.primaryBlue};
    color: ${colors.white};
    text-decoration: none;
  }

  :hover:not(:disabled) {
    text-decoration: none;
  }
`;

const SmallOutlineButton = styled.button<StyledButtonProps>`
  background-color: ${colors.white};
  border: 2px solid ${colors.denim};
  border-radius: 24px;
  color: ${colors.denim};
  font-family: ${fonts.openSans.semibold.fontFamily};
  font-size: ${rem(16)};
  font-weight: ${fonts.openSans.semibold.fontWeight};
  line-height: 22px;
  margin: 0;
  max-width: 140px;
  padding: 3px 0;
  text-decoration: none;
  width: 100%;

  &.selected,
  :hover {
    background-color: ${colors.denim};
    border: 2px solid ${colors.skyD2};
    color: ${colors.white};
  }
`;

const SecondaryButtonRed = styled(SecondaryButton)`
  border: 1px solid ${colors.cancelRed};
  color: ${colors.cancelRed};

  :focus {
    border-color: ${colors.cancelRed};
    color: ${colors.cancelRed};
  }

  :hover:not(:disabled) {
    border-color: ${colors.cancelRed};
    color: ${colors.cancelRed};
  }
`;

const PrimaryLargeButton = styled(BaseButton)<StyledButtonProps>`
  background-color: ${colors.primaryBlue};
  border: 1px solid ${colors.primaryBlue};
  color: ${colors.white};
  font-family: ${fonts.openSans.regular.fontFamily};
  font-size: ${rem(16)};
  font-weight: ${fonts.openSans.regular.fontWeight};
  margin: 0;
  padding: 18px 25px;
  text-decoration: none;

  &.selected,
  :hover,
  :focus {
    background-color: ${colors.white};
    border: 1px solid ${colors.primaryBlue};
    color: ${colors.primaryBlue};
    text-decoration: none;
  }

  :hover:not(:disabled) {
    text-decoration: none;
  }
`;

const FlatButton = styled(BaseButton)`
  border: 0px;
  color: ${colors.skyD2} !important;
  font-size: ${rem(13)};
  padding: 5px 0px;
  text-decoration: none !important;
  white-space: nowrap;
  background-color: initial;
`;

const LinkStyledButton = styled(BaseButton)`
  background-color: transparent;
  border: 0;
  color: ${colors.skyD2} !important;
  font-size: ${rem(14)};
  padding: 5px 0;
  white-space: nowrap;
`;

// styled buttons map
export const styledButtons = {
  [enums.ButtonTypes.breadcrumb]: BreadcrumbButton,
  [enums.ButtonTypes.primary]: PrimaryButton,
  [enums.ButtonTypes.secondary]: SecondaryButton,
  [enums.ButtonTypes.secondaryRed]: SecondaryButtonRed,
  [enums.ButtonTypes.flat]: FlatButton,
  [enums.ButtonTypes.link]: LinkStyledButton,
  [enums.ButtonTypes.outline]: OutlineButton,
  [enums.ButtonTypes.primaryLarge]: PrimaryLargeButton,
  [enums.ButtonTypes.smallOutline]: SmallOutlineButton,
};

const Button: (p: ButtonProps) => React.ReactElement = ({
  children,
  keyId,
  disabled,
  ...others
}) => {
  const buttonStyle = others.buttonStyle ?? enums.ButtonTypes.primary;
  const hPadding: number = get(others, 'hPadding', 48);
  const domIds = domIdsUnique(keyId);
  const StyledButton = styledButtons[buttonStyle];

  return (
    <StyledButton
      disabled={disabled}
      id={domIds.rootNode}
      hPadding={hPadding}
      {...others}
    >
      {children || ''}
    </StyledButton>
  );
};

export default Button;
