import { isAuthenticated } from '../auth-manager';
import {
  getAllAssetReferences,
  getFeatureFlags,
  getSimpleRemoteConfig,
  getLocalizedStrings,
  getMarkdownContent,
  getSplashConfig,
  ISplashConfig,
  IEmployerContent,
  getEmployerContentSafely,
} from '../remote-config-manager';
import { bypassLoginRefresh, enums } from '..';
import { getPersonData, getEmployerPreferences } from '../web-apis-client';
import {
  IKenticoData,
  refreshCallCenter,
} from '../../contexts/kentico-data-context';
import { handleRefresh, startRefresh } from '../../hooks/use-refresh-handler';
import {
  FullRefreshStatuses,
  LoginRefreshStatuses,
} from '../../contexts/refresh-context';
import { log } from '../log';

/*******************************************************************************
 * startup-manager.js is a collection of functions to assist with the startup
 * routine of the app (splash screen config, authentication, remote config, etc)
 ******************************************************************************/

export interface StartUpSequenceResult {
  state: string;
  context: {
    kentico: IKenticoData;
    remoteConfig?: {};
    splashConfig?: ISplashConfig;
    handleLoginResult?: boolean;
    personData?: { [name: string]: any };
    featureFlags?: {};
    employerPreferences?: {};
    refreshResult: {
      status: string;
      loginRefreshStatus: { status: LoginRefreshStatuses };
      fullRefreshStatus: { status: FullRefreshStatuses };
      statusDetail: any;
    };
    ERContent?: IEmployerContent;
  };
  error?: unknown;
}

const checkSplashConfig = async (returnObj: StartUpSequenceResult) => {
  try {
    // get splash config first
    returnObj.state = enums.AppStates.GET_SPLASH_CONFIG;
    const splashConfig = await getSplashConfig();
    returnObj.context.splashConfig = splashConfig;
  } catch (error) {
    returnObj.state = enums.AppStates.GET_SPLASH_CONFIG_ERROR;
    returnObj.error = error;
    throw error;
  }
};

const getKenticoData = async (returnObj: StartUpSequenceResult) => {
  try {
    returnObj.state = enums.AppStates.GET_KENTICO_DATA;
    const results = await Promise.all([
      await getAllAssetReferences(),
      await getFeatureFlags(),
      await getSimpleRemoteConfig(),
      await getLocalizedStrings(),
      await getMarkdownContent(),
    ]);
    returnObj.context.kentico.assets = results[0];
    returnObj.context.kentico.featureFlags = results[1];
    returnObj.context.kentico.config = results[2];
    returnObj.context.kentico.localizedStrings = results[3];
    returnObj.context.kentico.dynamicContent = results[4];
  } catch (error) {
    returnObj.state = enums.AppStates.GET_KENTICO_DATA_ERROR;
    returnObj.error = error;
    throw error;
  }
};

const tryAuthenticating = async (returnObj: StartUpSequenceResult) => {
  try {
    returnObj.state = enums.AppStates.AUTHENTICATING;
    let loggedIn = false;
    loggedIn = await isAuthenticated();
    returnObj.context.handleLoginResult = loggedIn;
  } catch (error) {
    returnObj.state = enums.AppStates.AUTHENTICATION_ERROR;
    returnObj.error = error;
    throw error;
  }
};

const loginRefresh = async (returnObj: StartUpSequenceResult) => {
  // LOCAL: bypass login refresh
  if (process.env.REACT_APP_MOCK) {
    bypassLoginRefresh();
  }

  if (sessionStorage.getItem(enums.CacheKeys.login_refresh_bypass) === 'true') {
    returnObj.context.refreshResult.loginRefreshStatus.status =
      enums.RefreshStatus.refreshed;
    sessionStorage.removeItem(enums.CacheKeys.login_refresh_bypass);
  } else {
    returnObj.context.refreshResult = await handleRefresh(true, false);
  }

  //trigger the full refresh
  if (sessionStorage.getItem(enums.CacheKeys.full_refresh_bypass) === 'true') {
    returnObj.context.refreshResult.fullRefreshStatus.status =
      enums.RefreshStatus.refreshed;
    sessionStorage.removeItem(enums.CacheKeys.full_refresh_bypass);
  } else {
    await startRefresh(false, true);
  }
};

const checkRefreshStatus = async (returnObj: StartUpSequenceResult) => {
  if (
    returnObj.context.refreshResult.loginRefreshStatus.status ===
    enums.RefreshStatus.failed
  ) {
    returnObj.state = enums.AppStates.FAILED;
    throw new Error('Login refresh failed');
  }

  if (
    returnObj.context.refreshResult.loginRefreshStatus.status ===
    enums.RefreshStatus.notRegisterable
  ) {
    returnObj.state = enums.AppStates.NOT_REGISTERABLE;
    returnObj.context.personData = {};
    returnObj.context.kentico.callCenter = await refreshCallCenter('-1');
    throw new Error('Login refresh not registerable');
  }
};

const initializePersonData = async (returnObj: StartUpSequenceResult) => {
  returnObj.context.personData = await getPersonData();
  returnObj.context.kentico.callCenter = await refreshCallCenter(
    returnObj.context.personData?.person?.companyCode,
  );

  returnObj.context.ERContent = await getEmployerContentSafely(
    returnObj.context.personData?.person?.companyCode,
  );
};

const setEmployerPreferences = async (returnObj: StartUpSequenceResult) => {
  try {
    returnObj.context.employerPreferences = await getEmployerPreferences();
  } catch (error) {
    log.error(error);
  }
};

const finalCheck = (returnObj: StartUpSequenceResult) => {
  if (
    returnObj.context.refreshResult.loginRefreshStatus.status ===
    enums.RefreshStatus.failedWithData
  ) {
    returnObj.state = enums.AppStates.FAILED_WITH_DATA;
  } else {
    returnObj.state = enums.AppStates.COMPLETE;
  }
};

/**
 * Executes the startup sequence and returns an object that indicates the state
 * along with any contextual data related to that state
 * @returns {{state:string, context:StartupContext}}
 */
export const startUpSequence = async () => {
  const returnObj: StartUpSequenceResult = {
    state: 'INIT',
    context: {
      refreshResult: {
        status: '',
        loginRefreshStatus: { status: enums.RefreshStatus.started },
        fullRefreshStatus: { status: enums.RefreshStatus.started },
        statusDetail: {},
      },
      kentico: {} as any,
    },
  };

  try {
    await checkSplashConfig(returnObj);
    await getKenticoData(returnObj);

    if (returnObj.context?.splashConfig?.showSplash) {
      returnObj.state = enums.AppStates.SHOW_SPLASH;
      return returnObj;
    }

    // after confirming splash is not relevant, but before login redirects, store the entry point
    if (!sessionStorage.getItem(enums.CacheKeys.entryPoint)) {
      sessionStorage.setItem(
        enums.CacheKeys.entryPoint,
        window.location.pathname,
      );
    }

    await tryAuthenticating(returnObj);
    await loginRefresh(returnObj);
    returnObj.state = enums.AppStates.AUTHENTICATED;
    await checkRefreshStatus(returnObj);
    await initializePersonData(returnObj);
    await setEmployerPreferences(returnObj);
    finalCheck(returnObj);
  } catch (error) {
    log.error(error);
  }

  return returnObj;
};
