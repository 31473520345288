import styled from 'styled-components';
import dayjs from 'dayjs';
import weekday from 'dayjs/plugin/weekday';
import isBetween from 'dayjs/plugin/isBetween';
import useHandlebars from '../../../../../hooks/use-handlebars';
import { prefixObjectValues } from '../../../../../utils';
import { IElement } from '../../../';
import React from 'react';
dayjs.extend(weekday);
dayjs.extend(isBetween);

export interface IRangeElement {
  id: string;
  item: IElement;
  date: dayjs.Dayjs;
  style: React.CSSProperties;
  order: number;
  totalRanges: number;
  legend: { [key: string]: any };
}

interface IRangeStyle {
  startH: number;
  startV: number;
  endH: number;
  endV: number;
  endDay: number;
  row: number;
  start: boolean;
  end: boolean;
  legendStyle: any;
  order: number;
  totalRanges: number;
}

const oneWeekRangeTemplate = (p: IRangeStyle) =>
  p.start &&
  p.end &&
  ` 
  left: calc((100% / 7) * ${p.startH});
  top: calc(
    (100% / 6) * ${p.startV - 1} +
      (var(--day-offset) * ${p.order}) + (${p.order} * 2px)
  );
  width: calc((100% / 7) * ${p.endH} - 10px);
`;

const middleRangeTemplate = (p: IRangeStyle) =>
  !p.start &&
  !p.end &&
  ` 
  left: 0; 
  top: calc((100% / 6) * ${p.startV - 1 + p.row} + (var(--day-offset) * ${
    p.order
  }) + (${p.order} * 2px));
  width: calc(100% - 10px);
`;

const endWeekTemplate = (p: IRangeStyle) =>
  !p.start &&
  p.end &&
  ` 
  left: 0; 
  top: calc((100% / 6) * ${p.endV - 1} + (var(--day-offset) * ${p.order}) + (${
    p.order
  } * 2px));
  width: calc((100% / 7) * ${p.endDay + 1} - 10px);
`;

const RangeStyled = styled.div<IRangeStyle>`
  pointer-events: none;
  box-shadow: 0 0 0 1px #fff;
  width: 20px;
  color: #fff;
  font-family: 'Open Sans';
  font-size: 10px;
  font-weight: 700;
  line-height: 10px;
  display: flex;
  align-items: center;
  padding-left: 3px;
  --day-offset: calc(
    ${(p) => p.theme.dayHeight} / ${(p) => p.totalRanges} - 15px
  );
  --range-gap: calc(${(p) => p.order} * 2px);
  --range-offset: calc(var(--day-offset) * ${(p) => p.order}) + var(--range-gap);
  --vertical-division: calc((100% / 6) * ${(p) => p.startV - 1});
  height: var(--day-offset);
  max-height: 18px;
  border-radius: 0 18px 18px 0;
  margin: 20px 5px 0;
  position: absolute;

  left: calc((100% / 7) * ${(p) => p.startH});
  top: calc(
    (100% / 6) * ${(p) => p.startV - 1} +
      (var(--day-offset) * ${(p) => p.order}) + (${(p) => p.order} * 2px)
  );

  top: calc(var(--vertical-division) + var(--range-offset));
  width: calc((100% / 7) * ${(p) => 7 - p.startH} - 10px);

  ${(p) => middleRangeTemplate(p)}

  ${(p) => endWeekTemplate(p)}
  
  ${(p) => oneWeekRangeTemplate(p)}

  ${(p) => p.legendStyle}
`;

const domIdsStatic = {
  rootNode: '-range',
};

export const domIdsUnique = (prefix?: string) =>
  prefixObjectValues(prefix, domIdsStatic);

const RangeElement = (p: IRangeElement): React.JSX.Element | null => {
  const domIds = domIdsUnique(p.id);
  const r = p.item;
  const content = useHandlebars(p?.legend?.content, p?.item ?? {});
  if (
    !dayjs(p.date).isBetween(
      dayjs(r.startDate),
      dayjs(r.endDate),
      'month',
      '[]',
    )
  ) {
    return null;
  }

  const rangeStart =
    dayjs(r.startDate).month() === p.date.month()
      ? dayjs(r.startDate)
      : dayjs(p.date).startOf('month');

  const rangeEnd =
    dayjs(r.endDate).month() === p.date.month()
      ? dayjs(r.endDate)
      : dayjs(p.date).endOf('month');

  const startH = rangeStart.weekday();

  const dayInMonth = rangeStart.date();
  const startOfMonth = rangeStart.startOf('month').weekday();

  const startV = Math.ceil((dayInMonth + startOfMonth) / 7);

  const endDayInMonth = rangeEnd.date();
  const endV = Math.ceil((endDayInMonth + startOfMonth) / 7);

  const endH = rangeEnd.weekday() - rangeStart.weekday() + 1;

  const totalRanges = endV - startV + 1;
  const props = {
    startH,
    startV,
    endH,
    endV,
    endDay: rangeEnd.weekday(),
    legendStyle: p.style,
    order: p.order,
    totalRanges: p.totalRanges,
  };

  return (
    <>
      {[...Array(totalRanges)].map((_, i) => (
        <RangeStyled
          key={`${domIds.rootNode}-${i}`}
          id={`${domIds.rootNode}-${i}`}
          start={i === 0}
          end={i === totalRanges - 1}
          row={i}
          {...props}
        >
          <span>{content}</span>
        </RangeStyled>
      ))}
    </>
  );
};

export default RangeElement;
