import React, { useEffect } from 'react';
import styled from 'styled-components';
import { colors, fonts } from '../../../style';
import dayjs from 'dayjs';
import { IPaymentAggregate } from '..';
import arrow from '../../../images/down_arrow.svg';
import { PaymentDetail } from '../../../data-models';

export interface TextProps {
  align?: string;
  color?: string;
}

const dateFormat = 'MM/DD/YYYY';

export const Container = styled.div`
  padding-left: 32px;
  padding-right: 32px;
  padding-top: 16px;
  padding-bottom: 16px;

  .k-animation-container {
    z-index: 1 !important;
  }
`;

export const Arrow = styled.img.attrs({
  alt: 'Expand',
  src: arrow,
})`
  cursor: pointer;
  margin-right: 0.5em;
  transform: ${(props: { rotate: string }) =>
    props.rotate === 'true' ? 'rotate(180deg)' : 'rotate(0deg)'};
  transition: all 0.5s;
  user-select: none;
  width: 2.75em;

  @media (min-width: 500px) {
    margin-right: 1em;
  }
`;

export const PaymentItem = styled.button`
  background-color: ${colors.white};
  border: 1px solid ${colors.alto2};
  border-radius: 8px;
  justify-content: space-between;
  position: relative;
  z-index: 2;
  width: 100%;
  padding: 0px;
`;

const DetailHeader = styled.span`
  font-family: ${fonts.openSans.semibold.fontFamily};
  font-weight: ${fonts.openSans.semibold.fontWeight};
  font-size: 14px;
  color: ${colors.warmNeutralL3};
`;

const Text = styled.div<TextProps>`
  font-family: ${fonts.ubuntu.bold.fontFamily};
  font-weight: ${fonts.ubuntu.bold.fontWeight};
  font-size: 14px;
  color: ${(p) => p.color};
  text-align: ${(p) => p.align};

  &.amount {
    font-family: ${fonts.ubuntu.semibold.fontFamily};
    font-weight: ${fonts.openSans.semibold.fontWeight};
    font-size: 20px;
    color: ${colors.prussianblue};
    @media (max-width: 768px) {
      padding-top: 10px;
      text-align: start;
    }
  }

  &.claimName {
    font-family: ${fonts.openSans.regular.fontFamily};
    font-weight: ${fonts.openSans.regular.fontWeight};
    font-size: 12px;
    color: ${colors.darkgrey};
  }
`;

const StyledLine = styled.div`
  background: #cee0e3;
  border-style: none;
  color: #cee0e3;
  height: 1px;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: auto;
  margin-right: auto;
  width: 97%;
`;

const DetailAmount = styled.span`
  font-family: ${fonts.openSans.regular.fontFamily};
  font-weight: ${fonts.openSans.regular.fontWeight};
  font-size: 14px;
  color: ${colors.warmNeutralL3};
  text-align: right;
`;

interface SpanProps {
  bold?: boolean;
}

const DetailReason = styled.span<SpanProps>`
  font-family: ${fonts.openSans.semibold.fontFamily};
  font-weight: ${fonts.openSans.semibold.fontWeight};
  font-weight: ${(p) =>
    p.bold
      ? fonts.openSans.bold.fontWeight
      : fonts.openSans.semibold.fontWeight};
  font-size: 14px;
  color: ${colors.warmNeutralL3};
  text-align: left;
`;

interface ContainerProps {
  expanded: boolean;
}

export const SummaryContainer = styled.div<ContainerProps>`
  align-items: center;
  padding-top: 32px;
  padding-bottom: 32px;
  width: 100%;
  border-bottom: ${(p) => (p.expanded ? '1px solid #DDDDDD' : 'unset')};
  border-radius: 8px;
`;

const DetailsContainer = styled.div`
  background-color: #fcfcfc;
  width: 100%;
  padding-top: 32px;
  padding-bottom: 32px;
`;

const InfoBox = styled.div`
  display: flex;
  align-items: center;
  border-radius: 8px;
  background-color: #eff3f8;
  padding: 10px;
  font-family: ${fonts.openSans.medium.fontFamily};
  font-size: 14px;
  font-weight: ${fonts.openSans.medium.fontWeight};
  color: #525252;
  text-align: left;
`;

interface StatusIconProps {
  future: boolean | undefined;
}

export const StatusIcon = styled.div<StatusIconProps>`
  opacity: 1;
  background-color: ${(p) => (p.future ? '#d9ebf4' : '#D9F2EA')};
  border-radius: 30px;
  color: ${(p) => (p.future ? '#037CB7' : '#04865D')};
  font-family: ${fonts.openSans.regular.fontFamily};
  font-size: 14px;
  font-weight: ${fonts.openSans.regular.fontWeight};
  padding: 8px 0;
  text-align: center;
  width: 140px;
  margin-left: auto;
  margin-right: 10px;
`;

export const presentablePaymentMethod = (paymentMethod: string) => {
  if (!paymentMethod) {
    return paymentMethod;
  }

  const pmMap: { [name: string]: string } = {
    check: 'Check',
    eft: 'EFT',
    zelle: 'Zelle',
  };

  let result = pmMap[paymentMethod];

  if (!result) {
    result = paymentMethod.charAt(0).toUpperCase() + paymentMethod.slice(1);
  }

  return result;
};

export const presentableDetailReason = (detailReason: string | undefined) => {
  if (!detailReason) {
    return detailReason;
  }

  const drMap: { [name: string]: string } = {
    'Auto Gross Entitlement': 'Gross Pay',
  };

  let result = drMap[detailReason];

  if (!result) {
    result = detailReason;
  }

  return result.toUpperCase();
};

export const getPaymentText = (isCheckCutting: boolean, future: boolean) => {
  if (future && !isCheckCutting) {
    return 'EMPLOYER TO BE NOTIFIED';
  } else if (!future && !isCheckCutting) {
    return 'EMPLOYER NOTIFIED';
  } else if (!future && isCheckCutting) {
    return 'ISSUED';
  } else {
    return 'TO BE ISSUED';
  }
};

export const getPaymentAmount = (
  isCheckCutting: boolean,
  futurePayment: boolean,
  item: any,
) => {
  return (
    <Text align="left" className="amount col-12">
      {!isCheckCutting
        ? 'Payment to be made by Employer'
        : futurePayment
        ? `Estimated Payment of $${item.paymentAmount}`
        : `Payment of $${item.paymentAmount}`}
    </Text>
  );
};

const getDetailsContainer = (
  isCheckCutting: boolean,
  futurePayment: boolean,
  item: any,
  index: number,
  grossPay: any,
) => {
  return (
    <DetailsContainer>
      <div
        id={`payment-details-${index}`}
        className="d-flex flex-column mt-1 pl-2"
      >
        {futurePayment && isCheckCutting && (
          <div
            id={`payment-details-${index}`}
            className="d-flex flex-column pl-2 pr-3"
          >
            <InfoBox className="mb-3 col-12 col-md-12">
              <span
                className="k-icon k-i-info mr-2"
                style={{ color: '#015294' }}
              ></span>
              <span>
                This payment is an estimate and subject to change based on plan
                provisions. Please check back on the release date for payment
                amount.
              </span>
            </InfoBox>
          </div>
        )}
        <Text
          className="col-12 pt-2 pb-2"
          align="left"
          color={colors.sanmarino}
        >
          PAYMENT DETAILS
        </Text>
        <div className="d-flex mt-1" color="#212529">
          <Text className="col-5" align="left">
            <DetailHeader>PERIOD: </DetailHeader>
            <DetailAmount>
              {dayjs(item.periodStartDate).format(dateFormat)} -{' '}
              {dayjs(item.periodEndDate).format(dateFormat)}
            </DetailAmount>
          </Text>
          <Text className="col-3" align="left">
            <DetailHeader>PAID BY: </DetailHeader>
            <DetailAmount>
              {isCheckCutting ? 'Unum' : 'Your employer'}
            </DetailAmount>
          </Text>
          <Text className="col-3" align="left">
            <DetailHeader>METHOD: </DetailHeader>
            <DetailAmount>
              {isCheckCutting && !futurePayment && item.paymentMethod
                ? presentablePaymentMethod(item.paymentMethod)
                : 'N/A'}
            </DetailAmount>
          </Text>
        </div>
        {isCheckCutting && (
          <>
            <StyledLine />
            <Text
              className="col-12 pt-2 pb-2"
              align="left"
              color={colors.sanmarino}
            >
              PAYMENT BREAKDOWN
            </Text>
            <div
              id={`payment-item-${index}-detail-grosspay`}
              className="d-flex flex-row pt-2 pb-2"
            >
              <DetailReason
                id={`payment-item-${index}-detail-grosspay-reason`}
                className="col-9 col-sm-8 pr-4"
                color="#212529"
                bold
              >
                {presentableDetailReason(grossPay.detailReason)}
              </DetailReason>
              <DetailAmount
                id={`payment-item-${index}-detail-grosspay-amount`}
                className="col-3 col-sm-4"
                style={{ marginLeft: 'auto' }}
                color="#212529"
              >
                ${grossPay.detailAmount}
              </DetailAmount>
            </div>
            <StyledLine />
            <Text className="col-12 pt-2 pb-2" align="left" color="#212529">
              <strong>ADJUSTMENTS</strong>
            </Text>
            {item.paymentDetails?.map(
              (detail: any, i: number) =>
                presentableDetailReason(detail.detailReason) !==
                  'GROSS PAY' && (
                  <div
                    id={`payment-item-${index}-detail-${i}`}
                    key={`payments-item-detail-${i}`}
                    className="d-flex flex-row pt-2 pb-2"
                  >
                    <DetailReason
                      id={`payment-item-${index}-detail-${i}-reason`}
                      className="col-9 col-sm-8 pr-4"
                      color="#212529"
                    >
                      {presentableDetailReason(detail.detailReason)}
                    </DetailReason>
                    <DetailAmount
                      id={`payment-item-${index}-detail-${i}-amount`}
                      className="col-3 col-sm-4"
                    >
                      ${detail.detailAmount}
                    </DetailAmount>
                  </div>
                ),
            )}
            <StyledLine />
            <div className="d-flex flex-row pt-2 pb-2">
              <DetailReason className="col-9 col-sm-8 pr-4" color="#212529">
                NET TOTAL
              </DetailReason>
              <DetailAmount
                style={{ marginLeft: 'auto' }}
                className="col-3 col-sm-4"
                color="#212529"
              >
                ${item.paymentAmount}
              </DetailAmount>
            </div>
          </>
        )}
        {!isCheckCutting && (
          <div className="d-flex flex-column pl-2 pr-3 mt-2">
            <InfoBox>
              <span
                className="k-icon k-i-info mr-2"
                style={{ color: '#015294' }}
              ></span>
              <i>
                Your employer will issue your payment(s). Please direct all
                questions regarding your benefit payments to your employer.
              </i>
            </InfoBox>
          </div>
        )}
      </div>
    </DetailsContainer>
  );
};

/**
 * MyItemRender - Renders custom items in the ListView component
 * @param props
 */
const ItemRender: (props: {
  dataItem: IPaymentAggregate;
  [name: string]: any;
}) => React.ReactElement = (props) => {
  const item = props.dataItem;
  const index = props.index;
  const [showDetails, setShowDetails] = React.useState(false);
  const [grossPay, setGrossPay] = React.useState<PaymentDetail>({
    detailReason: '',
    detailAmount: '',
    paymentDetailId: '',
  });
  const isCheckCutting = item.checkCutting === 'Check Cutting';
  const futurePayment = item.isFuturePayment || false;
  const detailArray = item.paymentDetails;

  useEffect(() => {
    detailArray?.forEach((d) => {
      if (
        d.detailReason &&
        presentableDetailReason(d.detailReason) === 'GROSS PAY'
      ) {
        setGrossPay(d);
      }
    });
  }, [detailArray]);

  return (
    <Container>
      <PaymentItem
        id={`payments-list-item-${index}`}
        key={`payments-list-item-${index}`}
        style={{ margin: 0 }}
        onClick={() => setShowDetails(!showDetails)}
      >
        <SummaryContainer
          expanded={showDetails}
          className="d-flex flex-column flex-md-row w-100 justify-content-between pl-2"
        >
          <div className="d-flex flex-column mr-2">
            <Text
              className="col-12 col-md-12"
              align="left"
              color={colors.sanmarino}
            >
              {getPaymentText(isCheckCutting, futurePayment)}{' '}
              {dayjs(item.paymentDate).format(dateFormat)}
            </Text>
            {getPaymentAmount(isCheckCutting, futurePayment, item)}
            <Text align="left" className="claimName col-12" id="paymentTtem">
              {item.claimName} ({item.caseId})
            </Text>
          </div>
          <StatusIcon future={futurePayment}>
            {futurePayment ? 'UPCOMING' : 'PAID'}
          </StatusIcon>
          <Arrow rotate={`${showDetails}`} />
        </SummaryContainer>

        {showDetails &&
          getDetailsContainer(
            isCheckCutting,
            futurePayment,
            item,
            index,
            grossPay,
          )}
      </PaymentItem>
    </Container>
  );
};

export default ItemRender;
