import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { colors } from '../../../style';
import { Container, LoadingCover } from '../../base/simple-styled-components';
import {
  DropDownListChangeEvent,
  ComboBoxChangeEvent,
} from '@progress/kendo-react-dropdowns';
import ClaimsDropDownList from '../../base/claims-drop-down-list';
import { PersonDataContext } from '../../../contexts/person-data-context';
import * as dm from '../../../data-models';
import dayjs from 'dayjs';
import FilterSection from '../filter-section';
import { TitleBarMod, Body, Text, BoldText } from '../index.sc';
import AbsenceCardSection from '../absence-list-view';
import { DEFAULT_LEAVE_OPTION } from '../defaults';
import AddAbsenceLink from '../add-absence-link';
import { analyticsTrackEvent, enums } from '../../../utils';
import {
  handleUseEffectInit,
  handleCalendarInit,
  absenceFilter,
} from '../handlers';
import FadeLoader from 'react-spinners/FadeLoader';
import useRefreshHandler from '../../../hooks/use-refresh-handler';
import Calendar from '../../generic-calendar';
import { calendarLegend } from './calendar-styles';

const trackLeaveSelection = (leave: any, v: boolean) =>
  analyticsTrackEvent(enums.AnalyticsEvents.absencesLeaveSelect, {
    ntn: leave,
    view: v ? 'Calendar' : 'List',
  });

const trackViewChange = (show: boolean) =>
  analyticsTrackEvent(
    show
      ? enums.AnalyticsEvents.absencesCalendar
      : enums.AnalyticsEvents.absencesList,
  );

/**
 * The Absence History Component. Renders the calendar and status cards.
 */
const AbsenceHistoryWrapper: () => React.ReactElement | null = () => {
  const { ntn } = useParams<{ ntn: string | undefined }>();
  const { personData } = useContext(PersonDataContext);
  const { isRefreshing } = useRefreshHandler([
    enums.RefreshDetailTypes.cases,
    enums.RefreshDetailTypes.caseDetails,
  ]);
  const [cases, setCases] = React.useState<Array<dm.Leave>>([]);
  const [allLeaves, setAllLeaves] = React.useState<dm.Leave[]>([]);
  const [selectedLeave, setSelectedLeave] = React.useState<dm.Leave>({});
  const [displayCalendarView, setDisplayCalendarView] = React.useState(true);
  const [selectedDate, setSelectedDate] = React.useState(dayjs());
  const [filterValue, setFilterValue] = React.useState({
    option: 'View All',
    fieldType: 'state',
    rules: [],
  });
  const [filteredElements, setFilteredElements] = React.useState<any>([]);
  const [rawElements, setRawElements] = React.useState<any>([]);
  const [filteredPeriods, setFilteredPeriods] = React.useState<any>([]);
  const [periods, setPeriods] = React.useState<any>([]);
  const [loading, setLoading] = React.useState<boolean>(true);
  const [initialized, setInitialized] = React.useState<boolean>(false);

  const filter = (choice: any) => {
    setFilterValue(choice);
    const { filteredElements, filteredPeriods } = absenceFilter(
      rawElements,
      periods,
      choice,
    );
    setFilteredElements(filteredElements);
    setFilteredPeriods(filteredPeriods);
  };

  React.useEffect(() => {
    handleUseEffectInit(
      personData,
      ntn,
      setCases,
      setSelectedLeave,
      setAllLeaves,
      setInitialized,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [personData, ntn]);

  React.useEffect(() => {
    if (!isRefreshing && initialized) {
      handleCalendarInit(
        personData,
        setLoading,
        setFilteredElements,
        setPeriods,
        setFilteredPeriods,
        setRawElements,
        selectedLeave?.parentId,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLeave, isRefreshing, initialized]);

  const filterByClaim = (e: DropDownListChangeEvent) => {
    const selected =
      e.target.value?.caseId === DEFAULT_LEAVE_OPTION ? {} : e.target.value;
    setSelectedLeave(selected);

    const newStartDate = dayjs();
    setSelectedDate(newStartDate);
    filter(filterValue);

    trackLeaveSelection(e.target.value?.caseId, displayCalendarView);
  };

  const onDateSelected = (date: any) => {
    setSelectedDate(date);
  };

  const showCalendarView = (show: boolean) => {
    setDisplayCalendarView(show);
    trackViewChange(show);
  };

  const handleFilterChange = (e: ComboBoxChangeEvent) => {
    const choice = e?.target?.value;
    setFilterValue(choice);
    filter(choice);
  };

  return allLeaves.length > 0 ? (
    <Container id="absence-history-container" style={{ maxWidth: 904 }}>
      <TitleBarMod>
        <ClaimsDropDownList
          caseList={cases}
          defaultOption={DEFAULT_LEAVE_OPTION}
          value={
            selectedLeave.leaveId
              ? selectedLeave
              : { caseId: DEFAULT_LEAVE_OPTION }
          }
          onChange={filterByClaim}
          processedProps
          maxWidth="100%"
          disabled={isRefreshing}
        />
      </TitleBarMod>
      <Body>
        <FilterSection
          handleFilterChange={handleFilterChange}
          showCalendarView={showCalendarView}
          displayCalendarView={displayCalendarView}
        />
        <div style={{ position: 'relative' }}>
          {selectedLeave.leaveId && (
            <Text className="absence-history-description">
              Below are all your reported absences for the leave period starting{' '}
              {dayjs(selectedLeave.startDate).format('MM/DD/YYYY')} and ending{' '}
              {dayjs(selectedLeave.endDate).format('MM/DD/YYYY')}.
            </Text>
          )}

          {displayCalendarView && (
            <>
              <Calendar
                onDateSelected={onDateSelected}
                legend={calendarLegend}
                periods={filteredPeriods}
                customDetailModal={() => <></>}
                elements={filteredElements}
                showLegend={true}
                showSelectedDate={true}
              />
              <div className="d-flex flex-row justify-content-between">
                <BoldText>{selectedDate.format('DD MMMM, YYYY')}</BoldText>
                <AddAbsenceLink
                  selectedDate={selectedDate}
                  selectedCaseId={selectedLeave?.leaveId ?? ''}
                />
              </div>
            </>
          )}
          <AbsenceCardSection
            legend={calendarLegend}
            elements={filteredElements}
            selectedDate={selectedDate}
            listView={!displayCalendarView}
          />
          {(loading || isRefreshing) && (
            <LoadingCover id="loading-cover">
              <FadeLoader
                height={14}
                width={5}
                radius={2}
                margin={0}
                color={colors.blue}
              />
            </LoadingCover>
          )}
        </div>
      </Body>
    </Container>
  ) : null;
};

export default AbsenceHistoryWrapper;
