import React from 'react';
import styled from 'styled-components';
import { colors, fonts } from '../../style';
import * as dm from '../../data-models';
import dayjs from 'dayjs';
import DetailView from './detail-view';
import { ButtonGroup, Icon, IconButton } from '../absence-history/index.sc';
import CalendarIcon from '../../images/calendar.svg';
import CalendarIconBlue from '../../images/calendar-blue.svg';
import InfoIcon from '../../images/info-circle-lg-gray.svg';
import InfoIconBlue from '../../images/info-circle-lg.svg';
import BluePen from '../../images/blue-pen.svg';
import { Popup } from '@progress/kendo-react-popup';
import { Link } from 'react-router-dom';
import { enums, analyticsTrackEvent } from '../../utils';
import { getLeavePlanData, getCalendarDetails } from '../../utils/calendars';
import Button from '../base/button';
import Calendar from '../generic-calendar';
import DetailModal from './detail-modal';
import { intakeStyle, continuousStyle } from './calendar-styles';
import LoadingIndicatorCard from './loading-indicator-card';
import GuideModal from './guide-modal';
import { PersonDataContext } from '../../contexts/person-data-context';
import isBetween from 'dayjs/plugin/isBetween';
import BlueInfoIcon from '../../images/info-icon.svg';

dayjs.extend(isBetween);

const dtFormat = 'MMM DD, YYYY';

interface LeavePlanProps {
  systemId?: string;
  keyId?: string;
  visualType: 'intake' | 'continuous';
  moveToStep?: Function;
  editConfig?: any;
  leaves?: dm.Leave[];
  claims?: dm.Claim[];
  calendarData?: dm.Calendar[];
}

const ButtonGroupMod = styled(ButtonGroup)`
  order: 2;
  margin: 0;

  @media (max-width: 1180px) {
    order: 3;
    max-width: none;
  }
`;

const Container = styled.div`
  background-color: ${colors.white};
  border-radius: 8px;
  box-shadow: 0px 3px 16px #00000029;
  padding: 32px;
  width: 100%;

  @media (max-width: 1279px) {
    padding: 25px 16px 16px;
  }
`;

const TitleBar = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 19px;
`;

const Header = styled.h1`
  color: ${colors.prussianblue};
  font-family: ${fonts.ubuntu.regular.fontFamily};
  font-size: 28px;
  font-weight: ${fonts.ubuntu.regular.fontWeight};
  line-height: 32px;
  margin: 0;
  white-space: nowrap;
  margin-right: auto;

  @media (max-width: 1180px) {
    font-size: 24px;
    margin-bottom: 24px;
  }

  @media (max-width: 330px) {
    margin-bottom: 15px;
  }
`;

const Text = styled.p`
  color: ${colors.warmNeutralL3};
  font-family: ${fonts.openSans.regular.fontFamily};
  font-size: 14px;
  font-weight: ${fonts.openSans.regular.fontWeight};
  line-height: 22px;
`;

const IconButtonSingle = styled(IconButton)`
  border-radius: 4px;
  max-width: 82px;
  margin-left: 16px;
  order: 3;

  @media (max-width: 1180px) {
    order: 2;
  }

  @media (max-width: 330px) {
    margin-left: 0;
    margin-bottom: 15px;
  }
`;

const HeaderContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: start;
  flex-direction: row;
  flex-wrap: nowrap;

  @media (max-width: 1180px) {
    flex-wrap: wrap;
  }
`;

const PopupContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 6px 16px;

  a {
    color: ${colors.denim};
  }
`;

const CalendarContainer = styled.div`
  position: relative;
`;

const getIcon = (displayCalendarView: boolean, isCalendarButton: boolean) => {
  if (isCalendarButton) {
    return displayCalendarView ? CalendarIcon : CalendarIconBlue;
  } else {
    return displayCalendarView ? InfoIconBlue : InfoIcon;
  }
};

const getPeriodInfo = (periods: any) => {
  if (periods.length > 1) {
    const earliest = dayjs.min(periods.map((p: any) => dayjs(p.startDate)));
    const latest = dayjs.max(periods.map((p: any) => dayjs(p.endDate)));
    return (
      <Text>
        You've requested multiple leave periods starting{' '}
        <strong>
          {dayjs(earliest).format('MMM DD')} until{' '}
          {dayjs(latest).format(dtFormat)}.
        </strong>
      </Text>
    );
  }

  return periods.length > 0 ? (
    <Text>
      You've requested leave for{' '}
      <strong>
        {dayjs(periods[0].endDate).diff(dayjs(periods[0].startDate), 'day')}{' '}
        days,
      </strong>{' '}
      from{' '}
      <strong>
        {dayjs(periods[0].startDate).format('MMM DD')} until{' '}
        {dayjs(periods[0].endDate).format(dtFormat)}.
      </strong>
    </Text>
  ) : null;
};

interface IHandleLeavePlanInit {
  props: LeavePlanProps;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setDays: React.Dispatch<React.SetStateAction<any>>;
  setElements: React.Dispatch<React.SetStateAction<any>>;
  setPeriods: React.Dispatch<React.SetStateAction<any>>;
  setBenefits: React.Dispatch<React.SetStateAction<any>>;
  setInitialCalendarDate: React.Dispatch<React.SetStateAction<string>>;
  leaves?: dm.Leave[];
}

const getOverallLeavePeriodForNTN = (leaves?: dm.Leave[]) => {
  let startDate = undefined;
  let endDate = undefined;
  if (leaves && leaves.length > 0) {
    startDate = dayjs
      .min(leaves.map((l) => dayjs(l.startDate)))
      ?.toISOString?.();
    endDate = dayjs.max(leaves.map((l) => dayjs(l.endDate)))?.toISOString?.();
  }
  return { startDate, endDate };
};

const handleLeavePlanInit = async ({
  props,
  setLoading,
  setDays,
  setElements,
  setPeriods,
  setBenefits,
  setInitialCalendarDate,
  leaves,
}: IHandleLeavePlanInit) => {
  const { startDate, endDate } = getOverallLeavePeriodForNTN(props?.leaves);
  const calendarData = props.calendarData
    ? props.calendarData
    : await getCalendarDetails(
        startDate,
        endDate,
        leaves,
        props.systemId,
        props.visualType === 'intake',
      );

  if (!dayjs().isBetween(dayjs(startDate), dayjs(endDate), 'd', '[]')) {
    setInitialCalendarDate(dayjs(startDate).format('YYYY-MM-DD'));
  }

  const result = getLeavePlanData(
    calendarData,
    props.visualType,
    props.systemId,
  );

  setElements(result.elements);
  setPeriods(result.nonOverlappingPeriods);
  setBenefits(result.benefits);
  setDays(result.dayDetails);

  setLoading(false);
};

const LeavePlan: (p: LeavePlanProps) => React.ReactElement = (p) => {
  const appPaths = enums.AppPaths;
  const [displayCalendar, setDisplayCalendar] = React.useState(true);
  const [displayEdit, setDisplayEdit] = React.useState(false);
  const [days, setDays] = React.useState<any>([]);
  const [elements, setElements] = React.useState<any>([]);
  const [periods, setPeriods] = React.useState<any>([]);
  const [benefits, setBenefits] = React.useState<any>([]);
  const [loading, setLoading] = React.useState(true);
  const [initialCalendarDate, setInitialCalendarDate] = React.useState(
    dayjs().format('YYYY-MM-DD'),
  );
  const anchor = React.useRef<HTMLButtonElement | null>(null);
  const { personData } = React.useContext(PersonDataContext);

  const showCalendarView = (show: boolean) => {
    setDisplayCalendar(show);
  };

  const showEditPopup = (e: any) => {
    e.preventDefault();
    setDisplayEdit(!displayEdit);
    analyticsTrackEvent(enums.AnalyticsEvents.lpvEditPressed);
  };

  const edit = (index: number, elementName: string, linkText: string) => {
    p?.moveToStep?.({ value: index }, elementName);
    analyticsTrackEvent(enums.AnalyticsEvents.lpvEditOptionPressed, {
      optionText: linkText,
    });
  };

  React.useEffect(() => {
    handleLeavePlanInit({
      props: p,
      setLoading,
      setDays,
      setElements,
      setPeriods,
      setBenefits,
      setInitialCalendarDate,
      leaves: personData?.cases?.leaves,
    });
  }, [p, personData]);

  const editConfig = p?.editConfig ?? {};

  const changeToCalendarView = () => {
    showCalendarView(true);
    analyticsTrackEvent(enums.AnalyticsEvents.lpvCalendarView);
  };

  const changeToDetailView = () => {
    showCalendarView(false);
    analyticsTrackEvent(enums.AnalyticsEvents.lpvDetailsView);
  };

  const legend = p.visualType === 'intake' ? intakeStyle : continuousStyle;

  if (loading) {
    return <LoadingIndicatorCard />;
  }

  if (
    p.visualType === 'intake' &&
    benefits?.jobProtectionBenefits?.length === 0 &&
    benefits?.incomeProtectionBenefits?.length === 0
  ) {
    return (
      <div className="info-bubble">
        <p>
          <strong>Your leave plan was built successfully!</strong>
        </p>
        <p>Please click "Next" to proceed.</p>
      </div>
    );
  }

  return (
    <Container id="leave-plan-container">
      <TitleBar>
        <HeaderContainer>
          <Header>
            {p.visualType === 'intake' ? 'Leave Estimate' : 'Your Leave'}
          </Header>
          <ButtonGroupMod>
            <IconButton
              id="lpv-calendar-view-button"
              onClick={changeToCalendarView}
              disabled={displayCalendar}
              style={{ maxWidth: 'none' }}
            >
              <Icon
                src={getIcon(displayCalendar, true)}
                alt=""
                height="20px"
                width="18px"
              />{' '}
              Calendar
            </IconButton>
            <IconButton
              id="lpv-details-view-button"
              onClick={changeToDetailView}
              disabled={!displayCalendar}
              style={{ maxWidth: 'none' }}
              left
            >
              <Icon
                src={getIcon(displayCalendar, false)}
                alt=""
                height="18px"
                width="24px"
              />{' '}
              Details
            </IconButton>
          </ButtonGroupMod>
          {editConfig?.allowEdit === 'true' && (
            <IconButtonSingle
              id="lpv-edit-button"
              onClick={showEditPopup}
              ref={anchor}
            >
              <Icon src={BluePen} alt="" height="16px" width="16px" /> Edit
            </IconButtonSingle>
          )}
        </HeaderContainer>
      </TitleBar>
      {getPeriodInfo(periods)}
      {displayCalendar ? (
        <CalendarContainer>
          <Calendar
            {...p}
            legend={legend}
            dates={days}
            defaultSelectedDate={initialCalendarDate}
            customDetailModal={(args: any) =>
              DetailModal({
                ...args,
                legend,
              })
            }
            periods={periods}
            elements={elements}
            showLegend={true}
            hideUnusedStyles={true}
          />
          <GuideModal
            legend={legend}
            hideUnusedStyles={true}
            stylesVisibleOnCalendar={[...elements, ...periods]}
          />
        </CalendarContainer>
      ) : (
        <DetailView
          benefits={benefits}
          visualType={p.visualType}
          leaves={p.leaves}
          claims={p.claims}
          legend={legend}
        />
      )}
      <EditPopover
        anchor={anchor}
        editConfig={editConfig}
        edit={edit}
        displayEdit={displayEdit}
        visualType={p.visualType}
      />
      <div className="tw-bg-[#D9EBF4] tw-rounded-md tw-p-3">
        <img src={BlueInfoIcon} alt="info icon" />
        <span className="tw-text-unm-adhoc2 tw-ml-2">
          If you want to know more about your leave entitlement, you can{' '}
          <Link to={appPaths.time} className="tw-font-bold tw-no-underline">
            check it here.
          </Link>
        </span>
      </div>
    </Container>
  );
};

interface IEditPopover {
  anchor: React.MutableRefObject<HTMLButtonElement | null>;
  editConfig: any;
  edit: (index: number, elementName: string, linkText: string) => void;
  displayEdit: boolean;
  visualType: string;
}

const EditPopover: (p: IEditPopover) => React.ReactElement = (p) => {
  const { anchor, editConfig, edit, displayEdit, visualType } = p;
  return (
    <Popup
      id="lpv-edit-popover"
      anchor={anchor.current}
      anchorAlign={{
        horizontal: 'right',
        vertical: 'bottom',
      }}
      popupAlign={{
        horizontal: 'right',
        vertical: 'top',
      }}
      show={displayEdit}
      popupClass={'popup-content'}
      margin={{ vertical: 5, horizontal: 0 }}
      style={{
        boxShadow: '0px 3px 6px #00000029',
        borderRadius: '8px',
      }}
    >
      {visualType === 'intake' ? (
        <PopupContent id="lpv-intake-popover-content">
          {editConfig?.editLinks?.map(
            (
              option: {
                linkText: string;
                formStepIndex: number;
                formFieldName: string;
              },
              i: number,
            ) => (
              <Button
                key={`edit-option-${i}`}
                id={`lpv-edit-option-${i}`}
                buttonStyle={enums.ButtonTypes.link}
                style={{ textAlign: 'left' }}
                onClick={() =>
                  edit(
                    option.formStepIndex,
                    option.formFieldName,
                    option.linkText,
                  )
                }
              >
                {option.linkText}
              </Button>
            ),
          )}
        </PopupContent>
      ) : (
        <PopupContent id="lpv-ocm-popover-content">
          <Link to={`${enums.AppPaths.form}/last-day-worked`}>
            Revise your last day worked
          </Link>
          <Link to={`${enums.AppPaths.form}/return-to-work`}>
            Set a new return to work date
          </Link>
          <Link to={`${enums.AppPaths.form}/delivery-details`}>
            Move your delivery date
          </Link>
        </PopupContent>
      )}
    </Popup>
  );
};

export default LeavePlan;
