import { useQuery } from '@tanstack/react-query';
import React from 'react';
import { PersonDataContext } from '../../../contexts/person-data-context';
import { Spinner } from '../../base/spinner';
import * as dm from '../../../data-models';
import { supplemental } from '../../../utils';
import Summary from '..';
import {
  useAbsenceNotifications,
  useRemoteConfig,
  useVBCoverage,
  useTasksGet,
} from '../../../hooks/use-data';
import { getTasksWithKontent } from '../../tasks/container/api';

interface SummaryProps {
  failedWithData?: boolean;
}

export const SummaryContainer = (p: SummaryProps) => {
  const { personData } = React.useContext(PersonDataContext);

  const { data: absenceNotifications } = useAbsenceNotifications();

  const { data: allTasks } = useTasksGet(personData?.person?.customerNumber);

  const { data: tasksResponse, isLoading: tasksLoading } = useQuery(
    ['getTasksWithKontent', personData?.person?.customerNumber],
    () => getTasksWithKontent(personData!.person!.customerNumber!),
  );

  const { data: employerEnabled, isLoading: suppLoading } = useQuery(
    ['supplemental.isEmployerEnabled', personData?.person?.companyCode],
    () => supplemental.isEmployerEnabled(personData?.person?.companyCode),
    {
      enabled: Boolean(personData?.person?.companyCode),
    },
  );

  const { data: vbCoverageData, isLoading: vbLoading } = useVBCoverage();
  const emptyVb: dm.ICoverageV1ResponseExt = {
    hasCoverage: false,
    firstName: '',
    middleName: '',
    lastName: '',
    dateOfBirth: '',
    coverages: [],
  };

  const { data: remoteConfig, isLoading: remoteLoading } = useRemoteConfig();
  const emptyCfg: {
    [name: string]: string;
  } = {};

  if (tasksLoading || suppLoading || vbLoading || remoteLoading) {
    return <Spinner />;
  }

  return (
    <Summary
      tasks={tasksResponse ?? []}
      remoteCfg={remoteConfig ?? emptyCfg}
      isEmployerEnabled={employerEnabled ?? false}
      vbCoverageData={vbCoverageData ?? emptyVb}
      failedWithData={p.failedWithData}
      absenceNotifications={absenceNotifications}
      allTasks={allTasks}
    />
  );
};
