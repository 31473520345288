import React from 'react';
import { get } from '../../../../../utils';
import {
  getAndMapNextPaymentFromClaims,
  getPaymentsFromClaims,
  IPaymentAggregate,
  sortDescending,
} from '../../../../payments';
import { ListView } from '@progress/kendo-react-listview';
import dayjs from 'dayjs';
import { TabCardsNonOverview } from '../tab-cards';
import { CardItemContainer } from '../../components/simple-components';
import { colors } from '../../../../../style';
import { PersonDataContext } from '../../../../../contexts/person-data-context';

const listViewStyling: any = {
  border: 0,
  position: 'initial',
  paddingTop: '0px',
  paddingRight: '0px',
  paddingBottom: '16px',
  paddingLeft: '0px',
  backgroundColor: colors.alabaster1,
};

const filterByParentNTN = (claimList: Array<any>, NTN: string) => {
  return claimList.filter((claim: any) => {
    if (claim.caseId) {
      return claim.caseId.includes(NTN);
    }
    if (claim.leaveId) {
      return claim.leaveId.includes(NTN);
    }
    if (claim.claimId) {
      return claim.claimId.includes(NTN);
    }
    return false;
  });
};

const getPaymentAmount = (
  isCheckCutting: boolean,
  futurePayment: boolean,
  item: any,
) => {
  return (
    <p className="tw-text-lg tw-m-0">
      {!isCheckCutting
        ? 'Payment to be made by Employer'
        : futurePayment
        ? `Estimated Payment of $${item.paymentAmount}`
        : `Payment of $${item.paymentAmount}`}
    </p>
  );
};

export const isEmptyObject = (obj: any) => {
  for (const _p in obj) {
    return false;
  }
  return true;
};

const getPaymentText = (isCheckCutting: boolean, future: boolean) => {
  if (future && !isCheckCutting) {
    return 'Employer to be notified';
  } else if (!future && !isCheckCutting) {
    return 'Employer notified';
  } else if (!future && isCheckCutting) {
    return 'Issued';
  } else {
    return 'Scheduled for';
  }
};

interface PaymentsListProps {
  paymentData: IPaymentAggregate[];
}

const PaymentsList = (props: PaymentsListProps) => {
  if (props.paymentData.length > 0) {
    return (
      <ListView
        data={props.paymentData}
        item={ItemRender}
        style={listViewStyling}
      ></ListView>
    );
  } else {
    return <div></div>;
  }
};

interface PaymentStatusIconProps {
  future: boolean;
}

const PaymentStatusIcon = (props: PaymentStatusIconProps) => {
  const futureLabelColor = '#FFF8E5';
  const futureTextColor = '#F1C44F';
  const pastLabelColor = '#ECF9E6';
  const pastTextColor = colors.apple;
  return (
    <div
      className="tw-rounded-lg tw-px-4 tw-py-2 tw-align-middle tw-inline-block tw-h-11"
      style={{
        backgroundColor: props.future ? futureLabelColor : pastLabelColor,
      }}
    >
      <p
        className="tw-align-middle tw-m-0 tw-text-lg tw-font-normal"
        style={{ color: props.future ? futureTextColor : pastTextColor }}
      >
        {props.future ? 'UPCOMING' : 'PAID'}
      </p>
    </div>
  );
};

/**
 * MyItemRender - Renders custom items in the ListView component
 * @param props
 */
const ItemRender: (props: {
  dataItem: IPaymentAggregate;
  [name: string]: any;
}) => React.ReactElement = (props) => {
  const item = props.dataItem;
  const index = props.index;
  const isCheckCutting = item.checkCutting === 'Check Cutting';
  const futurePayment = item.isFuturePayment || false;
  // replace all the styledcomponents stuff with generic html tags styled with tailwind
  return (
    <CardItemContainer>
      <div
        id={`payments-list-item-${index}`}
        key={`payments-list-item-${index}`}
        className="tw-flex w-100 justify-content-between"
      >
        <div className="d-flex flex-column mr-2 tw-w-full tw-space-y-2 tw-content-between">
          <div className="tw-text-base tw-font-normal tw-text-unm-interactive01-400 tw-m-0">
            {getPaymentText(isCheckCutting, futurePayment)}{' '}
            {dayjs(item.paymentDate).format('MM/DD/YYYY')}
          </div>
          <div className="tw-font-semibold tw-m-0">
            {getPaymentAmount(isCheckCutting, futurePayment, item)}
          </div>
          <div
            id="paymentItem"
            className="tw-text-sm tw-m-0 tw-text-[#929292] tw-font-normal"
          >
            {item.claimName} ({item.caseId})
          </div>
        </div>
        <div className="tw-flex tw-items-center">
          <PaymentStatusIcon future={futurePayment}></PaymentStatusIcon>
        </div>
      </div>
    </CardItemContainer>
  );
};

const setSections = (
  nextPaymentsList: IPaymentAggregate[],
  pastPaymentsList: IPaymentAggregate[],
  parentNTN: string,
): any => {
  const mappingObject: any = {};
  if (nextPaymentsList.length > 0) {
    mappingObject.upcomingPayments = {
      title: `Upcoming payments`,
      headerLinkName: 'Go to all payments',
      headerLink: `/app/payments/${parentNTN}`,
      popupContent:
        'Payments scheduled to be made to you. Please refer to your approval letter for additional payment details.',
      component: (
        <PaymentsList paymentData={nextPaymentsList.slice(0, 2)}></PaymentsList>
      ),
    };
  }

  if (pastPaymentsList.length > 0) {
    mappingObject.issuedPayments = {
      title: `Issued payments`,
      headerLinkName: 'Go to all payments',
      headerLink: `/app/payments/${parentNTN}`,
      popupContent:
        'Payments issued to you by Unum or your employer. Please refer to your approval letter for additional payment details.',
      component: (
        <PaymentsList paymentData={pastPaymentsList.slice(0, 2)}></PaymentsList>
      ),
    };
  }
  return mappingObject;
};

type PaymentTabProps = {
  parentNTNId: string;
  failedWithData: boolean | undefined;
};

export const PaymentsTab = ({
  parentNTNId,
  failedWithData,
}: PaymentTabProps) => {
  const { personData } = React.useContext(PersonDataContext);
  const [pastPaymentsList, setPastPaymentsList] = React.useState<
    Array<IPaymentAggregate>
  >([]);
  const [nextPaymentsList, setNextPaymentsList] = React.useState<
    Array<IPaymentAggregate>
  >([]);
  const [sectionMapping, setSectionMapping] = React.useState<any>({});

  React.useEffect(() => {
    const caseList = get(personData, 'cases.claims', []).concat(
      get(personData, 'cases.leaves', []),
    );

    if (caseList && caseList.length > 0) {
      const filteredCases = filterByParentNTN(caseList, parentNTNId);
      const pastPayments = getPaymentsFromClaims(filteredCases);
      const nextPayments = failedWithData
        ? []
        : getAndMapNextPaymentFromClaims(filteredCases);

      pastPayments.sort(sortDescending);
      nextPayments.sort(sortDescending);

      setPastPaymentsList(pastPayments);
      setNextPaymentsList(nextPayments);
    }
  }, [parentNTNId, failedWithData, personData]);

  React.useEffect(() => {
    setSectionMapping(
      setSections(nextPaymentsList, pastPaymentsList, parentNTNId),
    );
  }, [nextPaymentsList, parentNTNId, pastPaymentsList]);

  return <TabCardsNonOverview sectionMapping={sectionMapping} />;
};
