import React from 'react';
import styled from 'styled-components';
import { colors, fonts, rem } from '../../../style';
import Chevron from '../../../images/chevron-down-lg.png';
import { Button as KendoButton } from '@progress/kendo-react-buttons';
import { Reveal } from '@progress/kendo-react-animation';
import { getHtmlFromMarkdown } from '../../../utils';

interface AccordionCardProps {
  idPrefix?: string;
  icon?: string;
  titleString?: string;
  titleElement?: React.ReactElement;
  statusElement?: React.ReactElement;
  expandedElement?: React.ReactElement;
  expandedMarkdown?: string;
  index: number;
  onExpandCallback?: () => void;
  footerElement?: React.ReactElement;
}

interface TextProps {
  complete?: boolean;
}

interface ChevronIconProps {
  $expanded: boolean;
}

const Container = styled.div`
  margin-bottom: 16px;

  .k-animation-container {
    z-index: 1 !important;
  }
`;

const Card = styled.div`
  align-items: center;
  background-color: ${colors.white};
  border: 1px solid ${colors.alto2};
  border-radius: 8px;
  justify-content: space-between;
  padding: 21px 28px 19px 26px;
  position: relative;
  z-index: 2;

  @media (max-width: 576px) {
    padding: 25px 21px 31px 24px;
  }
`;

const Description = styled.div<TextProps>`
  color: ${colors.dovegray1};
  font-family: ${fonts.openSans.regular.fontFamily};
  font-size: ${rem(16)};
  font-weight: ${fonts.openSans.regular.fontWeight};
  width: 100%;

  > div > p:first-child {
    margin-top: 0;
  }

  > div > p:last-child {
    margin-bottom: 0;
  }
`;

const Title = styled.div`
  color: ${colors.prussianblue};
  font-family: ${fonts.ubuntu.regular.fontFamily};
  font-size: ${rem(20)};
  font-weight: ${fonts.ubuntu.regular.fontWeight};
`;

const ExpandedSection = styled.div<{ hasFooter?: boolean }>`
  background-color: ${colors.alabaster1};
  border: 1px solid ${colors.alto2};
  border-radius: ${(p) => (p.hasFooter ? 0 : '0 0 8px 8px')};
  display: flex;
  justify-content: start;
  margin-top: -5px;
  padding: 25px;
  position: relative;
  z-index: 1;

  @media (max-width: 576px) {
    padding: 20px 24px 26px 24px;
  }
`;

export const ChevronContainer = styled.div`
  align-self: center;
  width: 42px;

  @media (max-width: 576px) {
    align-self: start;
  }
`;

const StatusContainer = styled.div`
  align-self: center;
  margin-right: 32px;

  @media (max-width: 576px) {
    align-self: end;
    margin-top: 15px;
  }
`;

const MainTextContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  @media (max-width: 576px) {
    align-items: start;
  }
`;

const IconContainer = styled.div`
  margin-right: 8px;

  @media (max-width: 576px) {
    margin-bottom: 14px;
  }
`;

export const ChevronIcon = styled(KendoButton)<ChevronIconProps>`
  transform: ${(p) => (p.$expanded ? 'rotate(180deg)' : 'none')};
`;

const AccordionCard: (p: AccordionCardProps) => React.ReactElement = (p) => {
  const {
    icon,
    titleString,
    titleElement,
    statusElement,
    expandedElement,
    expandedMarkdown,
    index,
    footerElement,
  } = p;
  const idPrefix = p.idPrefix || 'accordion';
  const [expanded, setExpanded] = React.useState(index === 0);

  React.useEffect(() => {
    setExpanded(index === 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [p]);

  const handleCardClick = (e: any) => {
    e.preventDefault();
    setExpanded(!expanded);
    p?.onExpandCallback?.();
  };

  return (
    <Container id={`${idPrefix}-card-${index}`}>
      <Card
        onClick={handleCardClick}
        className="d-flex flex-row accordion-card-container"
      >
        <div className="d-flex flex-column flex-md-row w-100 justify-content-between">
          <MainTextContainer>
            {icon && (
              <IconContainer>
                <img src={icon} alt="" />
              </IconContainer>
            )}
            {titleString && (
              <Title className="d-flex flex-column mr-2">{titleString}</Title>
            )}
            {titleElement}
          </MainTextContainer>
          {statusElement && (
            <div className="d-flex mt-2">
              <StatusContainer>{statusElement}</StatusContainer>
            </div>
          )}
        </div>
        <ChevronContainer>
          <ChevronIcon
            id={`${idPrefix}-card-expand-button-${index}`}
            imageUrl={Chevron}
            onClick={handleCardClick}
            look="flat"
            $expanded={expanded}
          />
        </ChevronContainer>
      </Card>
      <Reveal style={{ display: 'block' }}>
        {expanded && (
          <>
            <ExpandedSection hasFooter={!!p.footerElement}>
              <Description id={`${idPrefix}-card-description-${index}`}>
                {expandedElement}
                {expandedMarkdown && (
                  <div
                    dangerouslySetInnerHTML={getHtmlFromMarkdown(
                      expandedMarkdown,
                    )}
                  />
                )}
              </Description>
            </ExpandedSection>
            {footerElement}
          </>
        )}
      </Reveal>
    </Container>
  );
};

export default AccordionCard;
