import React from 'react';
import { useParams } from 'react-router';
import styled from 'styled-components';
import { LoadingCover, ActionLink } from '../../base/simple-styled-components';
import { colors, fonts } from '../../../style';
import { TaskType } from '../index';
import TaskDescription from './task-description';
import TaskHelp from './task-help';
import { PersonDataContext } from '../../../contexts/person-data-context';
import { getSimpleRemoteConfig } from '../../../utils/remote-config-manager';
import { getReferenceValues } from '../../../utils/cms-client';
import { Form } from 'dvt-phoenix-tasks-sdk';
import { PersonData, FormData } from '../../../data-models';
import * as enums from '../../../utils/enums';
import envConfig from '../../../utils/env-config';
import HelpIcon from '../../../images/question-circle.svg';
import LeftArrow from '../../../images/left-arrow-blue.svg';
import { retrieveTask } from '../utils';
import { getTaskFormDownloadUrl } from '../../../utils/web-apis-client';
import { useQueryClient } from '@tanstack/react-query';
import { FadeLoader } from 'react-spinners';

const submittedViewStatuses = [5, 7, 8, 9];

export interface TaskDetailParamsProps {
  taskKey?: string;
}

const Title = styled.div`
  color: ${colors.prussianblue};
  font-family: ${fonts.ubuntu.regular.fontFamily};
  font-size: 36px;
  font-weight: ${fonts.ubuntu.regular.fontWeight};
  margin-bottom: 24px;
`;

const SubTitle = styled.div`
  color: ${colors.prussianblue};
  font-family: ${fonts.ubuntu.regular.fontFamily};
  font-size: 28px;
  font-weight: ${fonts.ubuntu.regular.fontWeight};
`;

const TitleBar = styled.div`
  margin-bottom: 16px;
  display: flex;
`;

const BackButton = styled.div`
  margin-bottom: 24px;
`;

const HelpButton = styled.button`
  background: #ffffff;
  border: 1px solid #dddddd;
  border-radius: 4px;
  margin-left: auto;
  padding: 8px 16px;
  display: flex;
  align-items: center;
`;

const HelpText = styled.span`
  font-family: ${fonts.openSans.semibold.fontFamily};
  font-size: 16px;
  font-weight: ${fonts.openSans.semibold.fontWeight};
  line-height: 16px;
  margin-left: 8px;
  color: ${colors.skyD2};
`;

const Container = styled.div`
  background: #ffffff;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  padding: 32px;
  margin-bottom: 16px;
`;

const ActionLinkMod = styled(ActionLink)`
  display: flex;
  align-items: center;
  text-decoration: none;
  width: fit-content;
  margin: 0;
`;

const helpScroll = () => {
  const section = document.querySelector('#help-root');
  section?.scrollIntoView({ behavior: 'smooth', block: 'start' });
};

const createFormConfig = async (
  task: TaskType | undefined,
  setFormConfig: Function,
  rc: any,
  refreshTask: Function,
  personData?: PersonData,
  formUrl?: string,
) => {
  let formItems = {};
  if (task && task.formData.length > 0) {
    sortFormsByDateDesc(task?.formData);
    formItems = task?.formData[0];
  }

  if (personData && rc && task) {
    const config = {
      customerNumber: personData.person?.customerNumber,
      companyCode: personData.person?.companyCode,
      firstName: personData.person?.firstName,
      lastName: personData.person?.lastName,
      environment: envConfig.upEnvironment,
      notificationCaseId: task.notificationCaseId,
      taskId: task.taskId,
      taskCodename: task.taskCodeName,
      cases: task.caseInfo,
      formItems,
      showSubmittedView: submittedViewStatuses.includes(task.taskStatusId),
      useKenticoPreviewMode: rc[enums.CodeNames.refTasksPreviewFlag] === 'true',
      downloadUrl: formUrl,
      onSubmit: refreshTask,
      rawDynamicFields: task.dynamicFields,
      dualTask: task.dualTask,
      apiConfig: {
        authType: 'iam',
        invokeUrl: rc[enums.RemoteConfigKeys.absenceApiInvokeUrl],
        customHeaders: {
          userId: `${personData.person?.firstName} ${personData.person?.lastName}`,
          assigneeId: task?.assigneeId,
          portalSource: 'Employee Portal',
        },
        uploadUrlsPath: `/ab/employee-tasks/${task.taskId}/upload-urls`,
        submitFormPath: `/ab/employee-tasks/${task.taskId}/form-data`,
      },
    };

    setFormConfig(config);
  }
};

const getRemoteConfig = async (setRemoteConfig: Function) => {
  const refItems = await getReferenceValues();
  const rc: any = await getSimpleRemoteConfig();
  setRemoteConfig({ ...rc, ...refItems });
};

const getDownloadUrl = async (
  setFormDownloadUrl: Function,
  taskId?: string,
) => {
  let taskDocs;
  if (taskId) {
    taskDocs = await getTaskFormDownloadUrl(taskId);
  }

  if (taskDocs && taskDocs.length > 0) {
    setFormDownloadUrl(taskDocs[0].url);
  }
};

const sortFormsByDateDesc = (formData: FormData[]) => {
  formData.sort(function (a, b) {
    return (
      new Date(b.modifiedDate).getTime() - new Date(a.modifiedDate).getTime()
    );
  });
};

const TaskDetail: () => React.ReactElement = () => {
  const [loading, setLoading] = React.useState<boolean>(true);
  const { personData } = React.useContext(PersonDataContext);
  const [formConfig, setFormConfig] = React.useState<any>();
  const [remoteConfig, setRemoteConfig] = React.useState<any>();
  const [task, setTask] = React.useState<TaskType>();
  const [isLlTask, setIsLlTask] = React.useState<boolean>(false);
  const [formDownloadUrl, setFormDownloadUrl] = React.useState<string>('');
  const params = useParams<TaskDetailParamsProps>();

  const queryClient = useQueryClient();
  const refreshTask = React.useCallback(() => {
    retrieveTask(setTask, setIsLlTask, task?.taskId.toString());

    // invalidate react-query cache
    queryClient.invalidateQueries({
      queryKey: ['getTasksWithKontent', personData?.person?.customerNumber],
    });
  }, [personData?.person?.customerNumber, queryClient, task?.taskId]);

  React.useEffect(() => {
    getRemoteConfig(setRemoteConfig);
    retrieveTask(setTask, setIsLlTask, params.taskKey);
    getDownloadUrl(setFormDownloadUrl, params.taskKey);
  }, [params.taskKey]);

  React.useEffect(() => {
    const configureForm = async () => {
      setLoading(true);
      await createFormConfig(
        task,
        setFormConfig,
        remoteConfig,
        refreshTask,
        personData,
        formDownloadUrl,
      );
      setLoading(false);
    };

    configureForm();
  }, [task, personData, remoteConfig, refreshTask, isLlTask, formDownloadUrl]);

  return (
    <>
      {loading ||
        (!task && (
          <>
            <LoadingCover>
              {' '}
              <FadeLoader
                height={14}
                width={5}
                radius={2}
                margin={0}
                color={colors.blue}
              />
            </LoadingCover>
          </>
        ))}
      <div className="col-12 col-lg-9 p-0">
        <BackButton>
          <ActionLinkMod className="d-flex" to="/app/tasks">
            <img src={LeftArrow} alt="Back" />
            <span style={{ marginLeft: '10px' }}>Tasks</span>
          </ActionLinkMod>
        </BackButton>
        <Title role="title">{task?.taskTitle}</Title>
        {task && (
          <TaskDescription
            task={task}
            refresh={refreshTask}
            isLlTask={isLlTask}
          ></TaskDescription>
        )}
        {formConfig && task?.formCodename && !isLlTask && (
          <Container>
            <TitleBar>
              <SubTitle role="title">What you need to do</SubTitle>
              {task.frequentlyAskedQuestions &&
                task.frequentlyAskedQuestions.length > 0 && (
                  <HelpButton onClick={helpScroll}>
                    <img height="24" width="24" src={HelpIcon} alt="Help" />
                    <HelpText>Help</HelpText>
                  </HelpButton>
                )}
            </TitleBar>
            <Form {...formConfig} />
          </Container>
        )}

        {task?.frequentlyAskedQuestions &&
          task?.frequentlyAskedQuestions.length > 0 && <TaskHelp {...task} />}
      </div>
    </>
  );
};

export default TaskDetail;
