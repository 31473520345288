import React from 'react';
import styled from 'styled-components';
import { get, analyticsTrackEvent, enums } from '../../../../../utils';
import { KenticoDataContext } from '../../../../../contexts/kentico-data-context';
import { CustomLink, CardButton } from '../../../simple-styled-components';

const CardFooter = styled.div`
  align-items: baseline;
  display: flex;
  height: 100%;
  justify-content: flex-end;
  padding: 1.5em;
  width: 100%;
`;

const CardFooterSection = (p: any) => {
  const { featureFlags } = React.useContext(KenticoDataContext);
  const leaveId = get(p, 'leaves[0].caseId', '');

  // Don't show footer for ACM only cases. Must have a leave or claim.
  if (get(p, 'leaves', []).length === 0 && get(p, 'claims', []).length === 0) {
    return null;
  }

  return (
    <>
      {featureFlags.absence_history &&
      get(p, 'leaves[0].absenceType', null) === 'Intermittent' ? (
        <CardFooter>
          <CustomLink
            to={`/app/absence-history/${p.NTNId}`}
            onClick={() =>
              analyticsTrackEvent(enums.AnalyticsEvents.summaryAbsences, {
                ntn: leaveId,
              })
            }
          >
            <CardButton>View Calendar</CardButton>
          </CustomLink>
        </CardFooter>
      ) : (
        // hasDraftClaim && (
        <CardFooter>
          <CustomLink
            to={`/app/leave-plan/${p.NTNId}`}
            onClick={() =>
              analyticsTrackEvent(enums.AnalyticsEvents.summaryLeavePlan, {
                ntn: p.NTNId,
              })
            }
          >
            <CardButton>View Calendar</CardButton>
          </CustomLink>
        </CardFooter>
        // )
      )}
    </>
  );
};

export default CardFooterSection;
