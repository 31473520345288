import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import ClaimStatusInfo from '../../../../../images/claimStatusInfoIcon.svg';
import penSolid from '../../../../../images/pen-solid.svg';
import {
  get,
  enums,
  translateLeaveStatuses,
  getStatusIcon,
} from '../../../../../utils';
import { EmployerPreferences, Leave, Task } from '../../../../../data-models';
import dayjs from 'dayjs';
import { fonts, colors } from '../../../../../style';
import { Item, Items, dateFormat, StatusFlag } from './shared-styles';
import { Popup } from '@progress/kendo-react-popup';
import { PreferencesContext } from '../../../../../contexts/preferences-context';
import { Popover } from '@progress/kendo-react-tooltip';
import { getClaimStatusConfig } from '../../../../../utils/remote-config-manager';

const pregReason = 'Pregnancy/Maternity';
const bondingReason = 'Child Bonding';

const UpdateButton = styled.button`
  background: #fcfcfc 0% 0% no-repeat padding-box;
  border: 1px solid #dddddd;
  border-radius: 4px;
  margin-left: 8px;
`;

const PopupLink = styled(Link)`
  display: flex;
  margin: 4px;
  flex-direction: column;
  background-color: ${colors.white};
  border: none;
  border-radius: 8px;
  text-decoration: none;
  font-size: 14px;
  font-family: ${fonts.openSans.semibold.fontFamily};
  font-weight: ${fonts.openSans.semibold.fontWeight};
  color: ${colors.sanmarino};
  :hover {
    text-decoration: none;
  }
`;

const PopupContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 6px 16px;

  a {
    color: ${colors.denim};
  }
`;

const Separator = styled.div`
  border: 1px solid #dddddd;
  margin-bottom: 10px;
  margin-top: 10px;
`;

const ClaimStatusInfoIcon = styled.button`
  background: #fff;
  border: 0px;
  margin-left: 6px;
  bottom: 1px;
`;

const LeaveCardItems: (props: {
  leaves: Array<Leave>;
  ERTasks: Array<Task>;
  EETasks: Array<Task>;
}) => React.ReactElement = (props) => (
  <Items>
    {get(props, 'leaves', []).map((leave: Leave, i: number) => (
      <LeaveCardItem
        leave={leave}
        key={`${leave.leaveId}-item-${i}`}
        ERTasks={props.ERTasks}
        EETasks={props.EETasks}
      />
    ))}
  </Items>
);

const getStatusText = async (props: any, setStatusMessage: Function) => {
  const statusConfigs = await getClaimStatusConfig();
  const foundStatus = statusConfigs.find(
    (s) =>
      s.status ===
      translateLeaveStatuses(get(props.leave, 'requestStatus', 'N/A')),
  );
  if (foundStatus && foundStatus.message) {
    setStatusMessage(foundStatus.message);
  }
};

export const LeaveCardItem: (props: {
  leave: Leave;
  ERTasks: Array<Task>;
  EETasks: Array<Task>;
}) => React.ReactElement = (props) => {
  const [showUpdate, setShowUpdate] = React.useState(false);
  const [statusMessage, setStatusMessage] = React.useState();

  const startDate = get(props.leave, 'startDate', null);
  const endDate = get(props.leave, 'endDate', null);
  const anchor = React.useRef<HTMLButtonElement | null>(null);
  const anchor2 = React.useRef<HTMLDivElement | null>(null);
  const { employerPreferences } = useContext(PreferencesContext);

  React.useEffect(() => {
    getStatusText(props, setStatusMessage);
  }, [props]);

  const showUpdateMenu = (e: any) => {
    e.preventDefault();
    setShowUpdate(!showUpdate);
  };

  const closeUpdateMenu = (e: any) => {
    e.preventDefault();
    setTimeout(() => {
      setShowUpdate(false);
    }, 200);
  };

  const [show, setShow] = React.useState(false);

  return (
    <Item>
      <div className="tw-flex tw-flex-row tw-items-center">
        <div className="tw-flex tw-flex-col md:tw-flex-row tw-gap-2 tw-w-1/2 md:tw-w-2/3 md:tw-mr-5">
          <div className="tw-flex tw-flex-col tw-gap-2 md:tw-w-1/2">
            <div className="tw-text-base tw-font-semibold">Claim</div>
            <div className="tw-text-base">{props.leave.reason}</div>
          </div>
          {dayjs(startDate).isValid() ? (
            <div className="tw-flex tw-flex-col tw-gap-2">
              <div className="tw-text-base tw-font-semibold">Period</div>
              <div className="tw-text-base">
                {dayjs(startDate).format(dateFormat)} -{' '}
                {dayjs(endDate).format(dateFormat)}
              </div>
            </div>
          ) : null}
        </div>
        <div className="tw-flex tw-flex-row tw-justify-end tw-items-center tw-w-1/2 md:tw-w-1/3">
          <div
            id={'claim-status-id-' + props.leave.caseId}
            ref={anchor2}
            onMouseEnter={() => setShow(true)}
            onMouseLeave={() => setShow(false)}
          >
            <StatusFlag
              className="tw-flex tw-flex-nowrap"
              color={translateLeaveStatuses(
                get(props.leave, 'requestStatus', 'None'),
              )
                .replace(/ /g, '')
                .toLowerCase()}
            >
              <img
                style={{ paddingRight: '5px' }}
                src={getStatusIcon(props)}
                alt=""
              />
              <div className="tw-inline-block tw-text-wrap md:tw-text-nowrap tw-align-middle">
                {translateLeaveStatuses(
                  get(props.leave, 'requestStatus', 'N/A'),
                )}
              </div>
              <ClaimStatusInfoIcon>
                <img alt="" src={ClaimStatusInfo} />
              </ClaimStatusInfoIcon>
              <Popover
                id={'popover-id-' + props.leave.caseId}
                show={show}
                anchor={anchor2.current}
                position={'top'}
              >
                <div
                  style={{
                    width: 220,
                    backgroundColor: '#015294',
                    padding: '5px',
                    borderRadius: '2px',
                    color: '#FFF',
                    fontWeight: 400,
                    textAlign: 'left',
                  }}
                >
                  {statusMessage}
                </div>
              </Popover>
            </StatusFlag>
          </div>
          <div>
            <UpdateButton
              ref={anchor}
              onClick={showUpdateMenu}
              onBlur={closeUpdateMenu}
            >
              <img alt="" src={penSolid} />
            </UpdateButton>
          </div>
          {get(props.leave, 'requestStatus', '') !== 'Closed' && (
            <UpdateLeavePopover
              leave={props.leave}
              employerPreferences={employerPreferences}
              showPopover={showUpdate}
              anchor={anchor}
            />
          )}
        </div>
      </div>
    </Item>
  );
};

export const UpdateLeavePopover = (p: {
  leave: Leave;
  showPopover: boolean;
  anchor: React.MutableRefObject<HTMLButtonElement | null>;
  employerPreferences: EmployerPreferences | undefined;
}) => {
  const { leave, showPopover, anchor } = p;
  return (
    <Popup
      id="lpv-edit-popover"
      anchor={anchor.current}
      anchorAlign={{
        horizontal: 'right',
        vertical: 'bottom',
      }}
      popupAlign={{
        horizontal: 'right',
        vertical: 'top',
      }}
      show={showPopover}
      popupClass={'popup-content'}
      margin={{ vertical: 5, horizontal: 0 }}
    >
      <PopupContent id="lpv-ocm-popover-content">
        <PopupLink
          to={{
            pathname: `${enums.AppPaths.form}/last-day-worked`,
            state: {
              task: {
                caseId: get(leave, 'caseId', null),
              },
            },
          }}
        >
          Revise your last day worked
        </PopupLink>
        {(leave.reason === pregReason || leave.reason === bondingReason) && (
          <PopupLink
            to={{
              pathname: `${enums.AppPaths.form}/delivery-date`,
              state: {
                task: {
                  caseId: get(leave, 'caseId', null),
                },
              },
            }}
          >
            Move your delivery date
          </PopupLink>
        )}
        <PopupLink
          to={{
            pathname: `${enums.AppPaths.form}/extension-request`,
            state: {
              task: {
                caseId: get(leave, 'caseId', null),
              },
            },
          }}
        >
          Request an extension
        </PopupLink>
        <Separator />
        <PopupLink
          to={{
            pathname: `${enums.AppPaths.form}/physician-information`,
            state: {
              task: {
                caseId: get(leave, 'caseId', null),
              },
            },
          }}
        >
          Update physician information
        </PopupLink>
      </PopupContent>
    </Popup>
  );
};

export default LeaveCardItems;
