import { useContext, useState } from 'react';
import { PersonDataContext } from '../../../../../../contexts/person-data-context';
import { get } from '../../../../../../utils';
import {} from '../../../../../documents';
import { IGeneratedCase } from '../../..';
import { Document } from '../../../../../../data-models';
import { PageChangeEvent } from '@progress/kendo-react-data-tools';
import PagerSection from '../../../../pager-section';
import { LetterItem } from './letter-item';
import { MultipleCardContainer } from '../../../components/simple-components';

type Props = {
  claim: IGeneratedCase;
};
export const OverviewLetters = ({ claim }: Props) => {
  const { personData } = useContext(PersonDataContext);

  const letters: Document[] = get(personData, 'documents.letters', [])
    .filter(
      (letter: Document) =>
        claim.claims.find(
          (c) => c.caseId === letter.claimId || c.caseId === letter.leaveId,
        ) ||
        claim.leaves.find(
          (l) => l.caseId === letter.claimId || l.caseId === letter.leaveId,
        ) ||
        claim.accommodations.find(
          (a) => a.caseId === letter.claimId || a.caseId === letter.leaveId,
        ),
    )
    .sort((a: Document, b: Document) => {
      return (b.receivedDate ?? '').localeCompare(a.receivedDate ?? '');
    });

  const [page, setPage] = useState({
    skip: 0,
    take: 4,
  });

  const handlePageChange = (e: PageChangeEvent) => {
    setPage({
      skip: e.skip,
      take: e.take,
    });
  };

  const { skip, take } = page; //Used for Pagination

  return (
    <>
      <MultipleCardContainer id="letters-card">
        {letters && letters?.length > 0 ? (
          <>
            {letters.slice(skip, skip + take).map((letter, index) => (
              <LetterItem item={letter} index={index} key={index} />
            ))}
            <div className="tw-flex-grow"></div>
            <PagerSection
              skip={skip}
              take={take}
              handlePageChange={handlePageChange}
              elements={letters}
            />
          </>
        ) : (
          <div className="tw-font-semibold tw-text-sm">
            You currently have no letters.
          </div>
        )}
      </MultipleCardContainer>
    </>
  );
};
