import { useState, useRef } from 'react';
import { Popup } from '@progress/kendo-react-popup';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { ReactComponent as HelpCircle } from '../../../../images/help-circle.svg';
import CloseX from '../../../../images/close-x-white.svg';
import { fonts } from '../../../../style';

type Props = {
  children: React.ReactNode;
};

type CardContainerProps = {
  children: React.ReactNode;
  id?: string;
};

export const TabCardContainer = ({ children }: Props) => {
  return (
    <div className="tw-w-full md:tw-h-[430px] md:tw-max-h-[430px] md:tw-shadow-basic md:tw-border md:tw-rounded-md">
      {children}
    </div>
  );
};

export const TabCardContainerPayments = ({ children }: Props) => {
  return (
    <div className="tw-w-full md:tw-shadow-basic md:tw-border md:tw-rounded-md">
      {children}
    </div>
  );
};

export const MultipleCardContainer = ({ children }: CardContainerProps) => {
  return (
    <div
      id="tasks-assigned-card"
      className="tw-h-full tw-flex tw-flex-col tw-pb-0 tw-max-h-[calc(100%-40px)]"
    >
      {children}
    </div>
  );
};

export const CardItemTitleMobile = ({ children }: Props) => {
  return (
    <div
      style={{ borderBottom: '2px solid #025780' }}
      className="tw-w-full tw-text-sm tw-border-unm-interactive01-700 tw-text-unm-interactive01-700 tw-font-semibold"
    >
      {children}
    </div>
  );
};

export const CardItemTitleDesktop = ({ children }: Props) => {
  return (
    <div className="items-center tw-text-base tw-text-unm-interactive01-700 tw-font-semibold tw-mb-5 custom-border-bottom">
      {children}
    </div>
  );
};

export const CardItemTitleNonOverview = ({ children }: Props) => {
  return (
    <div className="tw-flex tw-justify-between tw-text-2xl tw-mb-5 tw-text-unm-interactive01-700 tw-font-normal custom-border-bottom">
      {children}
    </div>
  );
};

export const CardItemContainer = ({ children }: Props) => {
  return (
    <div
      className="tw-p-3 tw-mb-3 tw-flex tw-justify-between tw-rounded tw-border tw-border-solid tw-border-unm-interactive01-800 
                tw-font-medium tw-text-sm tw-text-unm-primary01-100"
    >
      {children}
    </div>
  );
};

export const NoPaymentsContainer = () => {
  const id = `no-payments-container`;
  return (
    <div className="tw-rounded tw-pt-2 tw-px-3 tw-bg-[#FCFCFC]">
      <div className="tw-p-3 tw-mb-2 tw-flex tw-justify-center tw-rounded tw-border tw-border-solid tw-border-unm-interactive01-800 tw-font-medium tw-text-sm tw-text-unm-primary01-100">
        <div
          id={id}
          key={id}
          className="tw-flex tw-w-100 tw-justify-self-center"
        >
          <p className="tw-font-semibold tw-text-sm tw-justify-self-center tw-text-center tw-w-100 tw-my-4">
            You have no payments.
          </p>
        </div>
      </div>
    </div>
  );
};

export const TasksCardItemContainer = ({ children }: Props) => {
  return (
    <div
      className="box-border tw-p-3 tw-mb-3 tw-flex tw-flex-col tw-rounded tw-border tw-border-solid tw-border-unm-interactive01-800 tw-font-medium 
                  tw-text-sm tw-text-unm-primary01-100"
    >
      {children}
    </div>
  );
};

export const UploadButton = ({ children }: Props) => {
  return (
    <div
      className="tw-p-3 tw-my-3 tw-flex tw-justify-center tw-cursor-pointer tw-items-center 
                  tw-rounded tw-border-2 tw-border-solid tw-text-unm-interactive01-400  tw-border-unm-interactive01-400 tw-font-semibold tw-text-sm"
    >
      {children}
    </div>
  );
};

///////////////////////////////////////////////////

type ComponentProps = {
  popupContent: string;
};

export const HelpIcon = ({ popupContent }: ComponentProps) => {
  const [showPopup, setShowPopup] = useState(false);
  const buttonRef = useRef<HTMLButtonElement | null>(null);

  return (
    <div className="relative inline-block">
      <button
        ref={buttonRef}
        className="tw-flex tw-items-center tw-justify-center help-icon tw-w-4 tw-h-4 tw-bg-unm-primary02-400 tw-text-white tw-text-xs tw-rounded-full tw-border-0 tw-cursor-pointer"
        aria-label="Help"
        onMouseEnter={() => setShowPopup(true)}
        onMouseLeave={() => setShowPopup(false)}
        onFocus={() => setShowPopup(true)}
        onBlur={() => setShowPopup(false)}
      >
        ?
      </button>
      <Popup
        show={showPopup}
        offset={{ left: 10, top: 10 }}
        anchor={buttonRef.current}
        className="help-popup"
      >
        <div className="tw-w-52 tw-p-2 tw-text-white tw-text-sm tw-bg-unm-primary02-400 tw-border tw-border-unm-primary02-400 tw-rounded">
          {popupContent}
        </div>
      </Popup>
    </div>
  );
};

///////////////////////////////////////////////////

interface HelpSectionProps {
  title: string;
  popupContent: string;
  headerLinkName: string;
  headerLink: string;
  showDesktopLink: boolean;
}

export const HelpSection: React.FC<HelpSectionProps> = ({
  title,
  popupContent,
  headerLinkName,
  headerLink,
  showDesktopLink,
}) => {
  return (
    <div className="tw-flex tw-justify-between tw-items-center">
      <div className="tw-flex tw-justify-start tw-items-center">
        <span className="tw-mr-4">{title}</span>
        <span>
          <HelpIcon popupContent={popupContent} />
        </span>
      </div>
      {showDesktopLink && (
        <Link to={headerLink}>
          <div className="tw-no-underline tw-text-xs tw-font-normal">
            {headerLinkName}
          </div>
        </Link>
      )}
    </div>
  );
};

///////////////////////////////////////////////////

export const ImageContainer = styled.span<{ angle: string }>`
  img {
    transform: rotate(${(p) => p.angle});
  }
  cursor: pointer;
  position: relative;
  paddingleft: 5px;
`;

export const StatusFlag = styled.div<{
  background: string;
  color: string;
}>`
  background-color: ${(p) => p.background};
  border-radius: 4px;
  font-family: ${fonts.openSans.semibold.fontFamily};
  font-size: 10px;
  font-weight: ${fonts.openSans.semibold.fontWeight};
  color: ${(p) => p.color};
  padding: 4px 0px;
  width: 120px;
  text-align: center;
`;

export const StatusHelpTextBox = styled.div`
  background-color: #01344b;
  opacity: 95%;
  color: #ffffff;
  display: flex;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  padding: 16px 10px 10px 10px;
  cursor: default;

  position: absolute;
  width: 196px;
  height: auto;
  right: -92px;
  bottom: 30px;

  flex-direction: column;
  align-items: start;

  &:after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: -15px;
    margin: 0 auto;
    width: 0;
    height: 0;
    border-top: 17px solid #01344b;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    z-index: -1;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.4));
  }
`;

export const StatusHelpTextHeader = styled.span`
  position: absolute;
  font-family: ${fonts.openSans.semibold.fontFamily};
  font-weight: ${fonts.openSans.semibold.fontWeight};
  font-size: 12px;
  line-height: 0px;
`;

export const StatusHelpText = styled.p`
  flex-direction: column;
  font-size: 12px;
  line-height: 16px;
  text-align: left;
  margin-top: 12px;
  margin-bottom: 0px;
  font-family: ${fonts.openSans.regular.fontFamily};
  font-weight: ${fonts.openSans.regular.fontWeight};
`;

export const HelpCircleIcon = styled(HelpCircle)`
  margin-left: 6px;
  bottom: 1px;
`;

export const CloseIcon = styled.span`
  position: absolute;
  top: 8px;
  right: 6px;
  cursor: pointer;
`;
interface HelpImageContainerProps {
  taskId: number;
  help: boolean;
  setHelp: (value: boolean) => void;
  taskStatusHelp?: string;
  color: string;
}

export const HelpImageContainer: React.FC<HelpImageContainerProps> = ({
  taskId,
  help,
  setHelp,
  taskStatusHelp,
  color,
}) => (
  <ImageContainer angle="0deg">
    <HelpCircleIcon
      id={`task-${taskId}-help-circle`}
      onMouseEnter={() => setHelp(true)}
      fill={color}
      stroke={color}
    />
    {help && (
      <StatusHelpTextBox>
        <CloseIcon
          onClick={() => setHelp(false)}
          data-testid={`task-${taskId}-close-icon`}
        >
          <img src={CloseX} height="60%" width="60%" alt="Close" />
        </CloseIcon>
        <StatusHelpTextHeader>HELP</StatusHelpTextHeader>
        <StatusHelpText>{taskStatusHelp}</StatusHelpText>
      </StatusHelpTextBox>
    )}
  </ImageContainer>
);
