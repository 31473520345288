import { useQuery } from '@tanstack/react-query';
import { getSimpleRemoteConfig } from '../../../../utils/remote-config-manager';
import { Spinner } from '../../../base/spinner';
import { UserInitiatedTask } from '..';
import { useParams } from 'react-router';
import { getTasksWithKontent } from '../../container/api';
import { PersonDataContext } from '../../../../contexts/person-data-context';
import React from 'react';
interface ParamProps {
  taskCodeName: string;
}
export const UserInitiatedTaskContainer = () => {
  const { personData } = React.useContext(PersonDataContext);
  const { taskCodeName } = useParams<ParamProps>();
  const { data, isLoading } = useQuery(
    ['remoteConfig'],
    () => getSimpleRemoteConfig,
  );

  const { data: tasksResponse, isLoading: tasksLoading } = useQuery(
    ['getTasksWithKontent', personData?.person?.customerNumber],
    () => getTasksWithKontent(personData!.person!.customerNumber!),
  );

  if (isLoading || tasksLoading) {
    return <Spinner />;
  }

  return (
    <UserInitiatedTask
      taskCodeName={taskCodeName}
      rc={data}
      tasks={tasksResponse ?? []}
    />
  );
};
