import React from 'react';
import { Field, FormRenderProps } from '@progress/kendo-react-form';
import styled from 'styled-components';
import { enums, parseJSONOrDefault, prefixObjectValues } from '../../../utils';
import { models } from '../../../utils/cms-client';
import * as v from '../validators';
import ErrorBoundary from '../error-boundary';
import { process } from './props-template-processor';
import { FormContext } from '../../../contexts/form-context';
import useHandlebars from '../../../hooks/use-handlebars';
import { KenticoDataContext } from '../../../contexts/kentico-data-context';
import * as options from '../options';
import { RefreshContext } from '../../../contexts/refresh-context';
import { FormFieldWrapper } from '../form-field-wrapper';

/**
 * interfaces
 */
interface IFormFieldProps {
  keyId?: string;
  currentStepIndex: number;
  field: models.FormField;
  formValueGetter: FormRenderProps['valueGetter'];
  formOnChange: FormRenderProps['onChange'];
  formOptionsTemplate?: string;
  moveToStep?: Function;
  sectionFieldNamePrefix?: string;
}

const domIdsStatic = {
  rootNode: 'form-field',
  inputRoot: 'form-field-input',
};

export const domIdsUnique = (prefix?: string) =>
  prefixObjectValues(prefix, domIdsStatic);

const Container = styled.div``;

const FormField: (p: IFormFieldProps) => React.ReactElement | null = ({
  field,
  formOptionsTemplate,
  keyId,
  currentStepIndex,
  moveToStep,
  formValueGetter,
  formOnChange,
  sectionFieldNamePrefix,
}) => {
  const domIds = domIdsUnique(keyId);
  const [formFieldType, setFormFieldType] = React.useState<string | undefined>(
    field?.formFieldTypes?.value[0].name,
  );
  const [props, setProps] = React.useState<{ [name: string]: any }>({});
  const [optionsTemplate, setOptionsTemplate] =
    React.useState<options.IFieldOptions>({});
  const { formContext } = React.useContext(FormContext);
  const { assets, callCenter, featureFlags } =
    React.useContext(KenticoDataContext);
  const { refreshDetails } = React.useContext(RefreshContext);
  const [show, setShow] = React.useState(
    options.shouldShow(
      optionsTemplate,
      formValueGetter,
      formContext,
      refreshDetails,
      sectionFieldNamePrefix,
    ),
  );
  const isRequired =
    field.flags.value.filter((o) => o.codename === enums.FormFlags.required)
      .length > 0;

  // labels state
  const labelText = useHandlebars(
    field?.label?.value,
    formContext,
    formValueGetter,
  );
  const labelDetailsText = useHandlebars(
    field?.labelDetails?.value,
    formContext,
    formValueGetter,
  );

  const reprocessProps = React.useCallback(() => {
    if (formContext) {
      const pc = process(
        field.propsTemplate.value,
        { ...formContext, assets, callCenter, featureFlags },
        formValueGetter,
        sectionFieldNamePrefix,
      );

      setProps(pc);
    }
  }, [
    assets,
    callCenter,
    featureFlags,
    field.propsTemplate.value,
    formContext,
    formValueGetter,
    sectionFieldNamePrefix,
  ]);

  React.useEffect(() => {
    setOptionsTemplate(parseJSONOrDefault(field?.optionsTemplate?.value));
    setFormFieldType(field?.formFieldTypes?.value[0].name);
  }, [field]);

  React.useEffect(() => {
    reprocessProps();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formContext, formValueGetter, field.propsTemplate.value, show]);

  const handleValidation = React.useCallback(
    (value: string | Date) =>
      show
        ? v.handleValidation(
            options.processValidatorParams(
              optionsTemplate,
              formValueGetter,
              formContext,
            ),
            isRequired,
            value,
            formFieldType,
          )
        : '',
    [
      show,
      optionsTemplate,
      formValueGetter,
      formContext,
      isRequired,
      formFieldType,
    ],
  );

  const shouldShow = React.useCallback(() => {
    const ss = options.shouldShow(
      optionsTemplate,
      formValueGetter,
      formContext,
      refreshDetails,
      sectionFieldNamePrefix,
    );

    if (ss !== show) {
      setShow(ss);

      if (
        !ss &&
        formValueGetter(props.name) !== undefined &&
        props.name.startsWith(sectionFieldNamePrefix || '')
      ) {
        formOnChange(props.name, { value: undefined });
      }
    }

    return props.name !== undefined && ss;
  }, [
    optionsTemplate,
    formValueGetter,
    formContext,
    refreshDetails,
    sectionFieldNamePrefix,
    show,
    props.name,
    formOnChange,
  ]);

  const newId = `${domIds.inputRoot}_${formFieldType}_${props.name}`;

  return shouldShow() ? (
    <Container id={domIds.rootNode}>
      <ErrorBoundary>
        <Field
          id={newId}
          key={keyId}
          name={props?.name}
          component={FormFieldWrapper}
          required={isRequired}
          validator={handleValidation}
          fieldFlags={field?.flags}
          fieldOptionsTemplate={optionsTemplate}
          formFieldLabel={labelText}
          formFieldLabelDetails={labelDetailsText}
          formFieldType={formFieldType}
          formValueGetter={formValueGetter}
          formOptionsTemplate={formOptionsTemplate}
          formOnChange={formOnChange}
          reprocessProps={reprocessProps}
          currentStepIndex={currentStepIndex}
          moveToStep={moveToStep}
          {...props}
          data-cb-mask={
            formFieldType === enums.FormFieldTypes.input ? 'true' : 'false'
          }
        />
      </ErrorBoundary>
    </Container>
  ) : null;
};

export default FormField;
