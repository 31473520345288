import React, { RefObject, forwardRef } from 'react';
import * as dm from '../../../data-models';
import DetailCard from './detail-card';
import dayjs from 'dayjs';
import minMax from 'dayjs/plugin/minMax';
import {
  get,
  enums,
  analyticsTrackEvent,
  translateLeaveStatuses,
  getRelatedTask,
} from '../../../utils';
import AccordionCard from '../../base/accordion-card';
import { StatusFlag } from '../../base/claim-card/card-items/shared-styles';
import BlueChevron from '../../../images/leave-calendar/small-blue-chevron.svg';
import { CustomLink, CardButton } from '../../base/simple-styled-components';
import { UpdateLeavePopover } from '../../base/claim-card/card-items/leave-item';
import { PreferencesContext } from '../../../contexts/preferences-context';
import { PersonDataContext } from '../../../contexts/person-data-context';
import { isDueTask } from '../../tasks/utils';
import { useQuery } from '@tanstack/react-query';
import { getTasksWithKontent } from '../../tasks/container/api';
import { EmployerTaskInfo } from '../../tasks/employer';

dayjs.extend(minMax);

const dtFormat = 'MMM DD, YYYY';

interface DetailViewProps {
  keyId?: string;
  benefits?: {
    jobProtectionBenefits?: dm.Calendar[];
    incomeProtectionBenefits?: dm.Calendar[];
  };
  visualType: 'intake' | 'continuous';
  leaves?: dm.Leave[];
  claims?: dm.Claim[];
  legend?: { [key: string]: any };
}

const JobProtectionDetail: (p: {
  details: dm.JobProtectedBenefit[];
}) => React.ReactElement | null = (p) => {
  let description = 'No potential job protection benefits were found.';

  if (p.details.length > 0) {
    const earliestStart = dayjs.min(p.details.map((d) => dayjs(d.startDate)));
    const latestEnd = dayjs.max(p.details.map((d) => dayjs(d.endDate)));

    description = `**Job protected leave may be available to you** between ${earliestStart?.format(
      'MMM DD',
    )} and ${latestEnd?.format(dtFormat)}.`;
  }

  return (
    <DetailCard
      idPrefix="jp"
      header="Job Protection"
      description={description}
      benefits={p.details}
      index={1}
      onExpandCallback={() =>
        analyticsTrackEvent(enums.AnalyticsEvents.lpvJobProtectionExpanded)
      }
    />
  );
};

const IncomeProtectionDetail: (p: {
  details: dm.Calendar[];
}) => React.ReactElement | null = (p) => {
  const percentages = p.details.filter(
    (d: any) => d.legendKey === 'potentialPaidBenefits',
  );
  const benefits = p.details.filter((d: any) => d.legendKey === 'paidBenefits');

  if (percentages.length === 0) {
    return null;
  }

  let description = '';
  if (
    percentages.length === 1 &&
    percentages[0].meta?.percentage &&
    percentages[0].meta.percentage !== 0
  ) {
    const first = percentages[0];
    description = `**You may receive up to ${first?.meta?.percentage}% of your pay**, subject to applicable deductions (taxes, other income, etc.).`;
  } else {
    const earliestStart = dayjs.min(
      percentages.map((d: any) => dayjs(d.startDate)),
    );
    description = `**You may receive a percentage of your earnings** starting ${dayjs(
      earliestStart,
    ).format(dtFormat)}.`;
  }

  return (
    <DetailCard
      idPrefix="pl"
      header="Income Protection"
      description={description}
      benefits={benefits}
      index={2}
      onExpandCallback={() =>
        analyticsTrackEvent(enums.AnalyticsEvents.lpvPaidLeaveExpanded)
      }
    />
  );
};

type Props = {
  children: React.ReactNode;
  id?: string;
  className?: string;
};

type IconProps = {
  menuOpen: boolean;
  src: string;
  alt: string;
};

type ButtonProps = {
  children: React.ReactNode;
  ref: RefObject<HTMLButtonElement>;
  onClick: () => void;
  onBlur: () => void;
};

export const Container = ({ children, id }: Props) => {
  return (
    <div id={id} className="tw-p-0 tw-bg-white">
      {children}
    </div>
  );
};

export const ListItemContainer = ({ children, className }: Props) => {
  return (
    <div
      className={`tw-grid tw-grid-cols-12 tw-pb-4 tw-mb-4 tw-gap-3 ${className}`}
    >
      {children}
    </div>
  );
};

export const ListText = ({ children, className }: Props) => {
  return <div className={`tw-text-sm ${className}`}>{children}</div>;
};

export const FlexContainer = ({ children }: Props) => {
  return (
    <div className="tw-flex tw-flex-wrap tw-justify-between tw-gap-2">
      {children}
    </div>
  );
};

export const Subtitle = ({ children }: Props) => {
  return <div className="tw-text-xl tw-font-semibold tw-mb-4">{children}</div>;
};

export const FooterContainer = ({ children }: Props) => {
  return (
    <div
      style={{ border: '1px solid #007CB7' }}
      className="tw-bg-unm-bg-light tw-flex tw-justify-end tw-py-4 tw-px-8 tw-w-full tw-border-solid
              tw-border-unm-border1 tw-border tw-rounded-b-lg tw-gap-4 tw-flex-col sm:tw-flex-row tw-static"
    >
      {children}
    </div>
  );
};

export const OutlineComboButton = forwardRef<HTMLButtonElement, ButtonProps>(
  ({ children, onClick, onBlur }, ref) => {
    return (
      <button
        ref={ref}
        onClick={onClick}
        onBlur={onBlur}
        style={{ border: '1px solid #007CB7' }}
        className="tw-border tw-border-denim1 tw-bg-white tw-rounded-2xl tw-text-unm-interactive01-400 tw-text-14px 
                tw-py-1 tw-px-6 tw-flex tw-items-center tw-gap-x-1 tw-max-w-32 tw-justify-center hover:tw-bg-white hover:tw-text-unm-interactive01-400"
      >
        {children}
      </button>
    );
  },
);

export const CustomButtonMod = ({ children }: Props) => {
  return (
    <CardButton className="tw-py-1.5 tw-px-4 tw-max-w-39 tw-w-full">
      {children}
    </CardButton>
  );
};

export const Icon = ({ menuOpen, src, alt }: IconProps) => {
  return (
    <img
      src={src}
      alt={alt}
      className={`tw-transform tw-text-unm-interactive01-400 ${
        menuOpen ? 'tw-rotate-180' : ''
      }`}
    />
  );
};

export const ExpandedListItem = ({ children }: Props) => {
  return (
    <div className="tw-border-b tw-border-dashed tw-border-alto2 tw-pb-4.5 tw-mb-4.5 last:tw-border-none last:tw-pb-0 last:tw-mb-0">
      {children}
    </div>
  );
};

const dateformat = 'MM/DD/YYYY';
const benefitsAccordian = (p: any, benefit: any, i: number) => {
  return (
    <div>
      <ListItemContainer key={`jp-benefit-${i}`}>
        <div className="tw-col-span-12 md:tw-col-span-6 tw-p-0">
          <ListText className="tw-font-bold">{'TYPE'}</ListText>
          <ListText>
            {benefit?.meta?.leavePlanName ?? 'Unum Short Term Disability'}
          </ListText>
        </div>
        <div className="tw-col-span-6 sm:tw-col-span-4 md:tw-col-span-2 p-0">
          <ListText className="tw-font-bold">{'FROM'}</ListText>
          <ListText className="">
            {dayjs(benefit?.startDate).format(dateformat)}
          </ListText>
        </div>
        <div className="tw-col-span-6 sm:tw-col-span-4 md:tw-col-span-2 tw-p-0">
          <ListText className="tw-font-bold">{'TO'}</ListText>
          <ListText className="">
            {dayjs(benefit?.endDate).format(dateformat)}
          </ListText>
        </div>
        <div className="tw-col-span-12 sm:tw-col-span-4 md:tw-col-span-2 tw-p-0">
          <ListText className="tw-font-bold">{'STATUS:'}</ListText>
          <ListText>{p.legend?.[benefit?.legendKey]?.statusName}</ListText>
        </div>
      </ListItemContainer>
    </div>
  );
};

const benefitDeduper = (benefit: any) => [
  `${benefit.meta?.leavePlanName}-${benefit.startDate}-${benefit.endDate}-${benefit.legendKey}`,
  benefit,
];

const BenefitDetail = (p: any) => {
  if (
    p.benefits?.jobProtectionBenefits?.length === 0 &&
    p.benefits?.incomeProtectionBenefits?.length === 0
  ) {
    return (
      <div>
        <ListText>No benefits were found for this leave request.</ListText>
      </div>
    );
  }
  return (
    <div>
      {p.benefits?.jobProtectionBenefits?.length > 0 && (
        <div className="d-flex flex-column">
          <AccordionCard
            idPrefix="benefitsJP"
            index={2}
            titleString="Job Protection"
            expandedElement={
              <div>
                {[
                  ...new Map(
                    p.benefits?.jobProtectionBenefits?.map(benefitDeduper),
                  ).values(),
                ].map((benefit: any, i: number) =>
                  benefitsAccordian(p, benefit, i),
                )}
              </div>
            }
          ></AccordionCard>
        </div>
      )}
      {p.benefits?.incomeProtectionBenefits?.length > 0 && (
        <div className="d-flex flex-column">
          <AccordionCard
            idPrefix="benefitsIP"
            index={2}
            titleString="Income Protection"
            expandedElement={
              <div>
                {[
                  ...new Map(
                    p.benefits?.incomeProtectionBenefits?.map(benefitDeduper),
                  ).values(),
                ].map?.((benefit: any, i: number) =>
                  benefitsAccordian(p, benefit, i),
                )}
              </div>
            }
          ></AccordionCard>
        </div>
      )}
    </div>
  );
};

const LeaveExpanded = (p: {
  leaves?: dm.Leave[];
  erTasks?: Array<dm.Task>;
}) => {
  return (
    <>
      {p.leaves?.map?.((leave, i) => {
        const task = p.erTasks
          ? getRelatedTask(leave.leaveId, p.erTasks)
          : null;
        return (
          <>
            <ExpandedListItem key={`leave-item-${i}`}>
              <Subtitle>{leave?.reason}</Subtitle>
              <FlexContainer>
                <div>
                  <ListText>
                    <span className="tw-font-bold">EVENT ID: </span>
                    {leave?.leaveId}
                  </ListText>
                </div>
                <div>
                  <ListText>
                    <span className="tw-font-bold">PERIOD: </span>
                    {`${dayjs(leave?.startDate).format(dateformat)} - ${dayjs(
                      leave?.endDate,
                    ).format(dateformat)}`}
                  </ListText>
                </div>
                <div>
                  <ListText>
                    {' '}
                    <span className="tw-font-bold">STATUS: </span>
                    {`${translateLeaveStatuses(leave?.requestStatus)}`}
                  </ListText>
                </div>
                <EmployerTaskInfo
                  show={task && isDueTask(task.taskStatusId) ? true : false}
                />
              </FlexContainer>
            </ExpandedListItem>
          </>
        );
      })}
    </>
  );
};

const ClaimExpanded = (p: {
  claims?: dm.Claim[];
  erTasks?: Array<dm.Task>;
}) => {
  return (
    <>
      {p.claims?.map?.((claim, i) => {
        const task = p.erTasks
          ? getRelatedTask(claim.claimId, p.erTasks)
          : null;
        return (
          <ExpandedListItem key={`claim-item-${i}`}>
            <Subtitle>{claim?.claimType}</Subtitle>
            <FlexContainer>
              <ListText>
                <span className="tw-font-bold">EVENT ID: </span>
                {claim?.claimId}
              </ListText>
              <ListText>
                <span className="tw-font-bold">CREATED: </span>
                {dayjs(claim?.notificationDate).format(dateformat)}
              </ListText>
              <ListText>
                <span className="tw-font-bold">STATUS: </span>
                {claim?.status}
              </ListText>
              <EmployerTaskInfo
                show={task && isDueTask(task.taskStatusId) ? true : false}
              />
            </FlexContainer>
          </ExpandedListItem>
        );
      })}
    </>
  );
};

const Footer = (p: dm.Leave) => {
  const [showPopover, setShowPopover] = React.useState(false);
  const anchor = React.useRef<HTMLButtonElement | null>(null);
  const { employerPreferences } = React.useContext(PreferencesContext);

  const togglePopover = (show: boolean) => {
    setShowPopover(show);
  };

  return (
    <FooterContainer>
      <OutlineComboButton
        ref={anchor}
        onClick={() => togglePopover(!showPopover)}
        onBlur={() => togglePopover(false)}
      >
        Update <Icon menuOpen={showPopover} src={BlueChevron} alt="" />
      </OutlineComboButton>
      <CustomLink
        className="m-0"
        to={{
          pathname: `${enums.AppPaths.form}/extension-request`,
          state: {
            task: {
              caseId: get(p, 'leaveId', null),
            },
          },
        }}
      >
        <CustomButtonMod>Request Extension</CustomButtonMod>
      </CustomLink>
      <UpdateLeavePopover
        leave={p}
        showPopover={showPopover}
        anchor={anchor}
        employerPreferences={employerPreferences}
      />
    </FooterContainer>
  );
};

const getOverallStatus = (leaves: dm.Leave[], field: string) => {
  const firstStatus = get(leaves, `0.${field}`);

  const isAllSameStatus = leaves.every((l) => get(l, field) === firstStatus);

  if (firstStatus && isAllSameStatus) {
    return firstStatus;
  } else if (!firstStatus && isAllSameStatus) {
    return undefined;
  } else {
    return 'Mixed Decision';
  }
};

const getERTasks = async (customerNumber: string) => {
  const tasks = await getTasksWithKontent(customerNumber);
  return tasks.filter((t) => t.assigneeId === 1 && isDueTask(t.taskStatusId));
};

const DetailView: (p: DetailViewProps) => React.ReactElement = (p) => {
  const { benefits } = p;
  const { personData } = React.useContext(PersonDataContext);

  const { data: erTasks } = useQuery(
    ['getTasksWithKontent - all', personData?.person?.customerNumber, 0],
    () => getERTasks(personData!.person!.customerNumber!),
  );

  if (p.visualType === 'continuous') {
    const leaveStatus = getOverallStatus(p?.leaves ?? [], 'requestStatus');
    const claimStatus = getOverallStatus(p?.claims ?? [], 'status');
    return (
      <div>
        {p.leaves && p.leaves.length > 0 && (
          <AccordionCard
            idPrefix="leave"
            index={0}
            titleString="Leave"
            statusElement={
              <StatusFlag
                color={translateLeaveStatuses(leaveStatus ?? 'None')
                  .replace(/ /g, '')
                  .toLowerCase()}
              >
                {translateLeaveStatuses(leaveStatus)}
              </StatusFlag>
            }
            expandedElement={
              <LeaveExpanded leaves={p.leaves} erTasks={erTasks} />
            }
            footerElement={<Footer {...p.leaves[0]} />}
          />
        )}
        {p.claims && p.claims.length > 0 && (
          <AccordionCard
            idPrefix="claim"
            index={1}
            titleString="Claim"
            statusElement={
              <StatusFlag
                color={(claimStatus ?? 'None').replace(/ /g, '').toLowerCase()}
              >
                {claimStatus ?? 'N/A'}
              </StatusFlag>
            }
            expandedElement={
              <ClaimExpanded claims={p.claims} erTasks={erTasks} />
            }
          />
        )}
        <AccordionCard
          idPrefix="benefits"
          index={2}
          titleString="Benefits"
          expandedElement={
            <BenefitDetail benefits={benefits} legend={p.legend} />
          }
        />
      </div>
    );
  }
  return (
    <Container id="lpv-detail-view-container">
      <JobProtectionDetail details={benefits?.jobProtectionBenefits ?? []} />
      <IncomeProtectionDetail
        details={benefits?.incomeProtectionBenefits ?? []}
      />
    </Container>
  );
};

export default DetailView;
