import React, { useContext } from 'react';
import { colors } from '../../style';
import { ListView } from '@progress/kendo-react-listview';
import { DropDownListChangeEvent } from '@progress/kendo-react-dropdowns';
import { PageChangeEvent } from '@progress/kendo-react-data-tools';
import PagerSection from '../base/pager-section';
import DocumentGraphic from '../../images/document-in-cloud.png';
import {
  Container,
  TitleBar,
  Body,
  Text,
  ItalicText,
  NoDocumentsContainer,
  Title,
  HeaderLabel,
  ButtonStyledLink,
  Dialog,
  LoadingCover,
} from '../base/simple-styled-components';
import { enums, get } from '../../utils';
import ItemRender from './item-render';
import * as dm from '../../data-models';
import { PersonDataContext } from '../../contexts/person-data-context';
import ClaimsDropDownList from '../base/claims-drop-down-list';
import useRefreshHandler from '../../hooks/use-refresh-handler';
import { FadeLoader } from 'react-spinners';

/**
 * Interfaces
 */
export interface DocumentProps extends dm.Documents {
  caseId?: string;
  claimId?: string;
  leaveId?: string;
  receivedDate?: string;
}

interface DocumentsComponentProps {
  displayModalTest?: boolean;
}

const domIdsStatic = {
  rootNode: 'documents',
};

/**
 * Sorts the documents by the received date - DESC
 */
const sortDescending = (a: any, b: any) => {
  const d1 = new Date(a.receivedDate);
  const d2 = new Date(b.receivedDate);

  return d1 < d2 ? 1 : -1;
};

const defaultOption = 'All Claims';

/**
 * Documents component - Displays a styled list of documents that a user can open
 * @param p
 */
const Documents = (p: DocumentsComponentProps) => {
  const { personData } = useContext(PersonDataContext);
  const { isRefreshing } = useRefreshHandler([
    enums.RefreshDetailTypes.cases,
    enums.RefreshDetailTypes.documents,
  ]);

  //Set component states
  const [initialDocuments, setInitialDocuments] = React.useState([]);
  const [documents, setDocuments] = React.useState([]);
  const [filterDocuments, setFilteredDocuments] = React.useState([]);
  const [displayModal, setDisplayModal] = React.useState(
    p.displayModalTest || false,
  );
  const [modalLink, setModalLink] = React.useState('');
  const [page, setPage] = React.useState({
    skip: 0,
    take: 10,
  });

  console.log('document view', documents);
  React.useEffect(() => {
    //Initial formatting of the data
    const allDocs = get(personData, 'documents.letters', []).concat(
      get(personData, 'documents.documents', []),
    );

    //Determine if the document has a claimId or a leaveId and assign that value to a common caseId property
    const allDocsWithCaseId = allDocs.map((document: DocumentProps) => {
      const caseId = document.claimId ? document.claimId : document.leaveId;
      document.caseId = caseId;
      return document;
    });

    setInitialDocuments(allDocsWithCaseId);
    setDocuments(allDocsWithCaseId);
    setFilteredDocuments(allDocsWithCaseId);
  }, [personData]);

  filterDocuments.sort(sortDescending);

  const handlePageChange = (e: PageChangeEvent) => {
    setPage({
      skip: e.skip,
      take: e.take,
    });
  };

  const openModal = (link: string) => {
    setModalLink(link);
    setDisplayModal(true);
  };

  const closeModal = () => {
    setDisplayModal(false);
  };

  //Filters the list of docments by the caseID (selecting 'All Claims' will reset documents to initial state)
  const filterByClaim = (e: DropDownListChangeEvent) => {
    let filteredDocuments = initialDocuments;
    if (e.target.value.caseId !== defaultOption) {
      filteredDocuments = initialDocuments.filter((document: DocumentProps) => {
        return document.caseId === e.target.value.caseId;
      });
    }
    setDocuments(filteredDocuments);
    setFilteredDocuments(filteredDocuments);
  };

  const { skip, take } = page; //Used for Pagination

  const claimList = [
    ...(personData?.cases?.claims || []),
    ...(personData?.cases?.leaves || []),
    ...(personData?.cases?.accommodations || []),
  ];

  return (
    <Container id={domIdsStatic.rootNode}>
      <TitleBar>
        <Title>Documents</Title>
        <ClaimsDropDownList
          caseList={claimList}
          defaultOption={defaultOption}
          onChange={filterByClaim}
          viewAll
          processedProps
          disabled={isRefreshing}
        />
      </TitleBar>
      <div className="position-relative">
        {isRefreshing && (
          <LoadingCover>
            <FadeLoader
              height={14}
              width={5}
              radius={2}
              margin={0}
              color={colors.blue}
            />
          </LoadingCover>
        )}
        {filterDocuments.length > 0 ? (
          <Body>
            <ListView
              data={filterDocuments.slice(skip, skip + take)}
              item={(itemProps) => (
                <ItemRender {...itemProps} openModal={openModal} />
              )}
              header={Header}
              style={{ border: 0, position: 'initial' }}
            ></ListView>
            <PagerSection
              skip={skip}
              take={take}
              handlePageChange={handlePageChange}
              elements={documents}
            />
          </Body>
        ) : (
          <NoDocumentsContainer>
            <img src={DocumentGraphic} alt="" width="179" height="148" />
            <ItalicText className="mt-4">
              {`You will be able to view your documents & letters here once you start to receive them.`}
            </ItalicText>
          </NoDocumentsContainer>
        )}
      </div>

      {displayModal && (
        <Dialog
          id={`document-available-modal`}
          onClose={closeModal}
          title={'Document Available'}
        >
          <Text
            color={colors.warmNeutralL3}
            className="p-3 pb-4"
            style={{ fontSize: 16 }}
          >
            Your document has been securely retrieved and is now available for
            viewing.
          </Text>
          <ButtonStyledLink
            id={`open-document-link`}
            href={modalLink}
            target="_blank"
          >
            Open Document
          </ButtonStyledLink>
        </Dialog>
      )}
    </Container>
  );
};

/**
 * Customer Header for the ListView component
 */
const Header = () => {
  return (
    <div
      style={{ color: 'rgb(160, 160, 160)', fontSize: 14, margin: 0 }}
      className="row align-middle d-none d-md-flex"
    >
      <HeaderLabel className="col-5 col-md-3">Date</HeaderLabel>
      <HeaderLabel className="col-7 col-md-5">Title</HeaderLabel>
      <HeaderLabel className="d-none d-md-block col-md-4">Claim ID</HeaderLabel>
    </div>
  );
};

export default Documents;
