import { getter, setter } from '@progress/kendo-react-common';
import * as enums from './enums';
import marked from 'marked';
import { models } from './cms-client';
import {
  getDynamicContent,
  getSimpleRemoteConfig,
  ISpecialBenefit,
  ITask,
  IEmployerCustomTask,
  IEmployerNextStep,
  IDynamicContent,
} from './remote-config-manager/index';
import {
  Accommodation,
  Cases,
  Claim,
  IGeneratedPersonData,
  Leave,
  Task,
} from '../data-models';
import { log } from './log';
import { ElementModels } from '@kentico/kontent-delivery';
import { trackEvent } from './analytics';
import { IKenticoData } from '../contexts/kentico-data-context';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import claimStatusGreen from '../images/claimStatusGreen.svg';
import claimStatusMix from '../images/claimStatusMix.svg';
import claimStatusOrange from '../images/claimStatusOrange.svg';
import claimStatusRed from '../images/claimStatusRed.svg';
import claimStatusBlue from '../images/claimStatusBlue.svg';
import { ReminderIcons } from './enums';
import { getPreferences, getReminders } from './web-apis-client';
dayjs.extend(utc);
dayjs.extend(timezone);

export * as supplemental from './supplemental';

// const log = new Logger\('utils');

/*******************************************************************************
 * utils/index.js is a collection of miscellaneous utility functions
 ******************************************************************************/

/**
 * Retrieves the value for a given object and path
 * @param {*} obj The object to search the path for
 * @param {string} path The path to the value to retrieve
 * @param {*} returnIfNotFound The value to return if the path is not found
 */
export const get = <R, T>(obj: R, path: string, returnIfNotFound?: T) => {
  const fn = getter(path);
  const r = fn(obj);

  if (r === null || r === undefined || r === '') {
    return returnIfNotFound;
  }

  return r;
};

/**
 * Sets the value for a given object and path
 * @param {*} obj The object to search the path for
 * @param {string} path The path to the value to retrieve
 * @param {*} value Value to set on the object
 * @returns {*} object Modified object
 */
export const set = <R, T>(obj: R, path: string, value: T) => {
  const setPath = setter(path);
  setPath(obj, value);

  return obj;
};

/**
 * Creates a shallow copy of the provided object and adds a prefix to the object values
 * @param {string} prefix The prefix
 * @param {object} obj The object
 * @returns A shallow copy of the obj with a prefix added to the obj values
 */
export const prefixObjectValues = (
  prefix: string | undefined,
  obj: { [name: string]: string },
) => {
  const shallowCopy = { ...obj };

  if (prefix) {
    Object.keys(obj).forEach((k) => {
      shallowCopy[k] = `${prefix}${obj[k]}`;
    });
  }

  return shallowCopy;
};

/**
 * Parse markdown to HTML
 * @param markdown string Markdown to parse
 * @returns object containing __html with parsed message
 */
export const getHtmlFromMarkdown = (markdown: string) => {
  const parser = marked.setOptions({ gfm: true, breaks: true });
  const parsedMessage = parser(markdown);
  return { __html: parsedMessage };
};

/**
 * Used for grabbing JWT from SAML parsing redirect
 * @name {string} token to extract
 * @url {string} defaults to current window.location.href
 */
export const getUrlParameterByName = (name: string) => {
  name = name.replace(/[[]/, '\\[').replace(/[\]]/, '\\]');
  const regex = new RegExp(`[\\?&]${name}=([^&#]*)`);
  const results = regex.exec((window as Window)?.location?.search);
  return results === null
    ? ''
    : decodeURIComponent(results[1].replace(/\+/g, ' '));
};

/**
 * Trims the end / from a path (or just returns / if root path)
 * @param path string
 * @returns string
 */
export const trimPathEndSlash = (path: string): string => {
  if (path !== '/') {
    return path.replace(/\/+$/, '');
  }

  return path;
};

/**
 * Parse JSON or return a default value
 * @param json string JSON to parse
 * @param defaultValue any Used to default the return value if unable to parse
 * @returns any Object value parsed, or defaultValue if unable to parse
 */
export const parseJSONOrDefault = (
  json: string | undefined | null,
  defaultValue = {},
) => {
  let result;

  try {
    result = JSON.parse(json || JSON.stringify(defaultValue));
  } catch (e) {
    log.error('parseJSONOrDefault', e);
    result = defaultValue;
  }

  return result;
};

/**
 * Parse string as integer or return a default value
 * @param int string int to parse
 * @param defaultValue any Used to default the return value if unable to parse
 * @returns number parsed, or defaultValue if unable to parse
 */
export const parseIntOrDefault = (int: string, defaultValue = 0): number => {
  let result;

  try {
    result = parseInt(int) || defaultValue;
  } catch (e) {
    log.error('parseIntOrDefault', e);
    result = defaultValue;
  }

  return result;
};

/**
 * Function scrolls the app to start of the specified element.
 * @param elementName the name of the element to scroll to.
 */
export const scrollToElementByName = (elementName: string) => {
  setTimeout(() => {
    const elements = document.getElementsByName(elementName);

    if (elements.length > 0) {
      elements[0].scrollIntoView({
        block: 'center',
        behavior: 'smooth',
      });
    }
  }, 0);
};

/**
 * Function scrolls the app to the passed in top and left positions
 * @param x top position
 * @param y left position
 */
export const scrollApp = (x = 0, y = 0) => {
  setTimeout(() => {
    const app = document.getElementById('app-root-node');
    app?.scrollTo({
      top: x,
      left: y,
      behavior: 'smooth',
    });
  }, 0);
};
export const removeSpecialCharactersFromObject = (obj: {
  [name: string]: any;
}) => {
  const values = obj;
  for (const key in values) {
    if (typeof values[key] === 'string') {
      const convertedText = values[key].replaceAll(/[\t].+/g, ' ');
      values[key] = convertedText;
    }
  }
  return values;
};
const sortAscending = (a: any, b: any) => {
  const o1 = a.order;
  const o2 = b.order;

  return o1 < o2 ? 1 : -1;
};

/**
 * This function gets all dynamic content for the specified path and returns all the code for the specified location
 * @param path URL path dynamic content is being looked for
 * @param location the location on the page for the content
 * @returns the html markdown for the specified piece of dynamic content
 */
export const getContent = async (path: string, location: string) => {
  if (path && path.endsWith('/')) {
    path = path.substring(0, path.length - 1); //remove trailing forward slash if exists
  }

  const content = await getDynamicContent(path);
  let code = '';
  content?.items?.sort(sortAscending);
  content?.items?.forEach((item: models.ContentBlob) => {
    if (
      item.location &&
      item.code &&
      item.location.value[0].codename === location
    ) {
      code += item.code.value;
    }
  });

  return code;
};

/**
 * This function returns a boolean to specify whether payment prefs should be shown to the company code in personData
 * @param personData the person data object
 * @param prefs whether the list of ids to be checked is for hiding the payment prefs or payments tab
 * @returns boolean
 */
export const showPaymentPageBasedOnEmployerId = async (
  ERContent: models.EmployerContent,
) => {
  const hidePrefs = (ERContent as any)['hide_payments_page'] === 'Yes';

  const showPrefsKey = enums.RemoteConfigKeys.showPaymentPrefs;
  const config = await getSimpleRemoteConfig();
  const showPaymentPrefs = config[showPrefsKey];
  return showPaymentPrefs && !hidePrefs;
};

/**
 * Takes incoming cases(leaves/claims) and reverse sorts by caseId
 * @param cases object
 * @returns cases object
 */
export const sortCasesByCaseIdDescending = (cases?: Array<any>) => {
  let caseTypeToSort = cases || [];
  caseTypeToSort = caseTypeToSort.sort((a, b) => {
    if (!a.caseId || !b.caseId) {
      return 0;
    }
    if (a.caseId < b.caseId) {
      return 1;
    }
    if (a.caseId > b.caseId) {
      return -1;
    }
    return 0;
  });
  return caseTypeToSort;
};

/**
 * Takes incoming cases(leaves/claims)
 * Generates parent NTN structure w/ child leaves+claims, all reverse sorted by NTN/CaseID
 * @param cases object
 * @returns ntn object
 */
export const generateParentNTNsFromCases = (cases?: Cases) => {
  const allClaims: Claim[] = sortCasesByCaseIdDescending(cases?.claims || []);
  const allLeaves: Leave[] = sortCasesByCaseIdDescending(cases?.leaves || []);
  const allAccommodations: Accommodation[] = sortCasesByCaseIdDescending(
    cases?.accommodations || [],
  );
  const uniqueNTNIds = [
    ...Array.from(
      new Set(
        [...allClaims, ...allLeaves, ...allAccommodations].map(
          (item) => item.parentId || '',
        ),
      ),
    ),
  ].sort((a, b) => {
    const aNTN = parseInt(a.replace('NTN-', ''))
      ? parseInt(a.replace('NTN-', ''))
      : 0;
    const bNTN = parseInt(b.replace('NTN-', ''))
      ? parseInt(b.replace('NTN-', ''))
      : 0;
    return bNTN - aNTN;
  });

  return uniqueNTNIds.map((str) => {
    const matchingClaims = allClaims.filter((c) => c.parentId === str) || [];
    const matchingLeaves = allLeaves.filter((l) => l.parentId === str) || [];
    const matchingAccommodations =
      allAccommodations.filter((a) => a.parentId === str) || [];

    const integrated =
      matchingClaims.length && matchingLeaves.length ? true : false;

    const caseEid =
      matchingLeaves[0] && matchingLeaves[0].eid
        ? matchingLeaves[0].eid
        : matchingClaims[0] && matchingClaims[0].eid
        ? matchingClaims[0].eid
        : '';

    return {
      NTNId: str,
      eid: caseEid,
      claims: matchingClaims,
      leaves: matchingLeaves,
      accommodations: matchingAccommodations,
      isIntegrated: integrated,
    };
  });
};

/**
 * Creates analytics data using personData
 * @param personData The personData object
 */
export const setAnalyticsData = (personData?: IGeneratedPersonData) => {
  if (!personData) {
    return;
  }

  const analytics = {
    customerNumber: '',
    employerId: '',
    employerName: '',
    firstTimeLogin: '',

    cases: undefined as any,
    documents: undefined as any,
    tasks: undefined as any,
  };

  if (personData.person) {
    analytics.customerNumber = (personData.person as any).customerNumber
      ? (personData.person as any).customerNumber
      : '';

    analytics.employerId = personData.person.companyCode
      ? personData.person.companyCode
      : '';

    analytics.employerName = personData.person.employerName
      ? personData.person.employerName
      : '';

    analytics.firstTimeLogin = personData.person.firstTimeLogin
      ? personData.person.firstTimeLogin
      : '';
  }

  analytics.cases = personData.cases ? personData.cases : {};
  analytics.documents = personData.documents ? personData.documents : {};
  analytics.tasks = personData.tasks ? personData.tasks : {};

  (window as any).caas = (window as any).caas ? (window as any).caas : {};
  (window as any).caas.analytics = analytics;
};

export const getCookie = function (cname: string) {
  const name = cname + '=';
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(';');
  for (const c of ca) {
    let tempC = c;
    while (tempC.charAt(0) === ' ') {
      tempC = tempC.substring(1);
    }
    if (tempC.indexOf(name) === 0) {
      return tempC.substring(name.length, c.length);
    }
  }
  return '';
};

export const isTrackingDeclined = function () {
  const consentCookie = getCookie('OptanonConsent');
  if (consentCookie) {
    // Parse the cookie value
    const params = new URLSearchParams(
      consentCookie.replace(/&/g, '&').split('; ').join('&'),
    );
    const groups = params.get('groups');
    if (groups) {
      // Check if the tracking group is declined (group ID 'C0003' for tracking)
      const trackingGroup = groups
        .split(',')
        .find((group) => group.startsWith('C0003:'));
      if (trackingGroup) {
        const trackingStatus = trackingGroup.split(':')[1];
        if (trackingStatus === '0') {
          // Tracking is declined
          return true;
        }
      }
    }
  }
  // If cookie not found or tracking group not found, return false
  return false;
};

export const analyticsTrackEvent = (
  eventName: string,
  props?: { [key: string]: any },
) => {
  if (isTrackingDeclined()) {
    return;
  }

  trackEvent(eventName, props);
};

/**
 * Checks if a time duration is valid
 * @param duration a time duration string
 * @returns boolean
 */
export const timeDurationIsValid = (duration: string) => {
  if (!duration || !duration.split) {
    return true;
  }

  const durationParts = duration.split(':');
  const h = durationParts[0],
    m = durationParts[1];

  return (
    !duration.includes('_') &&
    ((parseInt(h) === 24 && parseInt(m) === 0) ||
      (parseInt(h) <= 23 && parseInt(m) <= 59))
  );
};

/**
 * Formats number as a condenseed byte phrase
 * https://stackoverflow.com/questions/15900485/correct-way-to-convert-size-in-bytes-to-kb-mb-gb-in-javascript
 * @param bytes Number to format
 * @param decimals Count of decimal precision
 * @returns #.#MB
 */
export const formatBytes = (bytes: any, decimals = 1) => {
  if (bytes === 0) {
    return '0 Bytes';
  }

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};

/**
 * Handles autoscrolling after a message is added to the chat container
 * @param {*} autoScrollDelay the amount of time in ms to delay scrolling after a message has been added
 * @param {*} scrollContainer the scroll container to auto scroll
 */
export const autoScroll = (autoScrollDelay: number, scrollContainer: any) => {
  if (autoScrollDelay && scrollContainer) {
    setTimeout(() => {
      scrollContainer.scrollTop = scrollContainer.scrollHeight;
    }, autoScrollDelay);
  }
};

export { enums };

/**
 * Determines the environment identifier of the employer kentico content item.
 * @param env the environment name
 * @returns the section name for the environment identifier
 */
export const getEREnvironmentName = (env: string) => {
  switch (env) {
    case 'DEVELOPMENT':
      return enums.EREnvironmentCodes.dev;
    case 'ACCEPTANCE':
      return enums.EREnvironmentCodes.itest;
    case 'USER_ACCEPTANCE':
      return enums.EREnvironmentCodes.uat;
    case 'PRODUCTION':
      return enums.EREnvironmentCodes.prod;
    default:
      return enums.EREnvironmentCodes.dev;
  }
};

/**
 * Takes a array of kentico linked items and if an element is the Special Benefits content type, returns the array formatted.
 * Otherwise, the linked array will be returned as is.
 * @param itemValue the array of linked elements
 * @returns a formatted array of special benefits or the array of linked element
 */
export const formatSpecialBenefits: (
  itemValue: ElementModels.IElement<any>[],
) => ISpecialBenefit[] | ElementModels.IElement<any>[] = (itemValue) => {
  return itemValue.map((linkedItem: any) => {
    //If employer content type, format. Otherwise, return linkedElement as is.
    if (linkedItem?.system?.type === enums.ERContentTypes.ERContent) {
      return {
        content: linkedItem.content?.value,
        featureImage:
          linkedItem.featureImage?.value?.length > 0
            ? linkedItem.featureImage.value[0]
            : [],
        thumbnail:
          linkedItem.thumbnail?.value?.length > 0
            ? linkedItem.thumbnail.value[0]
            : [],
        headline: linkedItem.headline?.value,
        intakes: linkedItem.leaveTypes?.value?.map?.((type: any) => type?.name),
        summary: linkedItem.summary?.value,
      };
    }
    return linkedItem;
  });
};

/**
 * Takes a array of kentico linked items and if an element is the Task content type, returns the array formatted.
 * Otherwise, the linked array will be returned as is.
 * @param itemValue the array of linked elements
 * @returns a formatted array of tasks or the array of linked element
 */
export const formatTasks: (
  itemValue: any,
) => ITask | ElementModels.IElement<any> = (itemValue) => {
  return {
    type: itemValue.type?.value,
    name: itemValue.name?.value,
    description: itemValue.description?.value,
    action: itemValue.action?.value?.[0]?.name,
    customTask: itemValue.customTask?.value?.[0]?.name === 'Yes' ? true : false,
    form: itemValue.form?.value,
  };
};

export const formatCustomTask: (
  itemValue: ElementModels.IElement<any>[],
) => IEmployerCustomTask[] | ElementModels.IElement<any>[] = (itemValue) => {
  return itemValue.map((linkedItem: any) => {
    //If employer content type, format. Otherwise, return linkedElement as is.
    if (linkedItem?.system?.type === enums.ERContentTypes.customTask) {
      return {
        type: linkedItem.type?.value?.[0]?.name,
        name: linkedItem.name?.value,
        description: linkedItem.description?.value,
        action: linkedItem.action?.value?.[0]?.name,
        form: linkedItem.form?.value,
        test: linkedItem.test?.value?.map?.((t: any) => t?.value),
      };
    }
    return linkedItem;
  });
};

export const formatPostIntakeContent: (
  itemValue: ElementModels.IElement<any>[],
) => IEmployerNextStep[] | ElementModels.IElement<any>[] = (itemValue) => {
  return itemValue.map((linkedItem: any) => {
    //If employer content type, format. Otherwise, return linkedElement as is.
    if (linkedItem?.system?.type === enums.ERContentTypes.nextStep) {
      return {
        title: linkedItem.title,
        description: linkedItem.description,
        thumbnail: linkedItem.thumbnail,
        optionsTemplate: linkedItem.options_template,
        link: linkedItem.link,
        type: linkedItem.type,
        order: linkedItem.order,
        intakes: linkedItem.intakes,
      };
    }
    return linkedItem;
  });
};

export const formatIntakeList = (item: ElementModels.IElement<any>[]) => {
  const intakeList: string[] = [];
  item.forEach((i: any) => {
    intakeList.push(i.name);
  });

  return intakeList;
};

export const isJSONParsable = (text: string | undefined) => {
  try {
    JSON.parse(text ? text : '');
    return true;
  } catch {
    return false;
  }
};

export const getLocalizedOrDefault = (
  localizedStrings: any,
  localizedName: string,
  defaultStr: string,
) => {
  const found = localizedStrings && localizedName in localizedStrings;
  return found ? localizedStrings[localizedName]?.translatedText : defaultStr;
};

export const getDynamicOrDefault = (
  dynamicContent: IDynamicContent,
  contentName: string,
  medium: 'dcContentFull' | 'dcContentMedium' | 'dcContentShort',
  defaultStr: string,
): string => dynamicContent?.[contentName]?.[medium] ?? defaultStr;

export const getFormattedHoursOfOperation = (
  callCenter: IKenticoData[enums.KenticoDataKeys.callCenter],
  format = 'h a',
) => {
  const TZ = 'America/New_York';
  const hoost = dayjs(callCenter?.hoursOfOperation?.start)
    .tz(TZ, true)
    .format(format);
  const hooet = dayjs(callCenter?.hoursOfOperation?.end)
    .tz(TZ, true)
    .format(format);

  return { hoost, hooet };
};

export const translateLeaveStatuses: (leaveStatus?: string) => string = (
  leaveStatus,
) => {
  if (!leaveStatus) {
    return 'N/A';
  }

  const translationMap: { [key: string]: string } = {
    'Fully Adjudicated - Approved': 'Approved',
    'Fully Adjudicated - Denied': 'Denied',
    'Fully Adjudicated - Mixed Plan Decisions': 'Mixed Decision',
    'Partially Adjudicated': 'Partially Adjudicated',
  };

  const isTranslated = leaveStatus in translationMap;
  return isTranslated ? translationMap[leaveStatus] : leaveStatus;
};

export const getStatusIcon = (props: any) => {
  let statusColor = '';
  if (get(props.claim, 'status') !== undefined) {
    statusColor = get(props.claim, 'status');
  } else {
    statusColor = translateLeaveStatuses(get(props.leave, 'requestStatus'));
  }
  if (statusColor === 'Approved' || statusColor === 'Success') {
    return claimStatusGreen;
  } else if (statusColor === 'Mixed Decision' || statusColor === 'Partial') {
    return claimStatusMix;
  } else if (statusColor === 'Denied') {
    return claimStatusRed;
  } else if (
    statusColor === 'Open' ||
    statusColor === 'Intake in Progress' ||
    statusColor === 'Request Recognized' ||
    statusColor === 'Closed' ||
    statusColor === 'Archived'
  ) {
    return claimStatusBlue;
  } else {
    return claimStatusOrange;
  }
};

export const getRelatedTask = (claimId?: string, tasks?: Array<Task>) => {
  if (claimId && tasks) {
    return tasks.find(
      (t) =>
        t.notificationCaseId === claimId ||
        t.caseInfo.some((cI) => cI.caseId === claimId),
    );
  }
  return null;
};

/**
 * Gets a value in milliseconds that represents the current time plus the
 * provided number of minutes
 * @param {number} minutes The number of minutes
 * @returns {number} The current time plus the provided minutes (in ms)
 */
export const minutesInFuture = (minutes: number) =>
  new Date().getTime() + minutes * 60 * 1000;

/**
 * Gets a value in milliseconds that represents the current time plus the
 * provided number of minutes
 * @param {number} minutes The number of minutes
 * @returns {number} The current time plus the provided minutes (in ms)
 */
export const hoursInFuture = (hours: number) =>
  new Date().getTime() + hours * 60 * 60 * 1000;

export const prefixAndReCamelCase = (prefix: string, name: string) =>
  prefix ? `${prefix}${name.charAt(0).toUpperCase()}${name.slice(1)}` : name;

export const mapReminderToMenuItem = (reminder: {
  type: string;
  reminderId: string;
  creationTimestamp: string;
  extraParameters?: Record<string, unknown>;
}) => {
  switch (reminder.type) {
    case 'SMSPref': {
      return {
        headingText: 'Update Notification Preferences',
        bodyText: 'Please opt into Text Communications',
        icon: ReminderIcons.ChatBubbles,
        reminderId: reminder.reminderId,
        date: reminder.creationTimestamp,
        type: 'SMSPref',
      };
    }
    case 'Generic': {
      const heading = reminder.extraParameters?.headingText
        ? (reminder.extraParameters.headingText as string)
        : '';

      const body = reminder.extraParameters?.bodyText
        ? (reminder.extraParameters.bodyText as string)
        : '';

      return {
        headingText: heading,
        bodyText: body,
        icon: ReminderIcons.ChatBubbles,
        reminderId: reminder.reminderId,
        date: reminder.creationTimestamp,
        type: 'Generic',
      };
    }
    default: {
      return {
        headingText: ``,
        bodyText: ``,
        icon: ReminderIcons.ChatBubbles,
        reminderId: reminder.reminderId,
        date: reminder.creationTimestamp,
        type: reminder.type,
      };
    }
  }
};

export const getReminderData = async () => {
  const communicationPreferences = await getPreferences();

  if (communicationPreferences) {
    const smsPref = communicationPreferences?.communicationPreferences?.find(
      (c) => c.preferenceType === 'Texting',
    );

    const reminders = await getReminders(
      true,
      smsPref?.preferenceValue === 'Opt-In',
    );

    const rems = reminders.finalRemindersArray;

    if (rems.length === 0) {
      return [];
    }

    return rems
      .filter((rem) => !rem.closureTimestamp)
      .map((rem) =>
        mapReminderToMenuItem({
          type: rem.type,
          reminderId: rem.reminderId,
          creationTimestamp: rem.creationTimestamp,
          extraParameters: rem.extraParameters,
        }),
      );
  }

  return [];
};

export const removeNullishValues = (obj: Record<string, any>) => {
  const newObj = { ...obj };
  Object.keys(newObj).forEach((key) => {
    if (newObj[key] === null || newObj[key] === undefined) {
      delete newObj[key];
    }
  });
  return newObj;
};

export const claimStage = (props: any) => {
  const stepNumber = '';
  console.log('props', props);
  console.log('stepNumber', stepNumber);
  return stepNumber;
};

export const bypassLoginRefresh = (
  loginRefreshBypass = true,
  fullRefreshBypass = true,
) => {
  sessionStorage.setItem(
    enums.CacheKeys.login_refresh_bypass,
    loginRefreshBypass.toString(),
  );
  sessionStorage.setItem(
    enums.CacheKeys.full_refresh_bypass,
    fullRefreshBypass.toString(),
  );
};

export function matchesPattern(value: string, pattern: string) {
  // escape special regex characters except '*'
  const escapedPattern = pattern.replace(/[.+?^${}()|[\]\\]/g, '\\$&');

  // replace '*' with '.*' for wildcard matching
  const regexPattern = `^${escapedPattern.replace(/\*/g, '.*')}$`;

  // create a RegExp object
  const regex = new RegExp(regexPattern);

  // test the value against the regex
  return regex.test(value);
}
