import React from 'react';
import { FieldRenderProps, FieldWrapper } from '@progress/kendo-react-form';
import styled from 'styled-components';
import { enums, getHtmlFromMarkdown } from '../../../utils';
import {
  Checkbox,
  Error,
  Input,
  MaskedTextBox,
  Switch,
  TextArea,
  MultiSelect,
} from '../simple-styled-components';
import DatePicker from '../form-field/date-picker';
import DurationPicker from '../form-field/duration-picker';
import Signer from '../form-field/signer';
import WeeklySchedule from '../form-field/weekly-schedule';
import Upload from '../form-field/upload';
import LeavePlanGraph from '../form-field/leave-plan-graph';
import LoadingIndicator from '../form-field/loading-indicator';
import RadioButtonGroup from '../form-field/radio-button-group';
import TimePicker from '../form-field/time-picker';
import ClaimsDropDownList from '../../base/claims-drop-down-list';
import DropDownList from '../form-field/dropdown-list';
import { Label as KendoLabel } from '@progress/kendo-react-labels';
import Label from '../form-field/label';
import { FormContext, IFormContext } from '../../../contexts/form-context';
import { rem } from '../../../style';
import { Elements } from '@kontent-ai/delivery-sdk';
import * as options from '../options';
import { useAutosaveGetFieldValue } from '../../../hooks/use-data';
import { processNavigationObject } from '../form/utils';
import { useLocation } from 'react-router-dom';

export const componentMap: {
  [key: string]: any;
} = {
  Checkbox,
  DatePicker,
  DropDownList,
  Input,
  LeavePlanGraph,
  MaskedTextBox,
  RadioButtonGroup,
  ClaimsDropDownList,
  Signer,
  Switch,
  TextArea,
  TimePicker,
  WeeklySchedule,
  Upload,
  MultiSelect,
  LoadingIndicator,
  DurationPicker,
};

export const simpleComponentList = [
  'Checkbox',
  'Error',
  'Input',
  'MaskedTextBox',
  'Switch',
  'TextArea',
  'TimePicker',
];

export interface IFormFieldWrapperProps extends FieldRenderProps {
  fieldOptionsTemplate: options.IFieldOptions;
  currentStepIndex: number;
  moveToStep?: Function;
}

export interface IHandleFormFieldValueChangeParams {
  fieldOptionsTemplate: options.IFieldOptions;
  formContext: { [name: string]: any };
  formOnChange: (
    name: string,
    options: {
      value: any;
    },
  ) => void;
  formValueGetter: (name: string) => any;
  lastKnownValue: any;
  setLastKnownValue: React.Dispatch<any>;
  lastKnownName: string;
  setLastKnownName: React.Dispatch<string>;
  touched: boolean;
  value: any;
  name: string;
}

export const handleFormFieldValueChange = ({
  fieldOptionsTemplate,
  formContext,
  formOnChange,
  formValueGetter,
  lastKnownValue,
  setLastKnownValue,
  touched,
  value,
  lastKnownName,
  setLastKnownName,
  name,
}: IHandleFormFieldValueChangeParams) => {
  if (fieldOptionsTemplate.updateFieldsOnChange) {
    if (
      touched &&
      !!value &&
      value !== lastKnownValue &&
      name === lastKnownName
    ) {
      // update field on change template
      const ufoct = fieldOptionsTemplate.updateFieldsOnChange;

      Object.keys(ufoct).forEach((k: string) => {
        // option value template
        const ovt = ufoct[k];
        let ov = ovt;

        // only process ovt if it's a string beginning with an option replacement directive ($)
        if (ovt !== null && typeof ovt === 'string' && ovt.charAt(0) === '$') {
          ov = options.getOptionValue(ovt, formValueGetter, formContext);
        }

        formOnChange(k, { value: undefined });
        setTimeout(() => formOnChange(k, { value: ov }));
      });
    }

    setLastKnownName(name);
    setLastKnownValue(value);
  }
};

const getFlagMap = (flags: Elements.MultipleChoiceElement) => {
  return {
    card:
      flags?.value.filter((f) => f.codename === enums.FormFlags.card).length >
      0,
    plainLabel:
      flags?.value.filter((f) => f.codename === enums.FormFlags.plainLabel)
        .length > 0,
    required:
      flags?.value.filter((f) => f.codename === enums.FormFlags.required)
        .length > 0,
  };
};

const FieldCard = styled.div`
  background-color: white;
  border: 0;
  border-radius: 8px;
  box-shadow: 0 -3px 12px #e0e7ed;
  padding: ${rem(20)};
`;

const handleFormFieldKeyDown = (e: React.KeyboardEvent<any>) => {
  if (e.code === 'Enter' && (e.target as HTMLElement).nodeName !== 'TEXTAREA') {
    e.preventDefault();
  }
};

export const FormFieldWrapper = (p: IFormFieldWrapperProps) => {
  const {
    id,
    validationMessage,
    visited,
    formFieldType,
    formFieldLabel,
    formFieldLabelDetails,
    fieldFlags,
    fieldOptionsTemplate,
    required,
    touched,
    value,
    processedProps,
    formOptionsTemplate,
    formValueGetter,
    formOnChange,
    reprocessProps,
    currentStepIndex,
    moveToStep,
    onChange: kendoOnChange,
    onBlur: kendoOnBlur,
    name,
    ...others
  } = p;
  const FieldComponent = componentMap[formFieldType];
  const flags = getFlagMap(fieldFlags);
  const [lastKnownName, setLastKnownName] = React.useState(name);
  const [lastKnownValue, setLastKnownValue] = React.useState(value);
  const { formContext, setFormContext } = React.useContext(FormContext);
  const location = useLocation();
  const { lastPath: formName } = processNavigationObject({
    location,
    history: undefined as any,
    match: undefined as any,
  });
  const { data: autoSaveFieldValue } = useAutosaveGetFieldValue(formName, name);
  const [isAutoSaveValueUsed, setIsAutoSaveValueUsed] = React.useState(false);

  const onChange = React.useCallback(
    (e: { target?: any; value?: any }) => {
      // sometimes the value is set to undefined, probably a kendo react bug in v4
      if (
        !isAutoSaveValueUsed &&
        !!autoSaveFieldValue &&
        touched &&
        (e?.target?.value === null || e?.target?.value === undefined) &&
        (e?.value === null || e?.value === undefined)
      ) {
        setIsAutoSaveValueUsed(true);
        kendoOnChange({ target: { value: autoSaveFieldValue } });
        return;
      }

      setIsAutoSaveValueUsed(false);

      if (
        currentStepIndex < (formContext.stepIndexWithLeavePlanGraph || -1) &&
        formContext.currentLeavePlan
      ) {
        /**
         * At this point, we know:
         *
         * 1. There is a currentLeavePlan on formContext
         * 2. We are on a step before the step with a leave plan graph
         * 3. They have changed a field
         *
         * So, delete the currentLeavePlan from formContext
         */
        setFormContext((c: IFormContext) => {
          // new current
          const nc = { ...c };
          delete nc.currentLeavePlan;
          return nc;
        });
      }

      // propogate any further changes up the chain
      kendoOnChange(e);
    },
    [
      autoSaveFieldValue,
      currentStepIndex,
      formContext.currentLeavePlan,
      formContext.stepIndexWithLeavePlanGraph,
      isAutoSaveValueUsed,
      kendoOnChange,
      setFormContext,
      touched,
    ],
  );

  // TODO autosave: use this if updating from kendoOnChange to autosave
  const onBlur = React.useCallback(() => {
    handleFormFieldValueChange({
      fieldOptionsTemplate,
      formContext,
      formOnChange,
      formValueGetter,
      lastKnownValue,
      setLastKnownValue,
      lastKnownName,
      setLastKnownName,
      touched,
      value,
      name,
    });
    kendoOnBlur();
  }, [
    name,
    fieldOptionsTemplate,
    formContext,
    formOnChange,
    formValueGetter,
    lastKnownValue,
    lastKnownName,
    touched,
    value,
    kendoOnBlur,
  ]);

  React.useEffect(() => {
    reprocessProps();
  }, [reprocessProps, value]);

  React.useEffect(() => {
    if (
      !touched &&
      !value &&
      !isAutoSaveValueUsed &&
      autoSaveFieldValue !== undefined &&
      autoSaveFieldValue !== null
    ) {
      setIsAutoSaveValueUsed(true);
      kendoOnChange({ target: { value: autoSaveFieldValue } });
    }
  }, [
    name,
    isAutoSaveValueUsed,
    value,
    kendoOnChange,
    autoSaveFieldValue,
    touched,
  ]);

  // only WeeklySchedule needs this prop at the moment
  // only LeavePlanGraph needs the form to check the optionsTemplate
  if (
    formFieldType === enums.FormFieldTypes.weeklyschedule ||
    formFieldType === enums.FormFieldTypes.claimsdropdownlist
  ) {
    others.processedProps = processedProps;
  } else if (formFieldType === enums.FormFieldTypes.leaveplangraph) {
    others.formOptionsTemplate = formOptionsTemplate;
    others.moveToStep = moveToStep;
  }

  // include formValueGetter on custom fields
  if (!simpleComponentList.includes(formFieldType)) {
    others.formValueGetter = formValueGetter;
  }

  return (
    <FieldWrapper
      style={{
        margin: '1.5rem 0rem',
      }}
    >
      {flags.plainLabel ? (
        formFieldLabel &&
        formFieldLabel.trim().length > 0 && (
          <KendoLabel editorId={id}>
            <div
              dangerouslySetInnerHTML={getHtmlFromMarkdown(formFieldLabel)}
            />
          </KendoLabel>
        )
      ) : (
        <Label
          editorId={id}
          formFieldLabel={formFieldLabel}
          formFieldLabelDetails={formFieldLabelDetails}
        />
      )}
      <div className={'k-form-field-wrap'}>
        {flags.card ? (
          <FieldCard>
            <FieldComponent
              id={id}
              value={value}
              touched={touched}
              onKeyDown={handleFormFieldKeyDown}
              {...others}
              onChange={onChange}
              onBlur={onBlur}
              name={name}
            />
          </FieldCard>
        ) : (
          <FieldComponent
            id={id}
            value={value}
            touched={touched}
            onKeyDown={handleFormFieldKeyDown}
            {...others}
            onChange={onChange}
            onBlur={onBlur}
            name={name}
          />
        )}
        {visited && touched && validationMessage && (
          <Error id={`${id}_error`}>{validationMessage}</Error>
        )}
      </div>
    </FieldWrapper>
  );
};
