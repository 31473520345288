import { PersonDataContext } from '../../../contexts/person-data-context';
import React from 'react';
import { generateParentNTNsFromCases } from '../../../utils';
import { DropDownListOpenEvent } from '@progress/kendo-react-dropdowns';
import DropDownList from '../../forms/form-field/dropdown-list';
import { Form } from 'dvt-phoenix-tasks-sdk';
import envConfig from '../../../utils/env-config';
import * as enums from '../../../utils/enums';
import { taskModelType, UserInitiatedModal } from './modals';
import { TaskType } from '..';
import { Accommodation, Claim, Leave } from '../../../data-models';

enum AuthTypes {
  iam = 'iam',
  cognito = 'cognito',
  custom = 'custom',
}
type props = {
  taskCodeName: string;
  rc: any;
  tasks: TaskType[];
};

type GeneratedTypes = {
  NTNId: string;
  eid: string;
  claims: Claim[];
  leaves: Leave[];
  accommodations: Accommodation[];
  isIntegrated: boolean;
};

const alreadySubmittedUIT = (codename: string, tasks: TaskType[]): boolean => {
  const filtered = tasks.filter((t) => t.taskCodeName === codename);
  const now = new Date();
  const submitted = filtered.find((t) => {
    if (t.taskSource === 2 && t.createdDate) {
      const createdDate = new Date(t.createdDate);

      const timeDiff = now.getTime() - createdDate.getTime();

      // 24 hours (24 * 60 * 60 * 1000 = 86400000 milliseconds)
      return timeDiff <= 86400000;
    }
    return false;
  });

  return submitted !== undefined;
};

const compileCaseList = (ntn: GeneratedTypes | undefined) => {
  const cases: { caseId: string; leaveReason: string }[] = [];

  (ntn?.accommodations || []).forEach((accommodation) => {
    cases.push({
      caseId: accommodation.accommodationId ?? '',
      leaveReason: 'Accommodation Case',
    });
  });

  (ntn?.claims || []).forEach((claim) => {
    cases.push({
      caseId: claim.claimId ?? '',
      leaveReason: 'Group Disability Claim',
    });
  });

  (ntn?.leaves || []).forEach((leave) => {
    cases.push({
      caseId: leave.leaveId ?? '',
      leaveReason: leave.reason ?? '',
    });
  });

  return cases;
};

export const UserInitiatedTask = ({ taskCodeName, rc, tasks }: props) => {
  const { personData } = React.useContext(PersonDataContext);
  const [ntn, setNtn] = React.useState<GeneratedTypes | undefined>(undefined);
  const [submitted, setSubmitted] = React.useState<taskModelType>(
    alreadySubmittedUIT(taskCodeName, tasks) ? 'fail' : '',
  );
  const generatedNtns: GeneratedTypes[] = generateParentNTNsFromCases(
    personData?.cases,
  );
  const parentNtns = generatedNtns.map((e) => e.NTNId);

  return (
    <div>
      {!submitted && (
        <>
          <span className="tw-pr-6">Please select your notification: </span>
          <DropDownList
            data={parentNtns}
            itemRender={(e) => <>{e.type.toString}</>}
            onOpen={(e: DropDownListOpenEvent) => {
              // When wrapped in conversational UI, dropdown intermittently does not recieve focus onOpen.
              // This causes onBlur to not fire, leaving the popup open when clicking outside the parent elem.
              // Fixed in lastest 5.5.0 dev build, but this is a workaround until released in a stable build.
              e.target.focus();
            }}
            onChange={(e) =>
              setNtn(generatedNtns.find((p) => p.NTNId === e.value))
            }
          />
        </>
      )}
      {Boolean(ntn) && !submitted && (
        <div className="tw-bg-white">
          <Form
            customerNumber={personData?.person?.customerNumber ?? ''}
            companyCode={personData?.person?.companyCode ?? ''}
            workState={personData?.person?.workState ?? ''}
            environment={envConfig.upEnvironment}
            notificationCaseId={ntn?.NTNId ?? ''}
            taskId={'0'}
            taskCodename={taskCodeName}
            cases={compileCaseList(ntn)}
            taskCategory=""
            createdBy={personData?.person?.eid ?? ''}
            downloadUrl=""
            // formItems
            // howSubmittedView: submittedViewStatuses.includes(task.taskStatusId),
            useKenticoPreviewMode={
              rc[enums.CodeNames.refTasksPreviewFlag] === 'true'
            }
            // downloadUrl={formUrl}
            onSubmit={() => setSubmitted('success')}
            onError={() => setSubmitted('error')}
            // rawDynamicFields={task.dynamicFields}
            dualTask={undefined}
            apiConfig={{
              authType: AuthTypes.iam,
              invokeUrl: rc[enums.RemoteConfigKeys.absenceApiInvokeUrl],
              customHeaders: {
                userId: `${personData?.person?.firstName} ${personData?.person?.lastName}`,
                assigneeId: '2',
                portalSource: 'Employee Portal',
              },
              uploadUrlsPath: `/ab/employee-tasks/0/upload-urls`,
              submitFormPath: `/ab/employee-tasks/0/form-data`,
              createTaskPath: `/ab/employee-tasks/createTask`,
              dynamicDataPath: `/ab/employee-tasks/employee-tasks/case/${ntn?.NTNId}/dynamicContent/${taskCodeName}`,
            }}
          />
        </div>
      )}
      {submitted && (
        <UserInitiatedModal type={submitted} codename={taskCodeName} />
      )}
    </div>
  );
};
