import { useParams } from 'react-router-dom';
import Payments from '..';

interface PaymentContainerProps {
  failedWithData?: boolean;
}

export const PaymentsContainer = (p: PaymentContainerProps) => {
  const { ntn } = useParams<{ ntn?: string }>();

  return <Payments failedWithData={p.failedWithData} routedViaNTN={ntn} />;
};
