import { useContext, useState } from 'react';
import { PersonDataContext } from '../../../../../../contexts/person-data-context';
import { get } from '../../../../../../utils';
import {} from '../../../../../documents';
import { IGeneratedCase } from '../../..';
import { Document } from '../../../../../../data-models';
import { PageChangeEvent } from '@progress/kendo-react-data-tools';
import PagerSection from '../../../../pager-section';
import { LetterItem } from '.././letters/letter-item';
import { Link } from 'react-router-dom';
import {
  MultipleCardContainer,
  UploadButton,
} from '../../../components/simple-components';

type Props = {
  claim: IGeneratedCase;
};
export const OverviewDocuments = ({ claim }: Props) => {
  const { personData } = useContext(PersonDataContext);

  const documents: Document[] = get(personData, 'documents.documents', [])
    .filter(
      (letter: Document) =>
        claim.claims.find(
          (c) => c.caseId === letter.claimId || c.caseId === letter.leaveId,
        ) ||
        claim.leaves.find(
          (l) => l.caseId === letter.claimId || l.caseId === letter.leaveId,
        ) ||
        claim.accommodations.find(
          (a) => a.caseId === letter.claimId || a.caseId === letter.leaveId,
        ),
    )
    .sort((a: Document, b: Document) => {
      return (b.receivedDate ?? '').localeCompare(a.receivedDate ?? '');
    });

  const [page, setPage] = useState({
    skip: 0,
    take: 3,
  });

  const handlePageChange = (e: PageChangeEvent) => {
    setPage({
      skip: e.skip,
      take: e.take,
    });
  };

  const { skip, take } = page; //Used for Pagination

  return (
    <>
      <MultipleCardContainer id="documents-card">
        {documents && documents?.length > 0 ? (
          <>
            {documents.slice(skip, skip + take).map((document, index) => (
              <LetterItem item={document} index={index} key={index} />
            ))}
            <div className="tw-flex-grow"></div>
            <PagerSection
              skip={skip}
              take={take}
              handlePageChange={handlePageChange}
              elements={documents}
            />
            <Link to="/app/upload">
              <UploadButton>Upload another document</UploadButton>
            </Link>
          </>
        ) : (
          <div className="tw-flex tw-flex-col tw-h-full">
            <div className="tw-font-semibold tw-text-sm">
              You currently have no documents.
            </div>
            <div className="tw-flex-grow"></div>
            <Link to="/app/upload" className="tw-mb-5">
              <UploadButton>Upload a document</UploadButton>
            </Link>
          </div>
        )}
      </MultipleCardContainer>
    </>
  );
};
