import * as dm from '../../../../data-models';

export const isTaskNotCompleted = (task: dm.Task): boolean => {
  return (
    task.taskStatusId !== 7 &&
    task.taskStatusId !== 9 &&
    task.taskStatusId !== 10
  );
};

const filterTasks = (
  tasks: dm.Task[],
  category: string,
  isTaskNotCompleted: (task: dm.Task) => boolean,
): dm.Task[] => {
  return tasks.filter(
    (t) => t.taskCategory === category && isTaskNotCompleted(t),
  );
};

const sortByDueDate = (a: dm.Task, b: dm.Task) =>
  new Date(a.taskDueDate).getTime() - new Date(b.taskDueDate).getTime();

export const sortAssignedTasks: Function = (tasks: dm.Task[]): dm.Task[] => {
  const requiredTasksNotComplete = filterTasks(
    tasks,
    'Required',
    isTaskNotCompleted,
  );
  requiredTasksNotComplete.sort(sortByDueDate);

  const recommendedTasksNotComplete = filterTasks(
    tasks,
    'Recommended',
    isTaskNotCompleted,
  );
  recommendedTasksNotComplete.sort(sortByDueDate);

  return requiredTasksNotComplete.concat(recommendedTasksNotComplete);
};

export const checkForRequiredTasks: Function = (tasks: dm.Task[]): boolean => {
  const requiredTasksNotComplete = filterTasks(
    tasks,
    'Required',
    isTaskNotCompleted,
  );

  return requiredTasksNotComplete?.length ? true : false;
};

export const sortReceivedTasks: Function = (tasks: dm.Task[]): dm.Task[] => {
  const status5Tasks = [...tasks].filter((t) => t.taskStatusId === 5);
  const status7Tasks = [...tasks].filter((t) => t.taskStatusId === 7);

  status5Tasks.sort(sortByDueDate);
  status7Tasks.sort(sortByDueDate);

  return [...status5Tasks, ...status7Tasks];
};
