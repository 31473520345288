import React from 'react';
import { ExpandedType, IGeneratedCase } from '../..';
import { get } from '../../../../../utils';
import {
  AccommodationCardItem,
  CardFooterSection,
  ClaimsCardItems,
  ConcurrentLeaveCardItems,
  LeaveCardItems,
} from '../../status-tracker/card-items';
import { SectionMappingProps, TabCardsNonOverview } from '../tab-cards';
import { CardItemContainer } from '../../components/simple-components';

const getConcurrentLeaves = (claim: IGeneratedCase) => {
  const leavePlan = get(claim, 'leaves');
  let concurrentLeave;
  for (const element of leavePlan) {
    if (element?.deniedLeavePlans?.length > 0) {
      for (const element2 of element.deniedLeavePlans) {
        if (element2.adjudicationReason === 'Not Concurrent') {
          concurrentLeave = element2.adjudicationReason;
        }
      }
    }
  }

  return concurrentLeave;
};

export const DetailsTab: ExpandedType = (props) => {
  const { claim, NTNId, EETasks, ERTasks, areTasksDue } = props;
  const showDenied = getConcurrentLeaves(claim);

  const compileSectionMapping = React.useCallback(() => {
    const sectionMapping: SectionMappingProps['sectionMapping'] = {};

    if (claim?.leaves?.length > 0) {
      sectionMapping.leaves = {
        title: 'Leave Coverages',
        headerLinkName: 'Go to available time',
        headerLink: '/app/time',
        popupContent:
          'Leave coverages could protect your job and/or income while you are out of work. You may be eligible for federal, state, or corporate leaves. Please click ‘View Details’ and review your letter(s) for more information about your leave coverages.',
        component: (
          <div className="tw-pb-2">
            <CardItemContainer>
              <LeaveCardItems
                leaves={claim.leaves}
                ERTasks={ERTasks}
                EETasks={EETasks}
              />
            </CardItemContainer>
          </div>
        ),
      };
    }

    if (showDenied === 'Not Concurrent') {
      sectionMapping.concurrentLeaves = {
        title: 'Concurrent Leave Coverages',
        headerLinkName: '',
        headerLink: '',
        popupContent:
          'Leave coverages could protect your job and/or income while you are out of work. You may be eligible for federal, state, or corporate leaves. Please click ‘View Details’ and review your letter(s) for more information about your leave coverages.',
        component: (
          <div className="tw-pb-2">
            <CardItemContainer>
              <ConcurrentLeaveCardItems
                leaves={claim.leaves}
                ERTasks={ERTasks}
              />
            </CardItemContainer>
          </div>
        ),
      };
    }

    if (claim?.claims?.length > 0) {
      sectionMapping.claims = {
        title: 'Disability Benefits',
        headerLinkName: '',
        headerLink: '',
        popupContent:
          'Disability benefits may replace a portion of your income due to injury or illness. You may be eligible for disability through your employer or your state, depending on where you reside or work. Please click ‘View Details’ and review your letter(s) for more information about your disability benefits.',
        component: (
          <div className="tw-pb-2">
            <CardItemContainer>
              <ClaimsCardItems
                claims={claim.claims}
                ERTasks={ERTasks}
                EETasks={EETasks}
              />
            </CardItemContainer>
          </div>
        ),
      };
    }

    if (claim?.accommodations?.length > 0) {
      sectionMapping.accommodations = {
        title: 'Accommodations',
        headerLinkName: '',
        headerLink: '',
        popupContent:
          'Accommodations are covered under the Americans with Disabilities Act (ADA). Please click "View Details" and review your letter(s) for more information about your accommodations.',
        component: (
          <div className="tw-pb-2">
            <CardItemContainer>
              <AccommodationCardItem accommodations={claim.accommodations} />
            </CardItemContainer>
          </div>
        ),
      };
    }

    return sectionMapping;
  }, [
    EETasks,
    ERTasks,
    claim.accommodations,
    claim.claims,
    claim.leaves,
    showDenied,
  ]);

  return (
    <>
      <TabCardsNonOverview sectionMapping={compileSectionMapping()} />
      <CardFooterSection {...claim} NTNId={NTNId} areTasksDue={areTasksDue} />
    </>
  );
};
