import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import {
  enums,
  get,
  parseJSONOrDefault,
  timeDurationIsValid,
} from '../../../utils';

dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);

type IValidator = (v: any, vm: string, params: any[]) => string;

export const defaultValidationMessages = {
  hasContent: enums.ValidationErrors.hasContent,
  signerHasContent: enums.ValidationErrors.signerHasContent,
  weeklyScheduleHasContent: enums.ValidationErrors.weeklyScheduleHasContent,
  noAlpha: enums.ValidationErrors.noAlpha,
  matchesPattern: enums.ValidationErrors.matchesPattern,
  dateGreaterThan: enums.ValidationErrors.dateGreaterThan,
  dateLesserThan: enums.ValidationErrors.dateLesserThan,
  dateGreaterThanOrEqual: enums.ValidationErrors.dateGreaterThanOrEqual,
  dateLesserThanOrEqual: enums.ValidationErrors.dateLesserThanOrEqual,
  isValidDateSelection: enums.ValidationErrors.isValidDate,
  noSpecialChars: enums.ValidationErrors.noSpecialChars,
};

export const isValidDateSelection: IValidator = (
  v,
  validationMessage,
  _params,
) => {
  const d = dayjs(v).format('YYYY-MM-DD');

  const re = /([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))/;
  const r = re.test(d);

  return r ? '' : validationMessage;
};

const hasContentFieldTypeMessageMap = {
  Signer: defaultValidationMessages.signerHasContent,
};

export const signerHasContent: IValidator = (v, validationMessage, _params) => {
  const returnMessage =
    validationMessage === defaultValidationMessages.hasContent
      ? defaultValidationMessages.signerHasContent
      : validationMessage;

  const isSigned = get(v, 'signed', false);
  const hasInsuredsName = get(v, 'insuredsName', '').length > 0;
  const hasSignedDate = get(v, 'signedDate', '').length > 0;

  const onBehalfSelected = get(v, 'onBehalfSelected', false);
  const hasSignersName = onBehalfSelected
    ? get(v, 'signersName', '').length > 0
    : true;
  const hasSignersRelationship = onBehalfSelected
    ? get(v, 'signersRelationship', '').length > 0
    : true;

  const baseFieldsFilled = isSigned && hasInsuredsName && hasSignedDate;
  const behalfOfFilled = onBehalfSelected
    ? hasSignersName && hasSignersRelationship
    : true;

  return baseFieldsFilled && behalfOfFilled ? '' : returnMessage;
};

export const weeklyScheduleHasContent: IValidator = (
  v,
  validationMessage,
  _params,
) => {
  const returnMessage =
    validationMessage === defaultValidationMessages.hasContent
      ? defaultValidationMessages.weeklyScheduleHasContent
      : validationMessage;

  if ((v?.weeks && !Array.isArray(v.weeks)) || !Array.isArray(v.weeks[0])) {
    return returnMessage;
  }

  const weeksInvalid = v.weeks.some((w: string[]) =>
    w.some((cv) => !timeDurationIsValid(cv)),
  );

  return weeksInvalid ? returnMessage : '';
};

const validatePrimitiveTypes = (value: any, returnMessage: string) => {
  if (typeof value === 'string') {
    return value?.length > 0 ? '' : returnMessage;
  } else if (value?.toISOString && !isNaN(value.getTime())) {
    return value.toISOString() === new Date(0).toISOString()
      ? returnMessage
      : '';
  } else if (typeof value === 'boolean') {
    return value ? '' : returnMessage;
  }

  // if we get this far without returning, check if we can parse as JSON
  try {
    return Object.keys(parseJSONOrDefault(JSON.stringify(value))).length === 0
      ? returnMessage
      : '';
  } catch (e) {
    return returnMessage;
  }
};

export const hasContent: IValidator = (value, validationMessage, params) => {
  const formFieldType: string = params[0];
  const defaultMessage = get(
    hasContentFieldTypeMessageMap,
    formFieldType,
    defaultValidationMessages.hasContent,
  );
  const returnMessage = validationMessage || defaultMessage;

  // handle undefinedish
  if (value === undefined || value === null) {
    return returnMessage;
  }

  // handle custom objects
  if (formFieldType === enums.FormFieldTypes.signer) {
    return signerHasContent(value, returnMessage, []);
  } else if (formFieldType === enums.FormFieldTypes.weeklyschedule) {
    return weeklyScheduleHasContent(value, returnMessage, []);
  }

  //Handle arrays
  if (Array.isArray(value)) {
    return value.length > 0 ? '' : returnMessage;
  }

  // handle primitives and others
  return validatePrimitiveTypes(value, returnMessage);
};

export const matchesPattern = (
  value: any,
  validationMessage = defaultValidationMessages.matchesPattern,
  params: any[] = [],
) => {
  const re = new RegExp(params[0], 'i');
  const r = re.test(value?.toString());

  return r ? '' : validationMessage;
};

export const isAValidDate = (value: any, validationMessage: string) => {
  const d = new Date(value);
  const validDate = d instanceof Date && !isNaN(d.valueOf());
  return validDate ? '' : validationMessage;
};

export const timeGreaterThan = (
  value: any,
  validationMessage: string,
  params: any[] = [],
) => {
  const date1 = new Date(value);
  const date2 = new Date(params[0]);
  const greater = date1.getTime() > date2.getTime();
  return greater ? '' : validationMessage;
};

export const noAlpha = (
  value: string,
  validationMessage = defaultValidationMessages.noAlpha,
  _params?: any[],
) => (!value || !/[a-zA-Z]/g.test(value) ? '' : validationMessage);

export const noSpecialChars = (
  value: string,
  validationMessage = defaultValidationMessages.noSpecialChars,
  _params?: any[],
) => (!value || !/[="><]/g.test(value) ? '' : validationMessage);

// date validators
export const dateGreaterThan: IValidator = (value, validationMessage, p) =>
  dayjs(value).isAfter(dayjs(p[0]), 'date') ? '' : validationMessage;
export const dateLesserThan: IValidator = (value, validationMessage, p) =>
  dayjs(value).isBefore(dayjs(p[0]), 'date') ? '' : validationMessage;
export const dateGreaterThanOrEqual: IValidator = (
  value,
  validationMessage,
  p,
) => (dayjs(value).isSameOrAfter(dayjs(p[0]), 'date') ? '' : validationMessage);
export const dateLesserThanOrEqual: IValidator = (
  value,
  validationMessage,
  p,
) => {
  return dayjs(value).isSameOrBefore(dayjs(p[0]), 'date')
    ? ''
    : validationMessage;
};
export const dateBeforeClaimStartDate: IValidator = (
  value,
  validationMessage,
  p,
) => {
  validationMessage = `Please note that the date of the absence cannot be before the start date of the claim (${dayjs(
    p[0],
  ).format('MM/DD/YYYY')}). Kindly adjust the date accordingly.`;
  return dayjs(value).isSameOrAfter(dayjs(p[0]), 'date')
    ? ''
    : validationMessage;
};
export const dateAfterClaimEndDate: IValidator = (
  value,
  validationMessage,
  p,
) => {
  validationMessage = `Please note that the date of the absence cannot be after the end date of the claim (${dayjs(
    p[0],
  ).format('MM/DD/YYYY')}). Kindly adjust the date accordingly.`;
  return dayjs(value).isSameOrBefore(dayjs(p[0]), 'date')
    ? ''
    : validationMessage;
};

export const customValidatorsMap: {
  [key: string]: any;
} = {
  hasContent,
  matchesPattern,
  noAlpha,
  dateGreaterThan,
  dateLesserThan,
  dateGreaterThanOrEqual,
  dateLesserThanOrEqual,
  isAValidDate,
  timeGreaterThan,
  noSpecialChars,
  dateBeforeClaimStartDate,
  dateAfterClaimEndDate,
};

export const handleValidation = (
  optionsTemplate: { [key: string]: any },
  isRequired: boolean,
  value: any,
  formFieldType: string | undefined,
) => {
  // if (process.env.REACT_APP_MOCK) {
  //   isRequired = false;
  // }
  let returnMessage = '';
  const customValidators = optionsTemplate.validators || [];

  const validationMessages =
    optionsTemplate.validationMessages || defaultValidationMessages;

  if (isRequired) {
    returnMessage = hasContent(value, validationMessages.hasContent, [
      formFieldType,
    ]);
  }

  if (returnMessage.length === 0 && formFieldType === 'DatePicker') {
    returnMessage = isValidDateSelection(
      value,
      validationMessages.isValidDateSelection,
      [],
    );
  }

  // handle custom validators
  if (returnMessage.length === 0 && customValidators.length > 0) {
    returnMessage = getMessageForCustomValidators(
      customValidators,
      value,
      validationMessages,
    );
  }

  return returnMessage;
};

const getMessageForCustomValidators = (
  customValidators: any[],
  value: any,
  validationMessages: { [key: string]: string },
) => {
  let returnMessage = '';
  // validators clone
  const vc: any[] = JSON.parse(JSON.stringify(customValidators));

  // shift through validators
  while (vc.length > 0 && returnMessage.length === 0) {
    const vKey = vc.shift();
    let v: IValidator;
    let params: any[] = [];
    let validationMessage = '';

    if (typeof vKey !== 'string') {
      // assume it's an object that matches expectations
      v = customValidatorsMap[vKey.name];
      params = vKey.params;
      validationMessage = validationMessages[vKey.name];
      if (vKey.property) {
        value = value[vKey.property];
      }
    } else {
      v = customValidatorsMap[vKey];
      validationMessage = validationMessages[vKey];
    }

    // execute validator
    returnMessage = v(value?.toString(), validationMessage, params);
  }

  return returnMessage;
};
