export const docTypes = [
  {
    text: 'Medical',
    id: 1,
    items: [
      { text: 'Medical Certification', id: 2 },
      { text: 'Medical Records', id: 3 },
      { text: 'Fit For Duty Form', id: 4 },
    ],
  },
  {
    text: 'Authorization',
    id: 5,
    items: [
      { text: 'Third Party Authorization', id: 6 },
      { text: 'Authorization Form - GD', id: 7 },
    ],
  },
  {
    text: 'Other',
    id: 8,
  },
];
