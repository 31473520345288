import React from 'react';
import { get, getStatusIcon } from '../../../../../utils';
import { Claim, Task } from '../../../../../data-models';
import dayjs from 'dayjs';
import { Items, Item, dateFormat, StatusFlag } from './shared-styles';
import ClaimStatusInfo from '../../../../../images/claimStatusInfoIcon.svg';
import { Popover } from '@progress/kendo-react-tooltip';
import styled from 'styled-components';
import { getClaimStatusConfig } from '../../../../../utils/remote-config-manager';

interface IClaimCardItem {
  claim: Claim;
  ERTasks: Array<Task>;
  EETasks: Array<Task>;
}

const ClaimsCardItems: (props: {
  claims: Array<Claim>;
  ERTasks: Array<Task>;
  EETasks: Array<Task>;
}) => React.ReactElement = (props) => (
  <Items>
    {get(props, 'claims', []).map((claim: Claim, i: number) => (
      <ClaimCardItem
        claim={claim}
        key={`${claim.claimId}-item-${i}`}
        ERTasks={props.ERTasks}
        EETasks={props.EETasks}
      />
    ))}
  </Items>
);

const getStatusText = async (props: any, setStatusMessage: Function) => {
  const statusConfigs = await getClaimStatusConfig();
  const foundStatus = statusConfigs.find(
    (s) => s.status === get(props.claim, 'status', 'N/A'),
  );
  if (foundStatus && foundStatus.message) {
    setStatusMessage(foundStatus.message);
  }
};

const ClaimStatusInfoIcon = styled.button`
  background: #fff;
  border: 0px;
  margin-left: 6px;
  bottom: 1px;
`;

export const ClaimCardItem: (props: IClaimCardItem) => React.ReactElement = (
  props,
) => {
  const createdDate = props.claim?.createdDate || null;
  const anchor = React.useRef<HTMLDivElement | null>(null);

  const [statusMessage, setStatusMessage] = React.useState();
  const [show, setShow] = React.useState(false);

  React.useEffect(() => {
    getStatusText(props, setStatusMessage);
  }, [props]);

  return (
    <Item>
      <div className="tw-flex tw-flex-row tw-items-center">
        <div className="tw-flex tw-flex-col md:tw-flex-row tw-gap-2 tw-w-1/2 md:tw-w-2/3 md:tw-mr-5">
          <div className="tw-flex tw-flex-col tw-gap-2 md:tw-w-1/2">
            <div className="tw-text-base tw-font-semibold">Claim</div>
            <div className="tw-text-base">{props.claim.claimType}</div>
          </div>
          {dayjs(createdDate).isValid() ? (
            <div className="tw-flex tw-flex-col tw-gap-2">
              <div className="tw-text-base tw-font-semibold">Created</div>
              <div className="tw-text-base">
                {dayjs(createdDate).format(dateFormat)}
              </div>
            </div>
          ) : null}
        </div>
        <div className="tw-flex tw-flex-row tw-justify-end tw-items-center tw-w-1/2 md:tw-w-1/3">
          <div
            id={'claim-status-id-' + props.claim.caseId}
            ref={anchor}
            onMouseEnter={() => setShow(true)}
            onMouseLeave={() => setShow(false)}
          >
            <StatusFlag
              className="tw-flex tw-flex-nowrap"
              color={get(props.claim, 'status', 'None')
                .replace(/ /g, '')
                .toLowerCase()}
            >
              <img
                style={{ paddingRight: '5px' }}
                src={getStatusIcon(props)}
                alt=""
              />
              <div className="tw-inline-block tw-text-wrap md:tw-text-nowrap tw-align-middle">
                {get(props.claim, 'status', 'N/A')}
              </div>
              <ClaimStatusInfoIcon>
                <img alt="" src={ClaimStatusInfo} />
              </ClaimStatusInfoIcon>
              <Popover
                id={'popover-id-' + props.claim.caseId}
                show={show}
                anchor={anchor.current}
                position={'top'}
              >
                <div
                  style={{
                    width: 220,
                    backgroundColor: '#015294',
                    padding: '5px',
                    borderRadius: '2px',
                    color: '#FFF',
                    fontWeight: 400,
                    textAlign: 'left',
                  }}
                >
                  {statusMessage}
                </div>
              </Popover>
            </StatusFlag>
          </div>
        </div>
      </div>
    </Item>
  );
};

export default ClaimsCardItems;
