import React from 'react';
import styled from 'styled-components';
import { Accommodation } from '../../../../../data-models';
import { get } from '../../../../../utils';
import { Item, StatusFlag, dateFormat } from './shared-styles';
import dayjs from 'dayjs';
import claimStatusGreen from '../../../../../images/claimStatusGreen.svg';
import claimStatusMix from '../../../../../images/claimStatusMix.svg';
import claimStatusOrange from '../../../../../images/claimStatusOrange.svg';
import claimStatusRed from '../../../../../images/claimStatusRed.svg';
import claimStatusBlue from '../../../../../images/claimStatusBlue.svg';
import ClaimStatusInfo from '../../../../../images/claimStatusInfoIcon.svg';
import { Popover } from '@progress/kendo-react-tooltip';
import { getClaimStatusConfig } from '../../../../../utils/remote-config-manager';

interface IAccommodationItem {
  accommodations: Array<Accommodation>;
}

const Container = styled.div`
  margin-bottom: 15px;
`;

const AccommodationContainer = styled.div<{ first: boolean }>`
  border-top: ${(p) => (p.first ? 0 : '1px solid #dddddd')};
  margin-top: ${(p) => (p.first ? 0 : '15px')};
  padding-top: ${(p) => (p.first ? 0 : '15px')};
  display: flex;
  flex-direction: row;
`;

const ClaimStatusInfoIcon = styled.button`
  background: #fff;
  border: 0px;
  margin-left: 6px;
  bottom: 1px;
`;

const getStatusIcon = (acm: any) => {
  const statusColor = get(acm, 'status', 'N/A');
  if (statusColor === 'Approved' || statusColor === 'Success') {
    return claimStatusGreen;
  } else if (statusColor === 'Mixed Decision' || statusColor === 'Partial') {
    return claimStatusMix;
  } else if (statusColor === 'Denied') {
    return claimStatusRed;
  } else if (
    statusColor === 'Open' ||
    statusColor === 'Intake in Progress' ||
    statusColor === 'Request Recognized' ||
    statusColor === 'Closed' ||
    statusColor === 'Archived'
  ) {
    return claimStatusBlue;
  } else {
    return claimStatusOrange;
  }
};

const getStatusText = async (acm: any, setStatusMessage: Function) => {
  const statusConfigs = await getClaimStatusConfig();
  const foundStatus = statusConfigs.find(
    (s) => s.status === get(acm, 'status', 'N/A'),
  );
  if (foundStatus && foundStatus.message) {
    setStatusMessage(foundStatus.message);
  }
};

export const AccommodationCardItem: (
  p: IAccommodationItem,
) => React.ReactElement = (p) => {
  const { accommodations } = p;
  const anchor = React.useRef<HTMLDivElement | null>(null);
  const [statusMessage, setStatusMessage] = React.useState();
  const [show, setShow] = React.useState(false);

  React.useEffect(() => {
    accommodations.forEach((acm) => {
      getStatusText(acm, setStatusMessage);
    });
  }, [accommodations]);

  return (
    <Container className="acm-card-container tw-w-full">
      {accommodations.map((acm, i) => (
        <Item key={`acm-item-${i}`}>
          {acm.accommodationDetails?.map((acmDetail, detailIndex) => (
            <AccommodationContainer
              key={`acm-${i}-detail-${detailIndex}`}
              first={i === 0 && detailIndex === 0}
            >
              <div className="tw-flex tw-flex-row tw-items-center tw-w-full">
                <div className="tw-flex tw-flex-col md:tw-flex-row tw-gap-2 md:tw-gap-5 tw-w-1/2 md:tw-w-2/3 md:tw-mr-5">
                  <div className="tw-flex tw-flex-col tw-gap-2 md:tw-w-1/2">
                    <div className="tw-text-base tw-font-semibold">Claim</div>
                    <div className="tw-text-base">{acmDetail.type}</div>
                  </div>
                  <div className="tw-flex tw-flex-col tw-gap-2">
                    <div className="tw-text-base tw-font-semibold">Created</div>
                    <div className="tw-text-base">
                      {dayjs(acm.createdDate).format(dateFormat)}
                    </div>
                  </div>
                </div>
                <div className="tw-flex tw-flex-row tw-justify-end tw-items-center tw-w-1/2 md:tw-w-1/3">
                  <div
                    id={'claim-status-id-' + acm.accommodationId}
                    ref={anchor}
                    onMouseEnter={() => setShow(true)}
                    onMouseLeave={() => setShow(false)}
                  >
                    <StatusFlag
                      className="tw-flex tw-flex-nowrap"
                      color={get(acm, 'status', 'None')
                        .replace(/ /g, '')
                        .toLowerCase()}
                    >
                      <img
                        style={{ paddingRight: '5px' }}
                        src={getStatusIcon(acm)}
                        alt=""
                      />
                      <div className="tw-inline-block tw-text-wrap md:tw-text-nowrap tw-align-middle">
                        {get(acm, 'status', 'N/A')}
                      </div>
                      <ClaimStatusInfoIcon>
                        <img alt="" src={ClaimStatusInfo} />
                      </ClaimStatusInfoIcon>
                      <Popover
                        id={'popover-id-' + acm.accommodationId}
                        show={show}
                        anchor={anchor.current}
                        position={'top'}
                      >
                        <div
                          style={{
                            width: 220,
                            backgroundColor: '#015294',
                            padding: '5px',
                            borderRadius: '2px',
                            color: '#FFF',
                            fontWeight: 400,
                            textAlign: 'left',
                          }}
                        >
                          {statusMessage}
                        </div>
                      </Popover>
                    </StatusFlag>
                  </div>
                </div>
              </div>
            </AccommodationContainer>
          ))}
        </Item>
      ))}
    </Container>
  );
};

export default AccommodationCardItem;
