import { prefixObjectValues } from '../../../../utils';
import { AppPaths, ReminderIcons } from '../../../../utils/enums';

import reminderEnvelope from '../../../../images/reminder-envelope.svg';
import chatBubbles from '../../../../images/chat-bubbles.svg';
import { Link } from 'react-router-dom';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { closeReminder } from '../../../../utils/web-apis-client';
import { useState } from 'react';
import { Spinner } from '../../spinner';
import { trackGA4Event } from '../../../../utils/analytics';

export interface ReminderMenuItem {
  headingText: string;
  bodyText: string;
  icon: ReminderIcons;
  date: string;
  reminderId: string;
  type: string;
}

type QueryStatus = 'error' | 'loading' | 'success';

export interface ReminderMenuProps {
  keyId?: string;
  queryStatus: QueryStatus;
  menuItems?: ReminderMenuItem[];
}

export interface MobileReminderMenuProps {
  keyId?: string;
  menuItems?: ReminderMenuItem[];
  mobileMenuIsOpen: boolean;
  mobileMenuFunction: Function;
}

/*******************************************************************************
 * DOM identifier template for this component
 ******************************************************************************/
const domIdsStatic = {
  reminderMenuItem: 'reminder-menu',
};

/*******************************************************************************
 * Adds a unique prefix to the domIds to create a unique id
 * @param {string} prefix A unique prefix to add to the domIds
 * @returns {domIdsStatic} If a prefix is provided, then a copy of domIdsStatic
 * is returned with the property values modified to include the prefix. If a
 * prefix is not provided a copy of domIdsStatic is returned.
 ******************************************************************************/
const domIdsUnique = (prefix?: string) =>
  prefixObjectValues(prefix, domIdsStatic);

const findIcon = (icon: ReminderIcons) => {
  if (icon === ReminderIcons.ChatBubbles) {
    return chatBubbles;
  } else {
    return undefined;
  }
};

/*******************************************************************************
 * Custom reminder rendering
 ******************************************************************************/
const ReminderMenuItemComponent = (props: {
  headingText: any;
  bodyText: any;
  icon: any;
  date: any;
  type: any;
  reminderId: any;
}) => {
  const { headingText, bodyText, icon, date, type, reminderId } = props;
  const { mutate } = useMutation((reminderId: string) =>
    closeReminder(reminderId),
  );

  const queryClient = useQueryClient();

  switch (type) {
    case 'SMSPref':
      return (
        <a
          className="tw-flex-col tw-no-underline tw-text-inherit tw-w-full"
          href={AppPaths.profileCommunicationPreferences}
          onClick={() => {
            mutate(reminderId);
            queryClient.invalidateQueries(['reminders']);
          }}
        >
          <div className="tw-flex-col tw-p-4">
            <div className="tw-flex tw-row tw-bg-white tw-w-full">
              <div className="tw-mr-3">
                <img
                  className="tw-self-start -tw-ml-1"
                  src={findIcon(icon)}
                  alt=""
                ></img>
              </div>
              <div>
                <p className="tw-text-sm tw-font-semibold tw-text-unm-primary02-500">
                  {headingText}
                </p>
                <p className="tw-text-sm tw-text-unm-primary02-500">
                  {bodyText}
                </p>
              </div>
            </div>
            <div className="tw-align-bottom">
              <p className="tw-text-sm tw-text-end tw-text-unm-primary02-500 tw-m-0 tw-align-text-bottom">
                {date}
              </p>
            </div>
          </div>
        </a>
      );
    case 'Generic':
      return (
        <Link
          to={AppPaths.profileCommunicationPreferences}
          onClick={() => {
            mutate(reminderId);
            queryClient.invalidateQueries(['reminders']);
          }}
        >
          <div>
            <img src={findIcon(icon)} alt=""></img>
            <p>generic {headingText}</p>
          </div>
          <div>
            <p>{bodyText}</p>
          </div>
          <p>{date}</p>
        </Link>
      );
    default:
      return (
        <Link to={AppPaths.root}>
          <div>
            <img src={findIcon(icon)} alt=""></img>
            <p>{headingText}</p>
          </div>
          <div>
            <p>{bodyText}</p>
          </div>
          <p>{date}</p>
        </Link>
      );
  }
};

export const MobileReminderMenuComponent = (props: {
  menuItems: ReminderMenuItem[] | undefined;
  queryStatus: 'error' | 'loading' | 'success';
  setOpenMenu(isOpen: boolean): void;
  isOpen: boolean;
}) => {
  const { menuItems, queryStatus, setOpenMenu, isOpen } = props;

  const menuItemsPresent = menuItems && menuItems.length > 0;

  const formattedDate = (date: string) => {
    return new Date(date).toLocaleDateString('en-US');
  };

  if (!isOpen) {
    return <></>;
  } else {
    return (
      <div
        style={{ zIndex: 100 }}
        id="mobile-reminder-menu"
        className="tw-absolute tw-z-100 tw-w-screen tw--ml-8 tw-mt-12 overflow tw-shadow-[0_96px_64px_32px_rgba(2,2,2,0.3)]"
      >
        <div className="tw-bg-unm-interactive01-700 tw-z-100 tw-w-full tw-pl-3 tw-pr-4 tw-py-2 tw-text-white tw-align-middle tw-font-semibold tw-flex tw-flex-row tw-justify-start">
          <div className="tw-mr-2 tw-mb-0 tw-z-100 tw-bg-unm-interactive01-400 tw-border-unm-interactive01-400 tw-rounded-lg tw-border-solid tw-px-[6px]">
            <p className="tw-mb-0 tw-z-100">
              {menuItems ? menuItems.length : ''}
            </p>
          </div>
          <div className="tw-mb-0 tw-z-100">
            <p className="tw-mb-0 tw-border-solid tw-border-transparent tw-z-100">
              Reminders
            </p>
          </div>
        </div>
        {queryStatus === 'loading' && (
          <div className="tw-bg-white tw-border-unm-adhoc4 tw-border-solid tw-border tw-border-t-0 tw-w-full tw-h-auto tw-z-100 tw-flex tw-justify-center">
            <Spinner></Spinner>
          </div>
        )}
        {queryStatus === 'error' && (
          <div
            id="error-message"
            className="tw-bg-white tw-border-unm-adhoc4 tw-border-solid tw-border tw-border-t-0 tw-w-full tw-h-auto tw-z-100 tw-flex tw-justify-center"
          >
            <p className="tw-text-unm-adhoc2 tw-font-normal tw-text-sm tw-my-4">
              Sorry, we can't retrieve your reminders at this time
            </p>
          </div>
        )}
        {queryStatus === 'success' && !menuItemsPresent && (
          <div
            id="no-reminders-message"
            className="tw-bg-white tw-border-unm-adhoc4 tw-border-solid tw-border tw-border-t-0 tw-w-full tw-h-auto tw-z-100 tw-flex tw-justify-center"
          >
            <p className="tw-text-unm-adhoc2 tw-font-normal tw-text-sm tw-my-4">
              You don't have reminders
            </p>
          </div>
        )}
        {menuItemsPresent &&
          menuItems?.map((m, i) => (
            <div
              className="tw-bg-white tw-border-unm-adhoc4 tw-border-solid tw-border tw-border-t-0 tw-w-full tw-h-auto tw-z-100"
              key={i}
              id={`reminder-menu-item-${i}`}
            >
              <ReminderMenuItemComponent
                headingText={m.headingText}
                bodyText={m.bodyText}
                icon={m.icon}
                date={formattedDate(m.date)}
                type={m.type}
                reminderId={m.reminderId}
              ></ReminderMenuItemComponent>
            </div>
          ))}
        <div className="tw-w-full tw-px-4 tw-py-2 tw-bg-white tw-border tw-border-solid tw-border-t-unm-interactive01-400 tw-border-x-0 tw-border-b-0 tw-align-middle tw-font-semibold tw-flex tw-flex-row tw-justify-end">
          <button
            className="tw-bg-transparent tw-text-unm-primary02-500 -tw-mr-1 tw-p-0 tw-text-sm tw-border-none"
            onClick={() => setOpenMenu(false)}
          >
            Close X
          </button>
        </div>
      </div>
    );
  }
};

export const ReminderMenuComponent = (props: {
  menuItems: ReminderMenuItem[] | undefined;
  queryStatus: 'error' | 'loading' | 'success';
  setOpenMenu(isOpen: boolean): void;
}) => {
  const { menuItems, queryStatus, setOpenMenu } = props;

  const menuItemsPresent = menuItems && menuItems.length > 0;

  const formattedDate = (date: string) => {
    return new Date(date).toLocaleDateString('en-US');
  };

  return (
    <div
      style={{ zIndex: 100 }}
      id="desktop-reminder-menu"
      className="tw-absolute tw-rounded-lg tw-top-7 -tw-ml-6 tw-z-100 tw-w-[315px] tw-shadow-[0_2px_4px_0px_rgba(2,84,119,0.3)] overflow"
    >
      <div className="tw-bg-unm-interactive01-700 tw-z-100 tw-w-full tw-pl-3 tw-pr-4 tw-py-2 tw-rounded-t-lg tw-text-white tw-align-middle tw-font-semibold tw-flex tw-flex-row tw-justify-start">
        <div className="tw-mr-2 tw-mb-0 tw-z-100 tw-bg-unm-interactive01-400 tw-border-unm-interactive01-400 tw-rounded-lg tw-border-solid tw-px-[6px]">
          <p className="tw-mb-0 tw-z-100">
            {menuItems ? menuItems.length : ''}
          </p>
        </div>
        <div className="tw-mb-0 tw-z-100">
          <p className="tw-mb-0 tw-border-solid tw-border-transparent tw-z-100">
            Reminders
          </p>
        </div>
      </div>
      {queryStatus === 'loading' && (
        <div className="tw-bg-white tw-border-unm-adhoc4 tw-border-solid tw-border tw-border-t-0 tw-w-full tw-h-auto tw-z-100 tw-flex tw-justify-center">
          <Spinner></Spinner>
        </div>
      )}
      {queryStatus === 'error' && (
        <div
          id="error-message"
          className="tw-bg-white tw-border-unm-adhoc4 tw-border-solid tw-border tw-border-t-0 tw-w-full tw-h-auto tw-z-100 tw-flex tw-justify-center tw-align-middle"
        >
          <p className="tw-text-unm-adhoc2 tw-font-normal tw-text-xs tw-my-4">
            Sorry, we can't retrieve your reminders at this time
          </p>
        </div>
      )}
      {queryStatus === 'success' && !menuItemsPresent && (
        <div className="tw-bg-white tw-border-unm-adhoc4 tw-border-solid tw-border tw-border-t-0 tw-w-full tw-h-auto tw-z-100 tw-flex tw-justify-center">
          <p
            id="no-reminders-message"
            className="tw-text-unm-adhoc2 tw-font-normal tw-text-sm tw-my-4"
          >
            You don't have reminders
          </p>
        </div>
      )}
      {menuItemsPresent &&
        menuItems?.map((m, i) => (
          <div
            className="tw-bg-white tw-border-unm-adhoc4 tw-border-solid tw-border tw-border-t-0 tw-w-full tw-h-auto tw-z-100"
            id={`reminder-menu-item-${i}`}
            key={i}
          >
            <ReminderMenuItemComponent
              headingText={m.headingText}
              bodyText={m.bodyText}
              icon={m.icon}
              date={formattedDate(m.date)}
              type={m.type}
              reminderId={m.reminderId}
            ></ReminderMenuItemComponent>
          </div>
        ))}
      <div className="tw-rounded-b-lg tw-w-full tw-px-4 tw-py-2 tw-bg-white tw-border tw-border-solid tw-border-t-unm-interactive01-400 tw-border-x-0 tw-border-b-0 tw-align-middle tw-font-semibold tw-flex tw-flex-row tw-justify-end">
        <button
          className="tw-bg-transparent tw-text-unm-primary02-500 -tw-mr-1 tw-p-0 tw-text-sm tw-border-none"
          onClick={() => setOpenMenu(false)}
        >
          Close X
        </button>
      </div>
    </div>
  );
};

/*******************************************************************************
 * ReminderMenu component
 * @param {ReminderMenu.propTypes} props ReminderMenu propTypes
 ******************************************************************************/
const MobileReminderMenu: (p: MobileReminderMenuProps) => React.ReactElement = (
  props,
) => {
  const { menuItems, mobileMenuIsOpen, mobileMenuFunction } = props;
  const menuItemsPresent = menuItems && menuItems.length > 0;

  const [focused, setFocused] = useState<boolean>(false);

  const setOpenMenuFunction = (isOpen: boolean) => {
    mobileMenuFunction(isOpen);
  };

  return (
    <div className="tw-align-middle">
      <div className="tw-align-middle tw-mt-1 lg:tw-mb-[2px] tw-relative">
        <button
          onClick={() => {
            setOpenMenuFunction(!mobileMenuIsOpen);
          }}
          onFocus={() => {
            setFocused(true);
          }}
          onBlur={() => setFocused(false)}
          id="reminder-button"
          className="tw-h-9"
          style={{
            zIndex: 10,
            borderStyle: mobileMenuIsOpen || focused ? 'solid' : 'none',
            borderColor: '#9ACBE2',
            backgroundColor:
              mobileMenuIsOpen || focused ? '#9ACBE2' : 'inherit',
            borderRadius: 8,
            borderWidth: 0,
          }}
        >
          {(focused || mobileMenuIsOpen) && (
            <svg
              height="36"
              width="36"
              className="tw-absolute tw-inset-x-0 tw--top-1"
              style={{ zIndex: -1 }}
            >
              <rect
                height="36"
                width="36"
                rx="8"
                fill="#9ACBE2"
                style={{ zIndex: -1 }}
              />
            </svg>
          )}
          <img
            src={reminderEnvelope}
            style={{ zIndex: 10 }}
            alt="Reminder Icon"
          ></img>
          {menuItemsPresent && (
            <svg
              height="10"
              width="10"
              className="tw-absolute tw-top-2 tw-right-[5px]"
              style={{ zIndex: 12 }}
            >
              <ellipse
                rx="4px"
                ry="4px"
                fill="#CC222F"
                cx="5px"
                cy="5px"
                className="tw-top-0 tw-right-0"
                stroke="#E6F2F8"
              />
            </svg>
          )}
        </button>
      </div>
    </div>
  );
};

/*******************************************************************************
 * ReminderMenu component
 * @param {ReminderMenu.propTypes} props ReminderMenu propTypes
 ******************************************************************************/
const ReminderMenu: (p: ReminderMenuProps) => React.ReactElement = (props) => {
  const { menuItems, queryStatus } = props;
  const menuItemsPresent = menuItems && menuItems.length > 0;

  const [openMenu, setOpenMenu] = useState<boolean>(false);
  const [focused, setFocused] = useState<boolean>(false);

  const setOpenMenuFunction = (isOpen: boolean) => {
    setOpenMenu(isOpen);
    if (isOpen) {
      trackGA4Event({
        action: 'click',
        target: 'Reminder Icon',
      });
    }
  };

  return (
    <div className="tw-align-middle">
      <div className="tw-align-middle tw-mt-1 lg:tw-mb-[2px] tw-relative">
        <button
          onClick={() => {
            setOpenMenuFunction(!openMenu);
          }}
          onFocus={() => {
            setFocused(true);
          }}
          onBlur={() => setFocused(false)}
          id="reminder-button"
          className="tw-h-9"
          style={{
            zIndex: 10,
            borderStyle: openMenu || focused ? 'solid' : 'none',
            borderColor: '#9ACBE2',
            backgroundColor: openMenu || focused ? '#9ACBE2' : 'inherit',
            borderRadius: 8,
            borderWidth: 0,
          }}
        >
          {(focused || openMenu) && (
            <svg
              height="36"
              width="36"
              className="tw-absolute tw-inset-x-0 tw--top-1"
              style={{ zIndex: -1 }}
            >
              <rect
                height="36"
                width="36"
                rx="8"
                fill="#9ACBE2"
                style={{ zIndex: -1 }}
              />
            </svg>
          )}
          <img
            src={reminderEnvelope}
            style={{ zIndex: 10 }}
            alt="Reminder Icon"
          ></img>
          {menuItemsPresent && (
            <svg
              height="10"
              width="10"
              className="tw-absolute tw-top-2 tw-right-[5px]"
              style={{ zIndex: 12 }}
            >
              <ellipse
                rx="4px"
                ry="4px"
                fill="#CC222F"
                cx="5px"
                cy="5px"
                className="tw-top-0 tw-right-0"
                stroke="#E6F2F8"
              />
            </svg>
          )}
        </button>
        {openMenu && (
          <ReminderMenuComponent
            menuItems={menuItems}
            queryStatus={queryStatus}
            setOpenMenu={setOpenMenuFunction}
          ></ReminderMenuComponent>
        )}
      </div>
    </div>
  );
};

/*******************************************************************************
 * exported api definition
 ******************************************************************************/
export { ReminderMenu, MobileReminderMenu, domIdsUnique };
