import { enums, isJSONParsable } from '../../../utils';
import { postChatDetails } from '../../../utils/web-apis-client';
import { getSimpleRemoteConfig } from '../../../utils/remote-config-manager';
import { log } from '../../../utils/log';
import UnumAvatar from '../../../images/unum-avatar-sm.svg';
import {
  messageConstants,
  eventMap,
  handleInitialConnectionStatusEvents,
  handleBotMessage,
  handleSystemMessage,
  BotProps,
} from './message-handlers';
import { IKenticoData } from '../../../contexts/kentico-data-context';
import 'amazon-connect-chatjs';

export const bot = {
  id: 0,
  avatarUrl: UnumAvatar,
};

// const log = new Logger\('live-chat-helper');

const isBotMessage = (message: any) => {
  return (
    message.data.DisplayName === 'BOT' ||
    (message.data.DisplayName === 'SYSTEM_MESSAGE' &&
      isJSONParsable(message.data.Content))
  );
};

const isPlainTextMessage = (message: any) => {
  return (
    message.data.ContentType === messageConstants.plainText &&
    message.data.Type === messageConstants.message
  );
};

/**
 * Processes a message from AWS Connect
 * @param {Object} message
 * @param {BotProps} bot
 * @param {Function} addNewMessage
 * @param {Boolean} loadedFromTranscript
 */
export const processMessage = async (
  message: any,
  bot: BotProps,
  addNewMessage: Function,
  setShowReconnect: Function,
  setShowMessageBox: Function,
  preChatContext: { [key: string]: string },
  loadedFromTranscript = false,
) => {
  let newMessage = null;
  const md = message.data;

  if (isPlainTextMessage(message)) {
    if (md.ParticipantRole === messageConstants.agent) {
      newMessage = {
        author: bot,
        id: md.Id,
        text: md.Content,
        timestamp: new Date(md.AbsoluteTime),
      };
    } else if (
      loadedFromTranscript &&
      md.ParticipantRole === messageConstants.customer &&
      !isJSONParsable(md.Content)
    ) {
      newMessage = {
        author: { id: 1 },
        id: md.Id,
        text: md.Content,
        timestamp: new Date(md.AbsoluteTime),
      };
    } else if (isBotMessage(message)) {
      handleBotMessage(
        bot,
        md.Id,
        md.Content,
        md.AbsoluteTime,
        addNewMessage,
        loadedFromTranscript,
      );
    } else if (md.DisplayName === 'SYSTEM_MESSAGE') {
      await handleSystemMessage(message, newMessage, addNewMessage);
    }
  } else if (md.ContentType) {
    //handle event messages
    newMessage = false;
    const handler = eventMap[md.ContentType];
    handler &&
      handler({
        md,
        loadedFromTranscript,
        addNewMessage,
        preChatContext,
        setShowMessageBox,
        setShowReconnect,
      });
  }

  if (newMessage) {
    addNewMessage({ message: newMessage, loadedFromTranscript });
  }
};

interface HandleOnMessageProps {
  message: any;
  addNewMessage: Function;
  messages: any;
  setMessages: Function;
  setShowReconnect: Function;
  setShowMessageBox: Function;
  preChatContext: { [key: string]: string };
}

export const handleOnMessage = async ({
  message,
  addNewMessage,
  messages,
  setMessages,
  setShowReconnect,
  setShowMessageBox,
  preChatContext,
}: HandleOnMessageProps) => {
  handleInitialConnectionStatusEvents(messages, setMessages);
  await processMessage(
    message,
    bot,
    addNewMessage,
    setShowReconnect,
    setShowMessageBox,
    preChatContext,
    false,
  );
};

export const getChatDetails = async (
  setChatDetails: Function,
  chatDetails: any,
  preChatContext: any,
  callCenter: IKenticoData[enums.KenticoDataKeys.callCenter],
) => {
  if (
    !chatDetails.startChatResult &&
    callCenter.open &&
    preChatContext.connect
  ) {
    const remoteConfig = await getSimpleRemoteConfig();

    const InstanceId = remoteConfig['connect_instance'];
    const ContactFlowId = remoteConfig['connect_contact_flow'];
    const ChatTopic = preChatContext?.answers['Chat Topic'] || '';

    const chatDets = await postChatDetails({
      ContactFlowId,
      InstanceId,
      ChatTopic,
    });

    setChatDetails(chatDets.data);
  }
};

const connectSession = async (session: any) => {
  let tries = 0;
  const maxTries = 5;
  while (true) {
    tries++;

    try {
      await session.connect();
      return session;
    } catch (e) {
      if (tries > maxTries) {
        log.debug('tries exceeded. Connection failed.');
        return false;
      }

      await new Promise((resolve) => setTimeout(resolve, 1500));
      log.debug('retrying connection...');
    }
  }
};

export const startSession = async (
  chatDetails: any,
  setSession: Function,
  messages: Array<any>,
  setMessages: Function,
  handleReconnect: Function,
  preChatContext: any,
  callCenter: IKenticoData[enums.KenticoDataKeys.callCenter],
) => {
  if (
    !chatDetails.startChatResult ||
    !callCenter.open ||
    !preChatContext.connect
  ) {
    return;
  }

  // @ts-ignore ts:7017
  global.connect.ChatSession.setGlobalConfig({
    region: 'us-east-1',
  });

  // @ts-ignore ts:7017
  const session: any = global.connect.ChatSession.create({
    chatDetails: chatDetails.startChatResult,
    type: 'CUSTOMER',
  });

  session.existingSession = chatDetails.existingSession;

  try {
    const response = await connectSession(session);
    if (response) {
      setSession(session);
      global.connectSession = session;
    } else {
      handleInitialConnectionStatusEvents(
        messages,
        setMessages,
        handleReconnect,
      );
    }
  } catch (e) {
    log.error('session.connect failed:');
    log.error(JSON.stringify(e));
  }
};
