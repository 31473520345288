import envConfig from './env-config';
import kenticoConfig from '../kentico-config.json';
import * as models from '../kontent-models';
import { TypeResolver, DeliveryClient } from '@kentico/kontent-delivery';
import { enums } from '.';
import { getKontent } from './remote-config-manager';

/*******************************************************************************
 * cms-client.js creates / exports an instance of the Kentico DeliveryClient
 * to use throughout the application to interact with the Kentico Delivery API
 ******************************************************************************/
let cmsClient: DeliveryClient;
let erClient: DeliveryClient;
let tasksClient: DeliveryClient;

const referenceCmsClient = new DeliveryClient({
  projectId: kenticoConfig.referenceProjectId,
  proxy: {
    baseUrl: `${window.location.origin}/kontent`,
    basePreviewUrl: `${window.location.origin}/kontent-preview`,
  },
  typeResolvers: [
    new TypeResolver(
      'simple_remote_configuration_item',
      () => new models.SimpleRemoteConfigurationItem(),
    ),
  ],
  // soon...
  // proxy: {
  //   baseUrl: 'https://portal-dev.unum.com/kontent',
  // },
});

export const getReferenceValues = async () => {
  const envCodename = envConfig.upEnvironment.toLowerCase().replace('.', '_');
  const result = await getKontent<models.SimpleRemoteConfigurationItem>({
    cmsClient: referenceCmsClient,
    kontentType: 'simple_remote_configuration_item',
    cacheKey: 'reference_remote_config',
    kontentElementsParameters: [envCodename],
  });

  const refItems: { [name: string]: any } = {};

  for (const item of result.items) {
    const codename = item.system.codename;
    refItems[codename] = item[envCodename].value;
  }

  return refItems;
};

const getClient = async () => {
  if (cmsClient) {
    return cmsClient;
  }

  const refItems = await getReferenceValues();

  cmsClient = new DeliveryClient({
    projectId: refItems[enums.CodeNames.refProjectId],
    proxy: {
      baseUrl: `${window.location.origin}/kontent`,
      basePreviewUrl: `${window.location.origin}/kontent-preview`,
    },
    previewApiKey: refItems[enums.CodeNames.refPreviewKey],
    globalQueryConfig: {
      usePreviewMode:
        !envConfig.isProd &&
        refItems[enums.CodeNames.refPreviewFlag] === 'true',
    },
    typeResolvers: [
      new TypeResolver('form', () => new models.Form()),
      new TypeResolver('form_step', () => new models.FormStep()),
      new TypeResolver('form_field', () => new models.FormField()),
      new TypeResolver('form_button', () => new models.FormButton()),
      new TypeResolver('form_text', () => new models.FormText()),
      new TypeResolver(
        'simple_remote_configuration_item',
        () => new models.SimpleRemoteConfigurationItem(),
      ),
      new TypeResolver(
        'call_center_closure',
        () => new models.CallCenterClosure(),
      ),
      new TypeResolver('splash_config', () => new models.SplashConfig()),
      new TypeResolver('feature_flags', () => new models.FeatureFlags()),
      new TypeResolver('content_blob', () => new models.ContentBlob()),
      new TypeResolver('asset_container', () => new models.AssetContainer()),
      new TypeResolver('dynamic_content', () => new models.DynamicContent()),
      new TypeResolver('localized_string', () => new models.LocalizedString()),
      new TypeResolver('task', () => new models.Task()),
      new TypeResolver('live_chat_form', () => new models.LiveChatForm()),
      new TypeResolver('next_step', () => new models.NextStep()),
      new TypeResolver(
        'shared___submission_text',
        () => new models.SharedSubmissionText(),
      ),
      new TypeResolver('intake_selection', () => new models.IntakeSelection()),
      new TypeResolver('intake_card', () => new models.IntakeCard()),
      new TypeResolver('accordion_card', () => new models.AccordionCard()),
      new TypeResolver(
        'accordion_card_item',
        () => new models.AccordionCardItem(),
      ),
      new TypeResolver('intake_section', () => new models.IntakeSection()),
    ],
  });

  return cmsClient;
};

const employerContentClient = () => {
  if (erClient) {
    return erClient;
  }

  erClient = new DeliveryClient({
    projectId: kenticoConfig.erProjectId,
    proxy: {
      baseUrl: `${window.location.origin}/kontent`,
      basePreviewUrl: `${window.location.origin}/kontent-preview`,
    },
    previewApiKey: kenticoConfig.previewERProjectId,
    globalQueryConfig: {
      usePreviewMode: !envConfig.isProd,
    },
    typeResolvers: [
      new TypeResolver('employer_content', () => new models.EmployerContent()),
      new TypeResolver('employer_image', () => new models.EmployerImage()),
      new TypeResolver(
        'employer_instructions',
        () => new models.EmployerInstructions(),
      ),
      new TypeResolver('employer', () => new models.Employer()),
      new TypeResolver(
        'intermittent_bonding_restrictions',
        () => new models.EmployerIntermittentBondingRestrictions(),
      ),
      new TypeResolver('plain_text', () => new models.EmployerPlainText()),
      new TypeResolver('rich_text', () => new models.EmployerRichText()),
      new TypeResolver(
        'restrictions_messaging',
        () => new models.EmployerRestrictionsMessaging(),
      ),
      new TypeResolver(
        'employer_custom_task',
        () => new models.EmployerCustomTask(),
      ),
      new TypeResolver(
        'employer_call_to_action',
        () => new models.EmployerCallToAction(),
      ),
      new TypeResolver(
        'employer_custom_link',
        () => new models.EmployerCustomLink(),
      ),
      new TypeResolver(
        'employer_educational_content',
        () => new models.EmployerEducationalContent(),
      ),
      new TypeResolver(
        'employer_link_section',
        () => new models.EmployerLinkSection(),
      ),
      new TypeResolver(
        'employer_hero_image',
        () => new models.EmployerHeroImage(),
      ),
    ],
  });

  return erClient;
};

const getTasksClient = async () => {
  if (tasksClient) {
    return tasksClient;
  }

  const refItems = await getReferenceValues();

  tasksClient = new DeliveryClient({
    projectId: refItems[enums.CodeNames.refTasksProjectId],
    proxy: {
      baseUrl: `${window.location.origin}/kontent`,
      basePreviewUrl: `${window.location.origin}/kontent-preview`,
    },
    previewApiKey: refItems[enums.CodeNames.refTasksPreviewKey],
    globalQueryConfig: {
      usePreviewMode:
        !envConfig.isProd &&
        refItems[enums.CodeNames.refTasksPreviewFlag] === 'true',
    },
    typeResolvers: [
      new TypeResolver('businessrole', () => new models.Businessrole()),
      new TypeResolver(
        'descriptionbybusinessrole',
        () => new models.Descriptionbybusinessrole(),
      ),
      new TypeResolver('duedatesetting', () => new models.Duedatesetting()),
      new TypeResolver('event', () => new models.Event()),
      new TypeResolver('faq', () => new models.Faq()),
      new TypeResolver('form_config', () => new models.FormConfig()),
      new TypeResolver('form_element', () => new models.FormElement()),
      new TypeResolver('form_text_element', () => new models.FormTextElement()),
      new TypeResolver('form', () => new models.Form()),
      new TypeResolver('formelement', () => new models.Formelement()),
      new TypeResolver(
        'frequentlyusedtasksbycasetype',
        () => new models.Frequentlyusedtasksbycasetype(),
      ),
      new TypeResolver(
        'inputtypecheckbox',
        () => new models.Inputtypecheckbox(),
      ),
      new TypeResolver(
        'inputtypedatepicker',
        () => new models.Inputtypedatepicker(),
      ),
      new TypeResolver(
        'inputtypedatetime',
        () => new models.Inputtypedatetime(),
      ),
      new TypeResolver(
        'inputtypefileupload',
        () => new models.Inputtypefileupload(),
      ),
      new TypeResolver('inputtypeoption', () => new models.Inputtypeoption()),
      new TypeResolver(
        'inputtyperadiobutton',
        () => new models.Inputtyperadiobutton(),
      ),
      new TypeResolver('inputtypeselect', () => new models.Inputtypeselect()),
      new TypeResolver('inputtypetext', () => new models.Inputtypetext()),
      new TypeResolver(
        'inputtypetextarea',
        () => new models.Inputtypetextarea(),
      ),
      new TypeResolver(
        'notificationsetting',
        () => new models.Notificationsetting(),
      ),
      new TypeResolver('notificationtype', () => new models.Notificationtype()),
      new TypeResolver('status_help_text', () => new models.StatusHelpText()),
      new TypeResolver(
        'persona_based_text',
        () => new models.PersonaBasedText(),
      ),
      new TypeResolver('task', () => new models.Task()),
      new TypeResolver('taskstatusevent', () => new models.Taskstatusevent()),
    ],
  });

  return tasksClient;
};
/*******************************************************************************
 * exported api definition
 ******************************************************************************/
export { getClient, employerContentClient, getTasksClient, models };
